import React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import Check from "@mui/icons-material/Check";
import styles from "../Profile/profile-jss";

function PerformanceWidget(props) {
  const { classes } = props;

  return (
    <Grid>
      <Chip
        avatar={
          <Avatar>
            <Check style={{ backgroundColor: "#3b37fe", color: "#fff" }} />
          </Avatar>
        }
        label={props.passToChild + "% Progress"}
        className={classes.chip}
        style={{ color: "#000" }}
      />
      <LinearProgress
        variant="determinate"
        className={classes.progress}
        value={props.passToChild}
      />
    </Grid>
  );
}

PerformanceWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PerformanceWidget);
