import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import firebase from "../../../config/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import storage from "../../../config/firebaseStorage";
import imageCompression from "browser-image-compression";
import Page from "../../../utils/Page";
import { format } from "date-fns";
import MerchantContext from "../../../config/MerchantContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  imageContainer: {
    width: "100%",
    height: 300,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  headerText: {
    fontWeight: "bold",
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    boxShadow: theme.shadows[5],
    "&:hover": {
      boxShadow: theme.shadows[10],
    },
    borderRadius: 8,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  caption: {
    // Style for the image caption
    marginTop: theme.spacing(1),
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
  },
  descriptionContainer: {
    // For the top description
    marginBottom: theme.spacing(2),
  },
  title: {
    userSelect: "none",
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: theme.palette.text.primary, // Usually black or dark grey
    marginBottom: theme.spacing(1), // Add some spacing below the title
  },
  titleDescription: {
    userSelect: "none",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
  },
}));

const EditCoupon = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { coupon } = location.state;

  const [formData, setFormData] = useState({
    name: coupon ? coupon.name : "",
    description: coupon ? coupon.description : "",
    expiryDate: coupon ? new Date(coupon.expiryDate) : null,
    imageUrl: coupon ? coupon.imageUrl : "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(coupon.imageUrl || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const { currentMerchant } = useContext(MerchantContext); // Use MerchantContext to get the current merchant

  useEffect(() => {
    if (!coupon) {
      navigate("/app/coupons");
    }
  }, [coupon, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = async (e) => {
    const image = e.target.files[0];

    if (!image) {
      console.error("Not an image, the image file is a", typeof image);
      return;
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(image, options);
      setImageFile(compressedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Error during image compression", error);
    }
  };

  const formatDate = (date) => {
    if (date) {
      return format(date, "yyyy-MM-dd");
    }
    return null; // Return null if no date is selected
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!currentMerchant) {
      setLoading(false);
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      console.error("No current merchant found in context.");
      return;
    }

    const currentMerchantId = currentMerchant.merchantID;

    try {
      if (imageFile) {
        console.log("Uploading image for coupon...");
        const uploadTask = await storage
          .ref(`/merchants/${currentMerchantId}/coupons/${imageFile.name}`)
          .put(imageFile);
        const fireBaseUrl = await storage
          .ref(`/merchants/${currentMerchantId}/coupons`)
          .child(imageFile.name)
          .getDownloadURL();
        formData.imageUrl = fireBaseUrl;
        console.log("Image uploaded successfully:", fireBaseUrl);
      }

      const expiryDateString = formData.expiryDate
        ? formatDate(formData.expiryDate)
        : null;

      const couponDataToUpdate = {
        ...formData,
        expiryDate: expiryDateString,
      };

      console.log("Updating coupon with data:", couponDataToUpdate);
      await firebase.updateCoupon(
        currentMerchantId,
        coupon.id,
        couponDataToUpdate
      );

      setLoading(false);
      setSnackbarMessage("Coupon updated successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      navigate("/app/coupons");
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to update coupon");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      console.error("Failed to update coupon:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page title="Edit Coupon">
      <Box className={classes.root}>
        <Container maxWidth="md">
          <Box className={classes.header}>
            <Typography variant="h4" className={classes.title}>
              Edit Coupon
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/app/coupons")}
              className={classes.backButton}
            >
              Back
            </Button>
          </Box>
          <Box className={classes.descriptionContainer}>
            <Typography variant="body1">
              Edit and update the details of Coupons. This will replace content
              of already existing/scheduled birthday coupons as well.
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          imagePreviewUrl || "https://via.placeholder.com/400"
                        }
                        alt="Uploaded"
                        className={classes.imagePreview}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      component="label"
                      className={classes.uploadButton}
                      startIcon={<PhotoCamera />}
                    >
                      Upload Image
                      <input type="file" hidden onChange={handleImageChange} />
                    </Button>
                    <TextField
                      sx={{ mt: 4 }}
                      label="Coupon Name"
                      name="name"
                      variant="outlined"
                      value={formData.name}
                      onChange={handleChange}
                      className={classes.input}
                      fullWidth
                    />
                    <TextField
                      label="Description"
                      name="description"
                      variant="outlined"
                      multiline
                      rows={4}
                      value={formData.description}
                      onChange={handleChange}
                      className={classes.input}
                      fullWidth
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Expiry Date"
                        value={formData.expiryDate}
                        onChange={(date) =>
                          setFormData({ ...formData, expiryDate: date })
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth margin="normal" />
                        )}
                        disablePast
                      />
                    </LocalizationProvider>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginTop={2}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.submitButton}
                        disabled={loading}
                        startIcon={<SaveIcon />}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Update Coupon"
                        )}
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate("/app/coupons")}
                        className={classes.submitButton}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Container>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Page>
  );
};

export default EditCoupon;
