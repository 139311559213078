import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ReactivateIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import ShareIcon from "@mui/icons-material/Share";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditShareButton,
  RedditIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { useNavigate } from "react-router-dom";
import firebase from "../../../config/firebase";
import { sendPromotionEmail } from "../../../config/cloudApis";
import Page from "../../../utils/Page";
import { makeStyles } from "@mui/styles";
import useUpdateEffect from "../../../utils/useUpdateEffect";
import MerchantContext from "../../../config/MerchantContext"; // Import MerchantContext

const useStyles = makeStyles((theme) => ({
  root: {
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  card: {
    position: "relative",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  cardMedia: {
    height: 140,
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "red",
  },
  deleteText: {
    position: "absolute",
    top: 40,
    right: 10,
    color: "red",
  },
  loadingSpinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  dialogTitle: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  dialogContent: {
    fontSize: "0.95rem",
  },
  cardTitle: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  shareButtons: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  },
  title: {
    userSelect: "none",
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: theme.palette.text.primary, // Usually black or dark grey
    marginBottom: theme.spacing(1), // Add some spacing below the title
  },
  titleDescription: {
    userSelect: "none",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
  },
  fullScreenDialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    height: "100vh",
    padding: theme.spacing(3),
  },
  sadEmoji: {
    fontSize: "4rem",
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2), // Adds space between buttons
  },
}));

const PromotionsManager = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { currentMerchant, plan } = useContext(MerchantContext); // Use context to get currentMerchant and plan
  const [promoCards, setPromoCards] = useState([]);
  const [merchantID, setMerchantID] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [sendCardId, setSendCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPromotions, setLoadingPromotions] = useState(true);
  const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
  const [reactivateCard, setReactivateCard] = useState(null);
  const [shareUrl, setShareUrl] = useState("");
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);

  useEffect(() => {
    if (currentMerchant) {
      const currentMerchantId = currentMerchant.merchantID;
      setMerchantID(currentMerchantId);
      loadPromoCards(currentMerchantId);
      setOpenSubscriptionDialog(!currentMerchant.subscriptions.isSubscribed);

      if (plan === "BASIC_01") setOpenPlanDialog(true);
    }
  }, [currentMerchant]);

  const loadPromoCards = (merchantId) => {
    setLoadingPromotions(true);

    firebase
      .fetchPromoCards(merchantId)
      .then((data) => {
        setPromoCards(data || []);
        setLoadingPromotions(false);
      })
      .catch((error) => {
        console.error("Failed to fetch promo cards:", error);
        setPromoCards([]);
        setLoadingPromotions(false);
      });
  };

  const getPromotionLimit = () => {
    switch (plan) {
      case "TRAIL_01":
      case "BASIC_01":
        return 0;
      case "STAN_01":
        return 3;
      case "PREMIUM_01":
        return "Unlimited";
      default:
        return 0;
    }
  };

  const handleAddPromoCard = () => {
    if (!currentMerchant.subscriptions.isSubscribed) {
      alert(
        "Your subscription has expired. Please renew your subscription to create new coupons."
      );
      return;
    }

    const promoLimit = getPromotionLimit();
    if (promoLimit !== "Unlimited" && promoCards.length >= promoLimit) {
      alert(`You have reached the promotion creation limit for your plan.`);
      return;
    }
    navigate("/app/add-promo-card");
  };

  const handleEditPromoCard = (card) => {
    navigate(`/app/edit-promo-card`, { state: { card } });
  };

  const handleDeleteClick = (cardId) => {
    setDeleteCardId(cardId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmDelete = () => {
    console.log("Promotion ID is " + deleteCardId);
    if (deleteCardId) {
      firebase
        .deletePromoCard(merchantID, deleteCardId)
        .then(() => {
          setOpenDialog(false);
          setSnackbarMessage("Deleted promotion successfully");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          loadPromoCards(merchantID);
        })
        .catch((error) => {
          setOpenDialog(false);
          setSnackbarMessage("Failed to delete promotion");
          setSnackbarSeverity("error");
          console.error("Failed to delete promo card:", error);
        });
    } else {
      setOpenDialog(false);
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarSeverity("error");
    }
  };

  const handleSendClick = (card) => {
    setSendCardId(card.id);
    setOpenSendDialog(true);
  };

  const handleCloseSendDialog = () => {
    if (!loading) {
      setOpenSendDialog(false);
    }
  };

  const handleConfirmSend = async () => {
    if (sendCardId) {
      setLoading(true);

      try {
        const numberOfCustomers = await firebase.getNumberOfCustomers(
          merchantID
        );
        const sentDetails = {
          date: new Date().toISOString(),
          numberOfCustomers,
        };

        await sendPromotionEmail(merchantID, sendCardId);

        const updatedPromoCard = promoCards.find(
          (card) => card.id === sendCardId
        );
        if (updatedPromoCard) {
          const updatedSentDetails = updatedPromoCard.sentDetails
            ? [...updatedPromoCard.sentDetails, sentDetails]
            : [sentDetails];
          const newSentCount = (updatedPromoCard.sentCount || 0) + 1;
          const lastSent = new Date().toISOString(); // Save the last sent timestamp

          const isActive = newSentCount < updatedPromoCard.maxSends;

          await firebase.updatePromoCard(merchantID, sendCardId, {
            sentDetails: updatedSentDetails,
            sentCount: newSentCount,
            totalCustomersSent:
              (updatedPromoCard.totalCustomersSent || 0) + numberOfCustomers,
            lastSent, // Add this field
            isActive,
          });

          setSnackbarMessage("Promotion email sent successfully");
          setSnackbarSeverity("success");
          loadPromoCards(merchantID);
        }
      } catch (error) {
        setSnackbarMessage("Failed to send promotion email");
        setSnackbarSeverity("error");
        console.error("Failed to send promotion email:", error);
      } finally {
        setLoading(false);
        setOpenSendDialog(false);
      }
    } else {
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      setOpenSendDialog(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return date.toLocaleDateString(); // This will format the date as "MM/DD/YYYY"
  };

  const handleViewAnalytics = (card) => {
    navigate(`/app/promo-analytics`, { state: { card } });
  };

  const handleReactivateClick = (card) => {
    setReactivateCard(card);
    setOpenReactivateDialog(true);
  };

  const generateRandomString = (length = 20) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  const handleConfirmReactivate = async () => {
    if (reactivateCard) {
      setLoading(true);

      try {
        const { id, ...newPromoCardData } = reactivateCard;
        const newPromoCardId = generateRandomString();
        const newPromoCard = {
          ...newPromoCardData,
          id: newPromoCardId,
          isActive: true,
          sentCount: 0,
          totalCustomersSent: 0,
          sentDetails: [],
        };

        await firebase.reactivatePromoCard(
          merchantID,
          newPromoCardId,
          newPromoCard
        );

        setSnackbarMessage("Promotion reactivated successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        loadPromoCards(merchantID);
      } catch (error) {
        setSnackbarMessage("Failed to reactivate promotion");
        setSnackbarSeverity("error");
        console.error("Failed to reactivate promotion:", error);
      } finally {
        setOpenReactivateDialog(false);
      }
    } else {
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      setOpenReactivateDialog(false);
    }
  };

  const handleCloseReactivateDialog = () => {
    setOpenReactivateDialog(false);
  };

  const handleShareClick = (card) => {
    const url = `www.reviewplusapp.com/share-promo/${merchantID}/${card.id}`;
    setShareUrl(url);
    setOpenShareDialog(true);
  };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
  };

  const handleSubscriptionClick = () => {
    navigate("/app/subscription", {
      replace: true,
    });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const activePromotions = promoCards.filter((card) => card.isActive);
  const inactivePromotions = promoCards.filter((card) => !card.isActive);

  return (
    <Page title="Promotions">
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid item className={classes.root}>
          <Box className={classes.titleContainer}>
            <Typography variant="h4" className={classes.title}>
              Exclusive Promotions
            </Typography>
            <Typography variant="body1" className={classes.titleDescription}>
              Create and manage promotions. You can set and schedule a promotion
              and it will automatically be sent to your customers. Create
              regular promotions to get more customers.{" "}
              {`You can create ${
                getPromotionLimit() === "Unlimited"
                  ? "unlimited"
                  : getPromotionLimit() - promoCards.length
              } promotions.`}
            </Typography>
          </Box>
        </Grid>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddPromoCard}
          sx={{ float: "right", mt: 2 }}
        >
          Add Promotion
        </Button>

        <Typography variant="h5" sx={{ mt: 4 }}>
          <strong>Active Promotions</strong>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          After a promotion is created, the promo email will automatically be
          sent on the scheduled date. You'll have the option to send the same
          promotion for a maximum of 5 times before the validity date and it
          becomes expired.
        </Typography>
        {loadingPromotions ? (
          <Box className={classes.loadingSpinner}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {activePromotions.length > 0 ? (
              <Grid container spacing={2} mt={1}>
                {activePromotions.map((card) => (
                  <Grid item xs={4} sm={4} md={4} lg={4} key={card.id}>
                    <Card className={classes.card}>
                      {/* <div onClick={() => handleEditPromoCard(card)}> */}
                      <div>
                        <CardMedia
                          className={classes.cardMedia}
                          image={
                            card.imageUrl || "https://via.placeholder.com/150"
                          }
                          alt={card.name}
                        />
                        <CardContent>
                          <Typography
                            variant="h5"
                            className={classes.cardTitle}
                          >
                            {card.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {card.description.length > 200
                              ? `${card.description.slice(0, 200)}...`
                              : card.description}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            mt={2}
                          >
                            <strong>Valid till: {card.endDate}</strong>
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            This promotion will become invalid on {card.endDate}{" "}
                            and no more emails can be scheduled.
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            mt={2}
                          >
                            <strong>Scheduled date: {card.scheduleDate}</strong>
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            This promotion will be automatically sent on{" "}
                            {card.endDate}.
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            mt={2}
                          >
                            <strong>Manual Promotion Trigger limit: </strong>
                            {card.sentCount || 0} / {card.maxSends || 5}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            Maximum no of times this promotions can be sent to
                            Customers to avoid spamming customer's email. Last
                            promotion was sent on: {card.lastSent}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            mt={2}
                          >
                            <strong>
                              Total Customers received this promotion:{" "}
                            </strong>
                            {card.totalCustomersSent || 0}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            Maximum no of times this promotions can be sent to
                            Customers.
                          </Typography>
                        </CardContent>
                      </div>
                      <CardActions disableSpacing>
                        <Button
                          variant="contained"
                          startIcon={<SendIcon />}
                          onClick={() => handleSendClick(card)}
                          disabled={card.sentCount >= card.maxSends}
                        >
                          Send
                        </Button>
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditPromoCard(card)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="share"
                          onClick={() => handleShareClick(card)}
                        >
                          <ShareIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteClick(card.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body1" color="#a82828" mt={1}>
                No active promotions found.
              </Typography>
            )}
          </>
        )}
        <Typography variant="h5" sx={{ mt: 4 }}>
          <strong>Inactive Promotions</strong>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          All expired promotions will be shown here. You can reactivate any
          expired promotion any time. This will be a history of all your
          promotions. Once a promo is reactivated, it will be a duplicate with
          new dates.
        </Typography>

        {inactivePromotions.length > 0 ? (
          <Grid container spacing={1} sx={{ mt: 2 }}>
            {inactivePromotions.map((card) => (
              <Grid item xs={4} sm={4} md={4} lg={4} key={card.id}>
                <Card>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.imageUrl || "https://via.placeholder.com/150"}
                    alt={card.name}
                  >
                    <div className={classes.overlay}></div>
                  </CardMedia>
                  <CardContent>
                    <Typography variant="h5" className={classes.cardTitle}>
                      {card.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {card.description}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={2}>
                      <strong>Valid till: {card.endDate}</strong>
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      This promotion will become invalid on {card.endDate} and
                      no more emails can be scheduled.
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={2}>
                      <strong>Manual Promotion Trigger limit: </strong>
                      {card.sentCount || 0} / {card.maxSends || 5}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Maximum no of times this promotions can be sent to
                      Customers to avoid spamming customer's email. Last
                      promotion was sent on {card.lastSent}.
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={2}>
                      <strong>Total Customers received this promotion: </strong>
                      {card.totalCustomersSent || 0}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Maximum no of times this promotions can be sent to
                      Customers.
                    </Typography>
                    <CardActions disableSpacing>
                      <Button
                        style={{ backgroundColor: "#ffd966" }}
                        variant="contained"
                        startIcon={<AnalyticsIcon />}
                        onClick={() => handleViewAnalytics(card)}
                        sx={{ mr: 1 }}
                        disabled
                      >
                        Analytics
                      </Button>
                      <IconButton
                        aria-label="reactivate"
                        onClick={() => handleReactivateClick(card)}
                        sx={{ mr: 1 }}
                      >
                        <ReactivateIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteClick(card.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" color="#a82828" mt={1}>
            No inactive promotions found.
          </Typography>
        )}
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          Delete Promotion
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogContent}
          >
            Are you sure you want to delete this promotion?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmDelete}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSendDialog}
        onClose={handleCloseSendDialog}
        aria-labelledby="alert-dialog-title-send"
        aria-describedby="alert-dialog-description-send"
        disableEscapeKeyDown // Disable escape key
      >
        <DialogTitle
          id="alert-dialog-title-send"
          className={classes.dialogTitle}
        >
          Send Promotion
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description-send"
            className={classes.dialogContent}
          >
            {promoCards.find((card) => card.id === sendCardId)?.sentCount === 4
              ? "This is your last time the promotion will be sent. After this, it will be deactivated."
              : "Are you sure you want to send this promotion email to all customers?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseSendDialog}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmSend}
            autoFocus
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openReactivateDialog}
        onClose={handleCloseReactivateDialog}
        aria-labelledby="alert-dialog-title-reactivate"
        aria-describedby="alert-dialog-description-reactivate"
      >
        <DialogTitle
          id="alert-dialog-title-reactivate"
          className={classes.dialogTitle}
        >
          Active Promotion again ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description-reactivate"
            className={classes.dialogContent}
          >
            Are you sure you want to reactivate this promotion? The new end date
            will be 1 week from today.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReactivateDialog}>Cancel</Button>
          <Button onClick={handleConfirmReactivate} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openShareDialog}
        onClose={handleCloseShareDialog}
        aria-labelledby="alert-dialog-title-share"
        aria-describedby="alert-dialog-description-share"
      >
        <DialogTitle
          id="alert-dialog-title-share"
          className={classes.dialogTitle}
        >
          Share Promotion
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" className={classes.dialogContent}>
            Share this promotion on your social media platforms to notify your
            followers!
          </Typography>
          <Box className={classes.shareButtons}>
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton url={shareUrl}>
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            {/* <TelegramShareButton url={shareUrl}>
              <TelegramIcon size={32} round />
            </TelegramShareButton> */}
            <EmailShareButton url={shareUrl}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            {/* <RedditShareButton url={shareUrl}>
              <RedditIcon size={32} round />
            </RedditShareButton> */}
            {/* <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseShareDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSubscriptionDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {}}
        disableEscapeKeyDown
      >
        <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
          Subscription Required! 😣
        </DialogTitle>
        <DialogContent>
          <Typography>
            Your subscription has expired. Please renew your subscription to
            access all features. If already subscribed, please relogin to gain
            access.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleBackClick}>
            Back
          </Button>
          <Button
            onClick={handleSubscriptionClick}
            color="secondary"
            variant="contained"
          >
            Subscribe Now
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPlanDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {}}
        disableEscapeKeyDown
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          😢 NOT AVAILABLE FOR BASIC PLAN
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" color="error">
            Upgrade your plan to access this feature. With promotions, you can
            directly schedule exclusive offers for your customers and provide
            updates to your instore customers about ongoing offers.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubscriptionClick}
          >
            Upgrade Plan
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default PromotionsManager;
