import { GlobalStyles as MuiGlobalStyles } from "@mui/material";
import React from "react";

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={{
        "*": { boxSizing: "border-box", margin: 0, padding: 0 },
        html: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          backgroundColor: "#f4f6f8",
          height: "100%",
          width: "100%",
        },
        a: { textDecoration: "none" },
        "#root": { height: "100%", width: "100%" },
      }}
    />
  );
};

export default GlobalStyles;
