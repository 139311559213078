import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Card,
  CardContent,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Rating from "@mui/material/Rating";
import { getGoogleReviewDetails } from "../../config/cloudApis";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    overflow: "visible",
    position: "relative",
    padding: theme.spacing(2),
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  listContainer: {
    maxHeight: "400px",
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
    borderRadius: "50%",
  },
  name: {
    fontWeight: "bold",
  },
  time: {
    color: "#666666",
    marginLeft: "auto",
  },
  reviewText: {
    marginTop: theme.spacing(1),
    color: "#666666",
  },
  reviewDivider: {
    marginTop: theme.spacing(2),
  },
  viewButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(0.5),
    },
  },
}));

function BmRecentReviewsContainer({ currentMerchant }) {
  const classes = useStyles();
  const [customerReviews, setCustomerReviews] = useState([]);
  const [placeId, setPlaceId] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentMerchant !== undefined && currentMerchant !== "") {
      getMerchantDataFirestore();
    }
  }, [currentMerchant]);

  async function getMerchantDataFirestore() {
    try {
      setLoading(true);
      const googlePlaceID = currentMerchant.googlePlaceID;
      const result = await getGoogleReviewDetails(googlePlaceID);

      if (result && result.data) {
        setCustomerReviews(result.data.reviews || []);
        setPlaceId(googlePlaceID);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setLoading(false);
    }
  }

  // const handleViewAll = () => {
  //   const PLACE_ID = placeId;
  //   window.open(
  //     `https://www.google.com/maps/place/?q=place_id:${PLACE_ID}&hl=en`,
  //     "_blank"
  //   );
  // };

  const handleViewAll = () => {
    navigate("/app/manage-google-reviews"); // Navigate to manage-google-reviews
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.viewButton} onClick={handleViewAll}>
          <Typography variant="caption">View all</Typography>
          <IconButton aria-label="view">
            <BsArrowRightShort />
          </IconButton>
        </div>
        <Typography className={classes.title}>Recent Reviews</Typography>
        <Divider className={classes.divider} />
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : customerReviews.length > 0 ? (
          <div className={classes.listContainer}>
            <Box sx={{ overflowX: "auto" }}>
              {customerReviews.map((review, index) => (
                <Box key={index} className={classes.listItem}>
                  <Avatar
                    className={classes.avatar}
                    alt={review.author_name}
                    src={review.profile_photo_url}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.name} variant="subtitle1">
                        {review.author_name}
                      </Typography>
                      <Typography className={classes.time} variant="caption">
                        {review.relative_time_description}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Rating value={review.rating} readOnly />
                    </Box>
                    <Typography className={classes.reviewText} variant="body2">
                      {review.text}
                    </Typography>
                    <Divider className={classes.reviewDivider} />
                  </Box>
                </Box>
              ))}
            </Box>
          </div>
        ) : (
          <Typography>No reviews available.</Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default BmRecentReviewsContainer;
