import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import angryEmoji from "../../utils/images/angry.png";
import sadEmoji from "../../utils/images/sad.png";
import okEmoji from "../../utils/images/ok.png";
import happyEmoji from "../../utils/images/happy.png";
import lovedEmoji from "../../utils/images/loved.png";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    overflow: "visible",
    position: "relative" /* add this */,
    padding: theme.spacing(2) /* add this */,
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "& > :nth-child(1)": {
      flex: "0.1",
    },
    "& > :nth-child(2)": {
      flex: "0.8",
    },
    "& > :nth-child(3)": {
      flex: "0.1",
    },
  },
  progress: {
    height: 6,
    borderRadius: 8,
    backgroundColor: "#F9EAC5",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#ECBA3F",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "1rem",
  },
  body: {
    fontSize: "0.9rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  smiley: {
    maxWidth: "38px",
  },
  footer: {
    position: "absolute",
    bottom: theme.spacing(2) /* add this */,
    right: theme.spacing(2) /* add this */,
    color: "#777",
    fontSize: "0.8rem",
  },
  viewButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: theme.spacing(0.5),
    },
  },
}));
/** Emojis component for the dashboard */
const BmExperienceCard = ({ percentage }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const emojis = ["angry", "sad", "ok", "happy", "loved"];
  const emojiChoices = {
    angry: angryEmoji,
    sad: sadEmoji,
    ok: okEmoji,
    happy: happyEmoji,
    loved: lovedEmoji,
  };

  const [displayedPercentages, setDisplayedPercentages] = useState(
    emojis.map(() => 0) // Initialize with zeros
  );

  useEffect(() => {
    const animatePercentage = () => {
      const newPercentages = [...displayedPercentages];
      emojis.forEach((_, index) => {
        if (newPercentages[index] < percentage[index]) {
          // Increment with smaller steps for smoother animation
          newPercentages[index] = Math.min(
            newPercentages[index] + 1.1,
            percentage[index]
          );

          // Immediately round to one decimal place to prevent large decimals
          newPercentages[index] = parseFloat(newPercentages[index].toFixed(1));
        }
      });
      setDisplayedPercentages(newPercentages);
    };

    const interval = setInterval(animatePercentage, 30); // Adjust speed
    return () => clearInterval(interval);
  }, [percentage, displayedPercentages]);

  const handleViewAll = () => {
    navigate("/app/customers", { replace: true });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.viewButton} onClick={handleViewAll}>
          <Typography variant="caption">View all</Typography>
          <IconButton aria-label="view">
            <BsArrowRightShort />
          </IconButton>
        </div>
        <Typography className={classes.title}>
          {" "}
          Customer Satisfaction
        </Typography>
        {emojis.map((emoji, index) => (
          <div className={classes.row} key={emoji}>
            <img
              src={emojiChoices[emoji]}
              alt={emoji}
              className={classes.smiley}
            />
            <LinearProgress
              variant="determinate"
              className={classes.progress}
              value={displayedPercentages[index]}
            />
            <Typography variant="subtitle1">
              {displayedPercentages[index]}%
            </Typography>
          </div>
        ))}
        <Typography variant="body2" className={classes.body} gutterBottom>
          {(() => {
            const sortedPercentages = [...percentage].sort((a, b) => b - a);
            const maxPercentage = sortedPercentages[0];
            const maxFeelingIndexes = percentage.reduce((acc, curr, index) => {
              if (curr === maxPercentage) {
                acc.push(index);
              }
              return acc;
            }, []);

            if (maxFeelingIndexes.length === 1) {
              const maxFeeling = emojis[maxFeelingIndexes[0]];
              return `Based on your customer satisfaction data, it appears that a majority of your customers are ${maxFeeling} with the service you provide.`;
            } else if (maxFeelingIndexes.length > 1) {
              // If there's a tie for the highest percentage, choose the most positive feeling
              const sortedMaxFeelingIndexes = maxFeelingIndexes.sort(
                (a, b) => b - a
              ); // Sort in descending order
              const mostPositiveFeelingIndex = sortedMaxFeelingIndexes[0];
              const mostPositiveFeeling = emojis[mostPositiveFeelingIndex];
              return `Based on your customer satisfaction data, it appears that a majority of your customers are ${mostPositiveFeeling} with the service you provide.`;
            } else {
              return "There is no customer satisfaction data available at this time.";
            }
          })()}
        </Typography>
        <Typography
          className={classes.footer}
          variant="caption"
          color="textSecondary"
          align="right"
        >
          {new Date().toLocaleDateString() +
            " " +
            new Date().toLocaleTimeString()}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default BmExperienceCard;
