import React, { useState, useEffect, useContext } from "react";
import firebase from "../../../config/firebase";
import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TablePagination,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useNavigate } from "react-router-dom";
import Page from "../../../utils/Page";
import * as XLSX from "xlsx";
import angryEmoji from "../../../utils/images/angry.png";
import sadEmoji from "../../../utils/images/sad.png";
import okEmoji from "../../../utils/images/ok.png";
import happyEmoji from "../../../utils/images/happy.png";
import lovedEmoji from "../../../utils/images/loved.png";
import {
  Download,
  FileUpload,
  ImportExport,
  Delete,
} from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MerchantContext from "../../../config/MerchantContext"; // Import MerchantContext

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.main, // Use primary color from the theme
  },
  tableText: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    padding: "8px 8px", // Adjust the padding as needed
  },
  filterButton: {
    color: theme.palette.common.black,
  },
  tableContainer: {
    height: "100%",
  },
  feelingCell: {
    fontSize: "1.5rem", // Adjust font size as needed
  },
  button: {
    border: "1px solid #000",
    padding: "8px",
  },
  noData: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  smiley: {
    height: "24px",
    width: "24px",
  },
  titleContainer: {
    // New style for title container
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  title: {
    userSelect: "none",
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: theme.palette.text.primary, // Usually black or dark grey
    marginBottom: theme.spacing(1),
  },
  titleDescription: {
    userSelect: "none",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
  },
  filterContainer: {
    // New style for filter container
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2), // Add space between filter elements
  },
  filterIcon: {
    cursor: "pointer", // Make the icon look interactive
  },
  root: {
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  toggleGroup: {
    // New style for ToggleButtonGroup
    "& .MuiToggleButtonGroup-grouped": {
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  },
  filterMenu: {
    // Added a new style for the Menu
    maxHeight: 300, // Limit the Menu height
    overflowY: "auto", // Enable scrolling if needed
  },
}));

function CustomerTable() {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { currentMerchant, plan } = useContext(MerchantContext); // Use context to get currentMerchant and plan
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [feelingFilterAnchor, setFeelingFilterAnchor] = useState(null);
  const [selectedFeelings, setSelectedFeelings] = useState([]);
  const [feedbackFilterAnchor, setFeedbackFilterAnchor] = useState(null);
  const [showNoFeedback, setShowNoFeedback] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleLogout = () => {
    logout();
  };

  async function logout() {
    try {
      await firebase.logout();
      sessionStorage.removeItem("currentMerchant");
      sessionStorage.removeItem("merchantData");
      navigate("/login", { replace: true });
    } catch (error) {
      alert(error.message);
    }
  }

  const getAllCustomers = (merchantID) => {
    try {
      setLoading(true);
      firebase.getAllMerchantCustomers(merchantID).then((data) => {
        const sortedData = data.sort((a, b) => b.timestamp - a.timestamp); // Sort by timestamp in descending order
        setCustomerData(sortedData);
        setLoading(false);
      });
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentMerchant) {
      const merchantID = currentMerchant.merchantID;
      getAllCustomers(merchantID);
      setOpenDialog(!currentMerchant.subscriptions.isSubscribed);
    }
  }, [currentMerchant]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleViewCustomerDetailsBtn = (customerData) => {
    navigate("/app/customer-details", {
      state: {
        filteredCustomers,
        customerData: {
          merchantId: currentMerchant.merchantID,
          plan: plan,
          visitDate: customerData.visitDate,
          feedback: customerData.lastfeedback,
          feeling: customerData.lastfeeling,
          name: customerData.name,
          phone: customerData.phone,
          gender: customerData.gender,
          email: customerData.email,
          dob: customerData.dob,
          country: customerData.country,
          lastFeedback: customerData.lastFeedback,
          birthdayOfferDetails: customerData.birthdayOfferDetails,
        },
      },
    });
  };

  const handleFeelingFilterClick = (event) => {
    setFeelingFilterAnchor(event.currentTarget);
  };

  const handleFeelingFilterChange = (event) => {
    const filterValue = event.target.value;
    if (selectedFeelings.includes(filterValue)) {
      setSelectedFeelings(
        selectedFeelings.filter((feeling) => feeling !== filterValue)
      );
    } else {
      setSelectedFeelings([...selectedFeelings, filterValue]);
    }
  };

  const handleFeelingFilterClose = () => {
    setFeelingFilterAnchor(null);
  };

  const handleFeedbackFilterClick = (event) => {
    setFeedbackFilterAnchor(event.currentTarget);
  };

  const handleFeedbackFilterClose = () => {
    setFeedbackFilterAnchor(null);
  };

  const handleNoFeedbackFilterChange = () => {
    setShowNoFeedback(!showNoFeedback);
  };

  const limitCustomerData = (data) => {
    if (plan === "BASIC_01") {
      return data.slice(0, 250);
    } else if (plan === "STAN_01") {
      return data.slice(0, 450);
    }
    return data;
  };

  const filteredCustomers = limitCustomerData(
    customerData.filter((customer) => {
      const nameMatch =
        customer.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        false; // Use optional chaining and provide default
      const emailMatch =
        customer.email?.toLowerCase().includes(searchText.toLowerCase()) ||
        false; // Use optional chaining and provide default
      const phoneMatch =
        customer.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
        false; // Use optional chaining and provide default

      // Feeling filter
      const feelingMatch =
        selectedFeelings.length === 0 ||
        selectedFeelings.includes(customer.lastFeeling);

      return (nameMatch || emailMatch || phoneMatch) && feelingMatch;
    })
  );

  const handleConfirmImport = () => {
    importCustomerData(fileData);
    setOpen(false);
  };

  const handleImportClick = () => {
    setUploadDialogOpen(true);
  };

  const closeDialogBoxes = () => {
    setUploadDialogOpen(false);
    setOpen(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const fileData = XLSX.utils.sheet_to_json(firstSheet);
      const filteredData = fileData.filter((row) => row.email); // Filter out rows without email
      if (filteredData.length > 0) {
        setFileData(filteredData);
        setOpen(true);
        setError("");
      } else {
        setError("No valid email data found in the file.");
      }
    };
    reader.readAsBinaryString(file);
  };

  const importCustomerData = async (fileData) => {
    setLoading(true);
    setUploadDialogOpen(false);
    try {
      await firebase.importCustomerDataToFirebase(
        currentMerchant.merchantID,
        fileData
      );
      setLoading(false);
      alert("Customer Data is imported successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading emails: ", error);
      alert("Failed to upload customer data. Invalid file uploaded.");
    } finally {
      setFileData([]);
    }
  };

  const downloadTemplate = () => {
    const sampleData = [
      {
        name: "John Doe",
        email: "john.doe@example.com",
        phone: "1234567890",
        address: "15 kings St, Ontario, L10 1A0",
      },
      {
        name: "Jane Smith",
        email: "jane.smith@example.com",
        phone: "0987654321",
        address: "2305 Reed Rd, Ontario, M20 3AA",
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
    XLSX.writeFile(workbook, "Customer_Template.xlsx");
  };

  const handleSubscriptionClick = () => {
    navigate("/app/subscription", {
      replace: true,
    });
  };

  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers((prevSelected) =>
      prevSelected.includes(customerId)
        ? prevSelected.filter((id) => id !== customerId)
        : [...prevSelected, customerId]
    );
  };

  const handleSelectAllCustomers = (event) => {
    if (event.target.checked) {
      const newSelected = filteredCustomers.map(
        (customer) => customer.customerID
      );
      setSelectedCustomers(newSelected);
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleDeleteSelectedCustomers = async () => {
    setConfirmDialogOpen(true);
  };

  const confirmDeleteCustomers = async () => {
    try {
      setLoading(true);
      setConfirmDialogOpen(false);
      const promises = selectedCustomers.map((customerId) =>
        firebase.deleteCustomer(currentMerchant.merchantID, customerId)
      );
      await Promise.all(promises);
      setSelectedCustomers([]);
      getAllCustomers(currentMerchant.merchantID);
      alert("Selected customers have been deleted successfully.");
    } catch (error) {
      console.error("Error deleting customers: ", error);
      alert("Failed to delete selected customers.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title="Customers">
      <Box sx={{ flexGrow: 1, p: 3 }} className={classes.root}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Box className={classes.titleContainer}>
              <Typography variant="h4" className={classes.title}>
                Customers
              </Typography>
              <Typography variant="body1" className={classes.titleDescription}>
                View and manage your customer information. Send Winback coupons
                and setup birthday offers for your beloved customers.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="form"
              sx={{
                display: "flex",
                justifyContent: "space-between", // Adjust as needed
                alignItems: "center",
                "& > :not(style)": {
                  width: { xs: "100%", sm: "30ch" },
                  mb: { xs: 2, sm: 0 },
                },
              }}
            >
              {/* Search Bar */}
              <TextField
                id="outlined-basic"
                label="Search"
                value={searchText}
                variant="outlined"
                onChange={handleSearchTextChange}
              />

              {/* Conditionally Render Import Button */}
              {plan === "PREMIUM_01" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleImportClick}
                  startIcon={!isMobile && <ImportExport />}
                  sx={{
                    fontSize: isMobile ? "0.6rem" : "1rem",
                    margin: theme.spacing(2),
                  }}
                >
                  Import Customers
                </Button>
              )}

              {/* Bulk Delete Button */}
              {selectedCustomers.length > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteSelectedCustomers}
                  startIcon={<Delete />}
                  sx={{
                    fontSize: isMobile ? "0.6rem" : "1rem",
                    margin: theme.spacing(2),
                  }}
                >
                  Delete Selected ({selectedCustomers.length})
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          className={classes.tableContainer}
          sx={{ mt: 3 }}
        >
          <Table className={classes.table} aria-label="customer table">
            <TableHead
              className={classes.tableHeader}
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedCustomers.length > 0 &&
                      selectedCustomers.length < filteredCustomers.length
                    }
                    checked={
                      filteredCustomers.length > 0 &&
                      selectedCustomers.length === filteredCustomers.length
                    }
                    onChange={handleSelectAllCustomers}
                    color="primary"
                  />
                </TableCell>
                <TableCell className={classes.tableText} sx={{ width: "15%" }}>
                  Name
                </TableCell>
                <TableCell className={classes.tableText} sx={{ width: "20%" }}>
                  Email
                </TableCell>
                <TableCell className={classes.tableText} sx={{ width: "15%" }}>
                  Phone
                </TableCell>
                <TableCell
                  className={classes.tableText}
                  style={{ width: "10%", textAlign: "center" }}
                >
                  {/* Feeling Filter with dropdown menu */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Feeling</span>
                    <IconButton
                      className={classes.filterButton}
                      onClick={handleFeelingFilterClick}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <FilterListOutlinedIcon />
                    </IconButton>
                  </div>
                  <Menu
                    anchorEl={feelingFilterAnchor}
                    open={Boolean(feelingFilterAnchor)}
                    onClose={handleFeelingFilterClose}
                  >
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFeelings.includes("loved")}
                            onChange={handleFeelingFilterChange}
                            value="loved"
                          />
                        }
                        label="Loved"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFeelings.includes("happy")}
                            onChange={handleFeelingFilterChange}
                            value="happy"
                          />
                        }
                        label="Happy"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFeelings.includes("ok")}
                            onChange={handleFeelingFilterChange}
                            value="ok"
                          />
                        }
                        label="Ok"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFeelings.includes("sad")}
                            onChange={handleFeelingFilterChange}
                            value="sad"
                          />
                        }
                        label="Sad"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFeelings.includes("angry")}
                            onChange={handleFeelingFilterChange}
                            value="angry"
                          />
                        }
                        label="Angry"
                      />
                    </MenuItem>
                  </Menu>
                </TableCell>
                <TableCell
                  className={classes.tableText}
                  style={{ width: "30%" }}
                >
                  Feedback
                </TableCell>
                <TableCell className={classes.tableText} sx={{ width: "10%" }}>
                  Last visit
                </TableCell>
                <TableCell className={classes.tableText} sx={{ width: "10%" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} className={classes.noData}>
                    <Typography>
                      No customer data available. Please try again later.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredCustomers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((customerData, index) => (
                    <TableRow
                      key={customerData.customerID || index}
                      selected={selectedCustomers.includes(
                        customerData.customerID
                      )}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedCustomers.includes(
                            customerData.customerID
                          )}
                          onChange={() =>
                            handleSelectCustomer(customerData.customerID)
                          }
                          color="primary"
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <span
                          onClick={() =>
                            handleSelectCustomer(customerData.customerID)
                          }
                        >
                          {customerData.name}
                        </span>
                      </TableCell>
                      <TableCell>{customerData.email}</TableCell>
                      <TableCell>{customerData.phone}</TableCell>
                      <TableCell className={classes.feelingCell}>
                        {customerData.lastFeeling === "loved" ? (
                          <img
                            src={lovedEmoji}
                            alt="Loved"
                            className={classes.smiley}
                          />
                        ) : customerData.lastFeeling === "happy" ? (
                          <img
                            src={happyEmoji}
                            alt="Happy"
                            className={classes.smiley}
                          />
                        ) : customerData.lastFeeling === "ok" ? (
                          <img
                            src={okEmoji}
                            alt="Ok"
                            className={classes.smiley}
                          />
                        ) : customerData.lastFeeling === "sad" ? (
                          <img
                            src={sadEmoji}
                            alt="Sad"
                            className={classes.smiley}
                          />
                        ) : customerData.lastFeeling === "angry" ? (
                          <img
                            src={angryEmoji}
                            alt="Angry"
                            className={classes.smiley}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        {customerData.lastFeedback || "Not provided"}
                      </TableCell>
                      <TableCell>{customerData.visitDate}</TableCell>
                      <TableCell>
                        <Button
                          className={classes.button}
                          variant="contained"
                          onClick={() =>
                            handleViewCustomerDetailsBtn(customerData)
                          }
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCustomers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Dialog
          open={uploadDialogOpen}
          onClose={closeDialogBoxes}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Import Customer Data (.xlsx, .xls, .csv)
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadTemplate}
                  startIcon={<Download />}
                  fullWidth
                >
                  Download Template
                </Button>
                <FormHelperText>
                  Click to download a template file to fill in your customer
                  data.{" "}
                  <strong>Make sure all fields are properly filled.</strong>
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Button
                  startIcon={<FileUpload />}
                  variant="contained"
                  component="label"
                  fullWidth
                >
                  Choose File
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
                <FormHelperText>
                  Choose the file containing your customer data to upload.
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialogBoxes} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontWeight: "bold", fontSize: "1.5em" }}
          >
            IMPORT CUSTOMERS DATA (PREVIEW)
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2" id="alert-dialog-description">
              Please confirm that the data below is correct. Click "OK" to
              proceed with the import or "CANCEL" to stop.
            </DialogContentText>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialogBoxes} color="secondary" size="small">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmImport}
              color="primary"
              autoFocus
              size="small"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontWeight: "bold", fontSize: "1.5em" }}
          >
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              Are you sure you want to delete the selected customers? This
              action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDialogOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={confirmDeleteCustomers}
              color="secondary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => {}}
          disableEscapeKeyDown
        >
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Subscription Required! 😣
          </DialogTitle>
          <DialogContent>
            <Typography>
              Your subscription has expired. Please renew your subscription to
              access all features. If already subscribed, please relogin to gain
              access.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              onClick={handleSubscriptionClick}
              color="secondary"
              variant="contained"
            >
              Subscribe Now
            </Button>
          </DialogActions>
        </Dialog>

        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Page>
  );
}

export default CustomerTable;
