import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import {
  GoogleMap,
  Autocomplete,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";

const styles = (theme) => ({
  searchBox: {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "240px",
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    height: "32px",
    marginTop: "7px",
    marginLeft: "10px",
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipsis",
    zIndex: 10,
    position: "absolute",
  },
});

const containerStyle = {
  height: "320px",
};

const libraries = ["places"];

const mapId = "b0b44ed0e72e3cf8"; // Replace with your actual Map ID

const SearchLocation = ({ lati, longi, passChildData, classes }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCM_tLubOkPLAsMNHiPl9Zh3UaIBiZqt3o",
    libraries,
  });

  const [placeData, setPlaceData] = useState(null);
  const [autocomplete, setAutoComplete] = useState(null);

  const functionHandler = (data) => {
    passChildData(data);
  };

  const [value, setValue] = useState({
    lat: 0.0,
    lng: 0.0,
    placeId: "",
  });

  const [centerValue, setCenterValue] = useState({
    lat: 45.49631759999999,
    lng: -73.578297,
  });

  const onLoad = (autocompleteVal) => {
    setAutoComplete(autocompleteVal);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setPlaceData(autocomplete.getPlace());
    } else {
      console.log("Something went wrong in Google Maps. Please try again.");
    }
  };

  const handleChange = (data) => {
    if (data.target.value === "") {
      setValue({
        lat: 0.0,
        lng: 0.0,
        placeId: "",
      });
      setPlaceData(null);
    }
  };

  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
  };

  useEffect(() => {
    if (placeData != null) {
      setValue({
        lat: placeData.geometry.location.lat(),
        lng: placeData.geometry.location.lng(),
        placeId: placeData.place_id,
      });

      setCenterValue({
        lat: placeData.geometry.location.lat(),
        lng: placeData.geometry.location.lng(),
      });

      functionHandler(autocomplete.getPlace());
    }
  }, [placeData]);

  useEffect(() => {
    if (!isNaN(lati) && !isNaN(longi)) {
      setCenterValue({
        lat: parseFloat(lati),
        lng: parseFloat(longi),
      });

      setValue({
        lat: parseFloat(lati),
        lng: parseFloat(longi),
      });
    }
  }, [lati, longi]);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <GoogleMap
      id="searchbox"
      mapContainerStyle={containerStyle}
      zoom={15}
      center={centerValue}
      options={defaultMapOptions}
      mapId={mapId}
    >
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          autoComplete="new-password"
          onChange={handleChange}
          type="text"
          placeholder="Enter your Business Name/Address"
          className={classes.searchBox}
        />
      </Autocomplete>
      {value.lat !== 0 && value.lng !== 0 && (
        <Marker position={{ lat: value.lat, lng: value.lng }} />
      )}
    </GoogleMap>
  );
};

export default withStyles(styles)(SearchLocation);
