import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    overflow: "visible",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  icon: {
    marginTop: theme.spacing(2),
    fontSize: "3rem",
    color: "#4280EF",
  },
  mediumText: {
    fontWeight: "bold",
  },
  largeText: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "2rem",
    color: "#4280EF",
  },
}));

function BmTotalCustomersCard(props) {
  const { icon, mediumText, largeText } = props;
  const classes = useStyles();
  const [displayedNumber, setDisplayedNumber] = useState(0);

  useEffect(() => {
    let currentNumber = 0;
    const increment = parseInt(largeText, 10) / 20; // Convert to integer for smoother animation

    const animationInterval = setInterval(() => {
      if (currentNumber >= parseInt(largeText, 10)) {
        clearInterval(animationInterval);
        setDisplayedNumber(largeText); // Ensure the final value is accurate
      } else {
        currentNumber += increment;
        setDisplayedNumber(Math.round(currentNumber)); // Round for whole numbers
      }
    }, 50); // Adjust interval for speed

    return () => clearInterval(animationInterval);
  }, [largeText]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid item>
          <Typography className={classes.icon}>{icon}</Typography>
        </Grid>
        <Typography variant="subtitle1" className={classes.mediumText}>
          {mediumText}
        </Typography>
        <Typography variant="h5" className={classes.largeText}>
          {displayedNumber}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default BmTotalCustomersCard;
