import React from 'react'

const RegisterView = () => {

    return(
        <div>

        </div>
    );
}


export default RegisterView;