import React from "react";
import { Navigate } from "react-router-dom";
import RegisterView from "../views/auth/RegisterView";
import LoginView from "../views/auth/LoginView";
import NotFoundView from "../views/errors/NotFoundView";
import DashboardLayout from "../views/layouts/DashboardLayout/index";
import ThankYou from "../views/onBoarding/ThankYou";
import MerchantOnBoard from "../views/onBoarding/MerchantOnboarding";
import MerchantOnBoardFinish from "../views/onBoarding/MerchantFinishOnboarding";
import CustomerView from "../views/customer";
import RedirectToGoogle from "../views/customer/RedirectToGoogleReview";
import CustomerDetailsView from "../views/customer/CustomerDetails";
import FullCustomerDetails from "../views/merchant/customers/CustomerDetails";
import CustomerExperienceView from "../views/customer/CustomerExperience";
import TestWidgetsView from "../views/testwidgets";
import SettingsView from "../views/clover/CloverMerchantSettings";
import CustomerDataView from "../views/merchant/customers";
import SubscriptionView from "../views/merchant/subscription";
import DashboardView from "../views/merchant/dashboard";
import Passcode from "../views/onBoarding/Passcode";
import CloverSetup from "../views/clover/CloverSetup";
import SetupComplete from "../views/clover/SetupComplete";
import CloverThankYou from "../views/clover/CloverThankYou";
import CloverDashboard from "../views/clover/CloverDashboard";
import CustomerTable from "../views/clover/CustomerTable";
import MerchantSettings from "../views/settings";
import CreatePromoCard from "../views/merchant/promotions/CreatePromoCard";
import EditPromoCard from "../views/merchant/promotions/EditPromoCard";
import MerchantDashboard from "../views/merchant/dashboard/MerchantDashboard";
import LogoutMerchant from "../utils/LogoutMerchant";
import PromotionsManager from "../views/merchant/promotions";
import OAuthCallback from "../components/oauth/OAuthCallback";
import PromotionDetailCard from "../views/merchant/promotions/PromotionDetailCard";
import CouponManagement from "../views/merchant/coupons/CouponManagement";
import CreateCoupon from "../views/merchant/coupons/CreateCoupon";
import EditCoupon from "../views/merchant/coupons/EditCoupon";
import MerchantCompleteRegistration from "../views/onBoarding/MerchantCompleteRegistration";
import SetupCompleteThankYou from "../views/onBoarding/SetupCompleteThankYou";
import EmployeesView from "../views/merchant/employees";
import EmployeeCompleteRegistration from "../views/merchant/employees/EmployeeCompleteRegistration";
import RoleSelection from "../views/auth/RoleSelection";
import RedeemOffer from "../views/merchant/coupons/RedeemOffer";
import ShowCouponQr from "../views/merchant/coupons/ShowCouponQr";
import CustomerExperienceTabletMode from "../views/tabletCustomerMode/CustomerExperienceTabletMode"; // Import the new component
import CustomerDetailsTabletMode from "../views/tabletCustomerMode/CustomerDetailsTabletMode"; // Import the new component
import ThankYouTabletMode from "../views/tabletCustomerMode/ThankyouTabletMode";
import CustomerViewTableMode from "../views/tabletCustomerMode";
import HomeScreenView from "../views/home";
import TermsConditions from "../views/home/TermsConditions";
import TestExportCustomer from "../views/testwidgets/TestExportCustomer";
import { element } from "prop-types";
import CustomerUnsubscribe from "../views/customer/CustomerUnsubscribe";
import GoogleOAuthCallback from "../components/oauth/GoogleOAuthCallback";
import RedeemBirthdayOffer from "../views/merchant/coupons/RedeemBirthdayOffer";
import GoogleReviewManagementView from "../views/merchant/google-reviews/GoogleReviewsManagementView";
import AdminMerchantComponent from "../views/admin/AdminMerchantComponent";
import AdminMerchantEditComponent from "../views/admin/AdminMerchantEditComponent";

const routes = (isLoggedIn) => [
  {
    path: "app",
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: "dashboard", element: <DashboardView /> },
      { path: "promotions", element: <PromotionsManager /> },
      { path: "employees", element: <EmployeesView /> },
      { path: "coupons", element: <CouponManagement /> },
      { path: "create-coupon", element: <CreateCoupon /> },
      { path: "edit-coupon", element: <EditCoupon /> },
      { path: "add-promo-card", element: <CreatePromoCard /> },
      { path: "edit-promo-card", element: <EditPromoCard /> },
      {
        path: "manage-google-reviews",
        element: <GoogleReviewManagementView />,
      },
      { path: "subscription", element: <SubscriptionView /> },
      { path: "customers", element: <CustomerDataView /> },
      { path: "customer-details", element: <FullCustomerDetails /> },
      { path: "mdashboard", element: <MerchantDashboard /> },
      { path: "settings", element: <MerchantSettings /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? "" : "",
    children: [
      {
        path: "login",
        element: !isLoggedIn ? <LoginView /> : <Navigate to="/app/dashboard" />,
      },
      {
        path: "oauth2callback",
        element: <OAuthCallback />,
      },
      {
        path: "admin-manage",
        element: <AdminMerchantComponent />,
      },
      {
        path: "admin-edit-merchant",
        element: <AdminMerchantEditComponent />,
      },
      {
        path: "google-oauth2callback",
        element: <GoogleOAuthCallback />,
      },
      {
        path: "home",
        element: <HomeScreenView />,
      },
      {
        path: "terms",
        element: <TermsConditions />,
      },
      { path: "role-selection", element: <RoleSelection /> },
      { path: "authenticate", element: <Passcode /> },
      { path: "merchant-onboarding-start", element: <MerchantOnBoard /> },
      {
        path: "merchant-onboarding-finish/",
        element: <MerchantOnBoardFinish />,
      },
      {
        path: "complete-registration/:id",
        element: <MerchantCompleteRegistration />,
      },
      {
        path: "employee-complete-registration",
        element: <EmployeeCompleteRegistration />,
      },
      { path: "cs-start/:id", element: <CloverSetup /> },
      { path: "cs-finish/", element: <SetupComplete /> },
      { path: "cs-thankyou/", element: <CloverThankYou /> },
      { path: "cs-dashboard/:id", element: <CloverDashboard /> },
      { path: "cs-customers/:id", element: <CustomerTable /> },
      { path: "cs-settings/:id", element: <SettingsView /> },
      { path: "customer-review/:lan/:id", element: <CustomerView /> },
      { path: "customer-review/:id", element: <CustomerView /> },
      { path: "customer-details/", element: <CustomerDetailsView /> },

      // { path: "test-export", element: <TestExportCustomer /> },
      { path: "customer-exp/", element: <CustomerExperienceView /> },
      {
        path: "customer-exp-tablet-mode",
        element: <CustomerExperienceTabletMode />,
      },
      {
        path: "customer-details-tablet-mode",
        element: <CustomerDetailsTabletMode />,
      },
      {
        path: "customer-view-tablet-mode/:id",
        element: <CustomerViewTableMode />,
      },
      { path: "thankyou-tablet-mode", element: <ThankYouTabletMode /> },
      { path: "google-review-direct/:id", element: <RedirectToGoogle /> },
      { path: "register", element: <RegisterView /> },
      { path: "test", element: <TestWidgetsView /> },
      { path: "thankyou", element: <ThankYou /> },
      { path: "setup-complete-thankyou", element: <SetupCompleteThankYou /> },
      {
        path: "share-promo/:merchantId/:promoId",
        element: <PromotionDetailCard />,
      },
      {
        path: "unsubscribe/:merchantId/:customerEmail",
        element: <CustomerUnsubscribe />,
      },
      {
        path: "redeem-offer/:merchantId/:customerEmail/:couponId",
        element: <RedeemOffer />,
      },
      {
        path: "redeem-birthday-offer/:merchantId/:customerEmail/:couponId",
        element: <RedeemBirthdayOffer />,
      },
      {
        path: "coupon-link/:merchantId/:customerEmail/:couponId",
        element: <ShowCouponQr />,
      },
      { path: "/", element: <Navigate to="/home" /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "logout-user", element: <LogoutMerchant /> },
    ],
  },
];

export default routes;
