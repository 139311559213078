import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import Logo from "../../icons/logo.png";
import Lottie from "lottie-react";
import AngryAnim from "../../utils/animations/angryanim.json";
import HappyAnim from "../../utils/animations/happyanim.json";
import NeutralAnim from "../../utils/animations/neutralanim.json";
import SadAnim from "../../utils/animations/sadanim.json";
import LovedAnim from "../../utils/animations/lovedanim.json";
import angryEmoji from "../../utils/images/angry.png";
import sadEmoji from "../../utils/images/sad.png";
import okEmoji from "../../utils/images/ok.png";
import happyEmoji from "../../utils/images/happy.png";
import lovedEmoji from "../../utils/images/loved.png";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";
import "../../utils/i18n"; // Ensure i18n is initialized

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 95%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  appTitle: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "2.5rem", // Increased font size
    color: theme.palette.text.primary,
    animation: "$fadeIn 2.5s",
    fontFamily: "'Roboto', sans-serif",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
    userSelect: "none",
  },
  glowingIcon: {
    margin: theme.spacing(1, 0),
    animation: "$glow 2.5s infinite",
    borderRadius: "50%",
    width: "130px", // Increased width
    height: "130px", // Increased height
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.7)",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes glow": {
    "0%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
    "50%": { boxShadow: "0 0 20px rgba(255, 255, 255, 1)" },
    "100%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
  },
  lottieAnim: {
    width: "350px", // Increased width
    height: "350px", // Increased height
  },
  changingText: {
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "1.5rem", // Increased font size
    margin: theme.spacing(2, 0),
    animation: "$fadeText 3s linear infinite",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    userSelect: "none",
  },
  "@keyframes fadeText": {
    "0%": { opacity: 0 },
    "10%": { opacity: 1 },
    "90%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
  sliderContainer: {
    width: "80%",
    marginTop: theme.spacing(1),
    position: "relative",
  },
  slider: {
    marginBottom: theme.spacing(3),
    width: "100%",
    "-webkit-appearance": "none",
    appearance: "none",
    height: "8px",
    borderRadius: "4px",
    outline: "none",
    opacity: "0.7",
    transition: "opacity .2s",
    background: `
      linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main} ),
      radial-gradient(circle at center, transparent 0%, transparent 80%, #d3d3d3 80%)
    `,
    backgroundSize: "100% 100%, 20% 100%",
    backgroundPosition: "center, center",
    backgroundRepeat: "no-repeat, no-repeat",
    animation: "$slideAnim 1.5s infinite linear",
    "&:hover": {
      opacity: 1,
    },
    "&::-webkit-slider-thumb": {
      "-webkit-appearance": "none",
      appearance: "none",
      width: "80px", // Increased width
      height: "35px", // Increased height
      borderRadius: "12px",
      background: `${theme.palette.secondary.main}`,
      cursor: "pointer",
      transition: "background .2s",
      textAlign: "center",
      lineHeight: "35px", // Adjusted for increased height
      fontWeight: "bold",
      fontSize: "18px", // Increased font size
      backgroundSize: "100% 100%",
      border: "none",
      userSelect: "none",
      position: "relative",
      "&::before": {
        content: "attr(data-label)", // Placeholder for translation
        position: "absolute",
        bottom: "40px", // Adjusted for increased size
        left: "50%",
        transform: "translateX(-50%)",
        color: "rgba(0, 0, 0, 0.7)",
        fontSize: "18px", // Increased font size
        fontWeight: "bold",
        whiteSpace: "nowrap",
        transition: "color 0.2s",
        userSelect: "none",
      },
    },
  },
  "@keyframes slideAnim": {
    "0%": { backgroundPosition: "0% 0, center" },
    "100%": { backgroundPosition: "100% 0, center" },
  },
  emojiLabel: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(1),
    position: "relative",
    zIndex: 1,
    userSelect: "none",
  },
  markVisitButton: {
    marginTop: theme.spacing(3),
    background: `${theme.palette.secondary.light}`,
    color: "#000",
    fontWeight: "bold",
    width: "80%",
    fontSize: "24px",
    borderRadius: "24px",
    padding: theme.spacing(1, 2),
    // boxShadow: `0 0 4px ${theme.palette.secondary.main}`,
    animation: "$buttonGlow 2.5s infinite",
    textTransform: "none",
    userSelect: "none",
    "&:hover": {
      background: `${theme.palette.secondary.light}`,
    },
  },
  "@keyframes buttonGlow": {
    "0%": { boxShadow: "0 0 5px rgba(251, 36, 108, 0.5)" },
    "50%": { boxShadow: "0 0 10px rgba(251, 36, 108, 1)" },
    "100%": { boxShadow: "0 0 5px rgba(251, 36, 108, 0.5)" },
  },
  footer: {
    position: "absolute",
    bottom: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "1rem", // Increased font size
    opacity: 0.8,
    width: "100%",
    userSelect: "none",
  },
  businessName: {
    fontSize: "2.1rem", // Increased font size
    fontWeight: "bold",
    textAlign: "center",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    fontFamily: "'Roboto', sans-serif",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
    userSelect: "none",
  },
  emoji: {
    width: "150px", // Adjusted size
    height: "150px", // Adjusted size
    transform: "scale(1.2)", // Increased scaling
    transition: "transform 0.3s ease-in-out, top 0.3s ease-in-out",
    userSelect: "none",
    animation: "$fadeIn 1s",
  },
  smallEmoji: {
    width: "50px", // Increased size
    height: "50px", // Increased size
    transition: "transform 0.3s ease-in-out",
    userSelect: "none",
  },
  selectedSmallEmoji: {
    width: "60px", // Increased size
    height: "60px", // Increased size
    transform: "scale(1.2)", // Slightly increased scaling
    transition: "transform 0.3s ease-in-out",
    userSelect: "none",
  },
}));

const emojiAnimations = {
  angry: AngryAnim,
  sad: SadAnim,
  ok: NeutralAnim,
  happy: HappyAnim,
  loved: LovedAnim,
};

const emojiChoices = {
  angry: angryEmoji,
  sad: sadEmoji,
  ok: okEmoji,
  happy: happyEmoji,
  loved: lovedEmoji,
};

const emojis = ["angry", "sad", "ok", "happy", "loved"];
const changingTexts = [
  "RECEIVE_BIRTHDAY_COUPONS",
  "TRACK_VISITS",
  "EARN_COUPONS",
  "GET_NOTIFIED",
  "GAIN_POINTS",
  "FEEDBACK_MANAGEMENT",
  "JOIN_LOYALTY",
  "PARTICIPATE_EVENTS",
];

const CustomerExperienceTabletMode = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  const [btnSelect, setBtnSelect] = useState("happy"); // Default to "happy"
  const [sliderValue, setSliderValue] = useState(75); // Default slider position for "happy"
  const [currentText, setCurrentText] = useState(0);
  const [showEmoji, setShowEmoji] = useState(false);
  const logoTimeoutRef = useRef(null);
  const history = createBrowserHistory();

  useEffect(() => {
    if (state == null) {
      navigate("/not-found", { replace: true });
      return;
    } else {
      i18n.changeLanguage(state.language); // Change language based on state.language
    }

    // Set initial emoji display based on btnSelect
    setShowEmoji(true);

    // Clear the timeout (if it exists) to prevent immediate hiding
    if (logoTimeoutRef.current) {
      clearTimeout(logoTimeoutRef.current);
    }

    // Set a new timeout to hide the emoji after 3 seconds
    logoTimeoutRef.current = setTimeout(() => {
      setShowEmoji(false);
    }, 3000);
  }, [state, i18n, navigate]);

  useEffect(() => {
    if (!state) {
      history.replace("/not-found");
    }
  }, [state, history]);

  const handleSliderChange = (e) => {
    const value = e.target.value;
    setSliderValue(value);
    // Calculate the selected emoji based on slider value
    const selectedEmojiIndex = Math.round(value / (100 / (emojis.length - 1)));
    const selectedEmoji = emojis[selectedEmojiIndex];
    setBtnSelect(selectedEmoji);

    setShowEmoji(true);
    if (logoTimeoutRef.current) {
      clearTimeout(logoTimeoutRef.current);
    }

    logoTimeoutRef.current = setTimeout(() => {
      setShowEmoji(false);
    }, 3000);

    const thumbColor = `linear-gradient(to bottom, #ffdcbf, #fe9292, #fe5184, #fb246c)`;
    e.target.style.setProperty("--thumb-color", thumbColor);
  };

  const handleEmojiClick = (index) => {
    const value = (index * 100) / (emojis.length - 1);
    setSliderValue(value);
    setBtnSelect(emojis[index]);

    setShowEmoji(true);
    if (logoTimeoutRef.current) {
      clearTimeout(logoTimeoutRef.current);
    }

    logoTimeoutRef.current = setTimeout(() => {
      setShowEmoji(false);
    }, 3000);
  };

  const handleSubmit = () => {
    if (btnSelect != null) {
      const choice = btnSelect;
      navigate(
        "/customer-details-tablet-mode",
        {
          state: {
            ...state, // Ensure all state properties are passed correctly
            choice: choice,
          },
        },
        { replace: true }
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % changingTexts.length);
    }, 3000);
    if (state === null) {
      navigate("/not-found", { replace: true });
    }
    return () => clearInterval(interval);
  }, [state, navigate]);

  return (
    <Page className={classes.root} title={t("CUSTOMER_REVIEW")}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="sm">
          {state && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography className={classes.appTitle}>
                {t("EXPERIENCE_TODAY")}
              </Typography>
              <Box className={classes.glowingIcon}>
                {showEmoji ? (
                  <Lottie
                    className={classes.emoji}
                    animationData={emojiAnimations[btnSelect]}
                    style={{
                      width: btnSelect === "angry" ? "75%" : "100%",
                      height: btnSelect === "angry" ? "75%" : "100%",
                    }}
                  />
                ) : (
                  <img
                    src={state.businessLogoUrl || Logo}
                    alt="Business Logo"
                    style={{
                      borderRadius: "50%",
                      width: "150px", // Increased width
                      height: "150px", // Increased height
                      objectFit: "cover",
                    }}
                  />
                )}
              </Box>
              <Typography className={classes.businessName}>
                {state.businessName}
              </Typography>
              <Typography className={classes.changingText}>
                {t(changingTexts[currentText])}
              </Typography>

              <div className={classes.sliderContainer}>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={sliderValue}
                  className={`${classes.slider} ${classes.dragMeThumb}`}
                  onChange={handleSliderChange}
                  style={{
                    "--thumb-color":
                      "linear-gradient(to bottom, #ffdcbf, #fe9292, #fe5184, #fb246c)",
                  }}
                />
                <div className={classes.emojiLabel}>
                  {emojis.map((emoji, index) => (
                    <img
                      key={emoji}
                      src={emojiChoices[emoji]}
                      alt={emoji}
                      className={`${classes.smallEmoji} ${
                        index ===
                          Math.round(
                            sliderValue / (100 / (emojis.length - 1))
                          ) && classes.selectedSmallEmoji
                      }`}
                      onClick={() => handleEmojiClick(index)}
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
              <Button
                className={classes.markVisitButton}
                onClick={handleSubmit}
                disabled={!btnSelect}
              >
                {t("MARK_VISIT")}
              </Button>
            </Grid>
          )}
          {!state && <div>{t("SCAN_QR_AGAIN")}</div>}
        </Container>
        <Typography className={classes.footer}>Powered by Review+</Typography>
      </Box>
    </Page>
  );
};

export default CustomerExperienceTabletMode;
