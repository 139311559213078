import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import MegaMenuLanding from "../../header/mega-menu/MegaMenuLanding";

const HeaderLandingAppointment = ({ toggleModalOne, setEmail }) => {
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-six bg-none fixed"
            : "theme-main-menu sticky-menu theme-menu-six bg-none"
        }
      >
        <div className="d-flex align-items-center">
          <div className="logo d-flex align-items-center">
            <a href="home#home">
              <img
                style={{ height: "56px" }}
                src="images/logo/logo.svg"
                alt="Review+"
              />
            </a>
            <span
              className="logo-text"
              style={{
                marginLeft: "10px",
                fontSize: "20px",
                fontFamily: "Helvetica, Arial, sans-serif",
              }}
            >
              Review+
            </span>
          </div>
          {/* End logo */}

          <div className="right-widget order-lg-3">
            <ul className="d-flex align-items-center">
              <li>
                <Link
                  to="/login"
                  className="signIn-action d-flex align-items-center"
                >
                  {/* <img src="images/icon/120.svg" alt="icon" /> */}
                  <span className="login-btn">Login</span>
                </Link>
              </li>
              <li>
                <button
                  className="signup-btn"
                  onClick={() => {
                    toggleModalOne();
                    setEmail("");
                  }}
                >
                  <span>Book a Demo</span>
                </button>
              </li>
            </ul>
          </div>
          {/* End .right-widget */}

          <nav
            id="mega-menu-holder"
            className="navbar navbar-expand-lg ms-lg-auto order-lg-2"
          >
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={["home", "features", "howitworks", "integrations"]}
                    currentClassName="active"
                    offset={-90}
                  >
                    {/* <li className="nav-item dropdown position-static">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#home"
                        data-toggle="dropdown"
                      >
                        Home
                      </a>
                      <div className="dropdown-menu">
                        <MegaMenuLanding />
                      </div>
                    </li> */}

                    <li className="nav-item">
                      <a
                        href="home#home"
                        className="nav-link"
                        style={{ color: "black" }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="home#features"
                        className="nav-link"
                        style={{ color: "black" }}
                      >
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="home#howitworks"
                        className="nav-link"
                        style={{ color: "black" }}
                      >
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="home#integrations"
                        className="nav-link"
                        style={{ color: "black" }}
                      >
                        Integrations
                      </a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div
          className="logo-container"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            style={{ height: "45px" }}
            src="images/logo/logo.svg"
            alt="brand"
          />
          <span
            className="logo-text"
            style={{
              marginLeft: "10px",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
            }}
          >
            Review+
          </span>
          <div
            className="fix-icon text-dark"
            onClick={handleClick}
            style={{
              marginLeft: "auto",
            }}
          >
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>
        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          items={["home", "features", "howitworks", "integrations"]}
          currentClassName="active"
          offset={-90}
        >
          <li className="nav-item">
            <a
              href="home#home"
              className="nav-link"
              onClick={handleClick}
              style={{ color: "black" }}
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              href="home#features"
              className="nav-link"
              onClick={handleClick}
              style={{ color: "black" }}
            >
              Features
            </a>
          </li>
          <li className="nav-item">
            <a
              href="home#howitworks"
              className="nav-link"
              onClick={handleClick}
              style={{ color: "black" }}
            >
              How it Works
            </a>
          </li>
          <li className="nav-item">
            <a
              href="home#integration"
              className="nav-link"
              onClick={handleClick}
              style={{ color: "black" }}
            >
              Integrations
            </a>
          </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default HeaderLandingAppointment;
