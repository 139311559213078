import React from "react";
import BrandFour from "../brand/BrandFour";
import FormAppoint from "../form/FormAppoint";
import { Link } from "react-router-dom";
import Video from "../elements/Video";

const HeroBannerNine = ({ toggleModalOne, setEmail }) => {
  return (
    <div className="hero-banner-nine lg-container">
      <img
        src="images/shape/190.svg"
        alt="shape"
        className="shapes bg-shape"
        draggable="false"
      />
      <div
        className="illustration-video-container"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <Video />
      </div>
      <div className="container">
        {/* <div className="illustration-container">
          <img
            src="images/assets/screen_banner.png"
            alt="screen"
            draggable="false"
          />
        </div> */}
        {/* illustration-container */}

        <div className="row">
          <div className="col-lg-6">
            <h1 className="hero-heading">
              Boost{" "}
              <span>
                Your Reputation{" "}
                <img src="images/shape/189.svg" alt="shape" draggable="false" />
              </span>{" "}
              with Every Interaction.
            </h1>
            <p className="hero-sub-heading">
              Review+ helps your Business to collect, analyze, and act on
              customer feedback. Automating the entire process of Customer
              Management.
            </p>
            <FormAppoint toggleModalOne={toggleModalOne} setEmail={setEmail} />
            <p className="term-text">
              Are you a registered Merchant and use Review+?{" "}
              <Link to="/login">Sign in here</Link>
            </p>
          </div>
        </div>

        {/* End .row */}
      </div>
      {/* End .container */}

      <div className="partner-slider-two mt-130 md-mt-100">
        <div className="container">
          <p>
            Helping businesses enhance <span>customer satisfaction</span> and{" "}
            <span>drive growth</span> through actionable feedback. Trusted by
            Hundreds of Small Business Owners.
          </p>

          <div className="partnerSliderTwo">
            <BrandFour />
          </div>
        </div>
      </div>
      {/* /.partner-slider-two */}
    </div>
    //   /.hero-banner-nine
  );
};

export default HeroBannerNine;
