import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      WELCOME_TO: "WELCOME TO",
      CUSTOMER_REVIEW: "Customer Review",
      LOADING: "Loading",

      EXPERIENCE_TODAY:
        "Hey there! How was your visit today? We’d love to know!",
      TRACK_VISITS: "Track your visits",
      EARN_COUPONS: "Earn free coupons and items",
      GET_NOTIFIED: "Get notified about latest offers",
      GAIN_POINTS: "Gain more points to use at other stores",
      FEEDBACK_MANAGEMENT: "Feedback is shared directly to the management",
      JOIN_LOYALTY: "Join our loyalty program",
      PARTICIPATE_EVENTS: "Participate in special events",
      MARK_VISIT: "Share My Experience ✍🏻️",
      SCAN_QR_AGAIN: "Something went wrong. Please Scan QR code again!",
      HOLD_AND_SLIDE: "Hold and Slide!",
      RECEIVE_BIRTHDAY_COUPONS: "Receive exclusive birthday coupons",
      FEEDBACK_SHARED_WITH_OWNERS: "Feedback is shared directly to Owners",
      JOIN_LOYALTY_PROGRAM: "Join our loyalty program",
      PARTICIPATE_LOCAL_EVENTS: "Participate in local events",
      TRACK_YOUR_VISITS: "Track your visits",
      EARN_FREE_COUPONS: "Earn free coupons and items",
      GET_NOTIFIED_NEW_OFFERS: "Get notified about new offers",
      GAIN_MORE_PERKS: "Gain more perks to redeem at other stores as well",
      BUSINESS_NAME: "Business Name",
      TELL_US_ABOUT_YOU: "Tell us about you! 😍",
      ENTER_EMAIL: "Enter your Email*",
      NEXT: "Next",
      ENTER_FULL_NAME: "Enter your Full Name",
      ENTER_MOBILE_NUMBER: "Enter your Mobile Number",
      CHOOSE_GENDER: "Please choose your gender (optional)",
      MALE: "Male",
      FEMALE: "Female",
      OTHERS: "Others",

      DATE_OF_BIRTH: "Date of birth (optional)",
      CHOOSE_COUNTRY: "Choose your Country (optional)",
      FEEDBACK: "Feedback",
      SUBMIT: "Submit 👍🏻",
      FEEDBACK_ALREADY_SUBMITTED: "Feedback Already Submitted 🧐",
      FEEDBACK_ALREADY_SUBMITTED_MESSAGE:
        "You have already left feedback for today. Do you want to retry?",
      NO: "No",
      YES: "Yes",
      LEAVE_GOOGLE_REVIEW: "🥰 Care to Leave a Google Review?",
      LEAVE_GOOGLE_REVIEW_MESSAGE:
        "We appreciate your positive feedback! We'd really love if you can share your experience on Google.",
      COPY_NAVIGATE: "Copy & Navigate",
      LEAVE_REVIEW: "Leave Google Review",
      CUSTOMER_DETAILS: "Customer Details",
      INVALID_EMAIL:
        "Invalid email address. Please correct the email before submitting",
      ACKNOWLEDGE_CONSENT:
        "By clicking Submit, I acknowledge and consent to {{businessName}} collecting and using my provided email address and phone number to send me information about their products and services.",
      FEEDBACK_COPIED: "Your Feedback is copied! Redirecting to Review Page...",
      THANK_YOU: "Thank You",
      THANK_YOU_MESSAGE:
        "We are truly grateful for your feedback. Your insights help us continuously improve and provide you with the best possible experience. Please check your email for exclusive offers and coupons from {{businessName}}. Your support means the world to us 💛",

      // Subscription Strings
      subscriptions: "Subscriptions",
      choose_plan: "Choose your Plan",
      choose_plan_description:
        "Choose your suitable plan and get access to all features of Review+. Get future updates as well and stay ahead of your competition.",
      basic_plan: "BASIC PLAN",
      standard_plan: "STANDARD PLAN",
      premium_plan: "PREMIUM PLAN",
      basic_features: [
        "Unlimited Taps or Scans",
        "Basic customer management up to 250 customers",
        "Issue max 10 digital coupons per month",

        "Basic analytics and reporting",
        "Email support",
      ],
      standard_features: [
        "Manage up to 450 customers",
        "Schedule exclusive Birthday offers for your customers",
        "Google Business Automation with AI - Manual",
        "Issue max 100 digital coupons per month",
        "Run up to 3 automated promotions",
        "Includes 1 NFC-based stand",
        "Enhanced analytics and reporting",
        "Standard marketing tools - email",
        "Standard Email support",
        "Support Customer Kiosk Mode",
        "Priority access to new Features",
      ],
      premium_features: [
        "All features of the Standard Plan",
        "Complete Google Business Automation with AI",
        "Manage Unlimited customers",
        "Manage Unlimited promotions",
        "Issue Unlimited coupons",
        "Includes 2 NFC-based stands",
        "Detailed analytics and reporting",
        "Link and Share promotions across your Social Media accounts",
        "Import customer data from other POS systems",
        "Advanced marketing tools - sms & email",
        "Dedicated customer support via phone or email",
      ],
      secure_payment:
        "*Payments will be securely processed by Stripe/ACH through Zoho. You can cancel your subscription anytime, but please note that your membership will continue until the end of the current billing cycle. We offer a free trial period for new users to try out 'Review Plus', which enables you to collect and manage customer reviews easily. With Review Plus, you can improve your local reputation and increase your sales. Please note that applicable taxes may be added to your subscription price based on your location. If you have any questions about billing or our web app, please don't hesitate to contact our support team at info@tezooo.com",
      complete_subscription: "Complete Your Subscription",
      payment_details:
        "Please complete your subscription by filling out your payment details below.",
      close: "Close",
      UNSUBSCRIBE: "Unsubscribe",
      SUBSCRIBE: "Subscribe",
      CHOOSE_DOB: "Choose your date of birth to receive Exclusive Coupons!",
      FEEDBACK_HELPER:
        "Your Feedback will directly be seen by the management. You'll be redirected to Google Review Page.",
      // Confirmation Dialog Strings
      confirm_unsubscribe_title: "Confirm Unsubscription",
      confirm_unsubscribe_message:
        "Are you sure you want to cancel your plan? It will be stopped immediately.",
      cancel: "Cancel",
      confirm: "Confirm",
      WELCOME_BACK: "Welcome back,",
    },
  },
  fr: {
    translation: {
      WELCOME_TO: "BIENVENUE À",
      CUSTOMER_REVIEW: "Avis des clients",
      LOADING: "Chargement",
      EXPERIENCE_TODAY:
        "Salut! Comment avez-vous trouvé votre expérience avec nous aujourd'hui?",
      TRACK_VISITS: "Suivez vos visites",
      EARN_COUPONS: "Gagnez des coupons et des articles gratuits",
      GET_NOTIFIED: "Soyez informé des dernières offres",
      GAIN_POINTS: "Gagnez plus de points à utiliser dans d'autres magasins",
      FEEDBACK_MANAGEMENT:
        "Les commentaires sont partagés directement à la direction",
      JOIN_LOYALTY: "Rejoignez notre programme de fidélité",
      PARTICIPATE_EVENTS: "Participez à des événements spéciaux",
      MARK_VISIT: "Partager mon Expérience ✍🏻️",
      SCAN_QR_AGAIN:
        "Quelque chose a mal tourné. Veuillez scanner le code QR à nouveau!",
      HOLD_AND_SLIDE: "Maintenez et glissez!",
      RECEIVE_BIRTHDAY_COUPONS: "Recevez des coupons d'anniversaire exclusifs",
      FEEDBACK_SHARED_WITH_OWNERS:
        "Les commentaires sont partagés directement aux propriétaires",
      JOIN_LOYALTY_PROGRAM: "Rejoignez notre programme de fidélité",
      PARTICIPATE_LOCAL_EVENTS: "Participez à des événements locaux",
      TRACK_YOUR_VISITS: "Suivez vos visites",
      EARN_FREE_COUPONS: "Gagnez des coupons et des articles gratuits",
      GET_NOTIFIED_NEW_OFFERS: "Soyez informé des nouvelles offres",
      GAIN_MORE_PERKS:
        "Obtenez plus d'avantages à échanger dans d'autres magasins également",
      BUSINESS_NAME: "Nom de l'entreprise",
      TELL_US_ABOUT_YOU: "Parlez-nous de vous! 😍",
      ENTER_EMAIL: "Entrez votre e-mail*",
      NEXT: "Suivant",
      ENTER_FULL_NAME: "Entrez votre nom complet",
      ENTER_MOBILE_NUMBER: "Entrez votre numéro de mobile",
      CHOOSE_GENDER: "Veuillez choisir votre sexe (facultative)",
      MALE: "Homme",
      FEMALE: "Femme",
      OTHERS: "Autres",
      DATE_OF_BIRTH: "Date de naissance (facultative)",
      CHOOSE_DOB:
        "Choisissez votre date de naissance pour recevoir des coupons exclusifs !",
      CHOOSE_COUNTRY: "Choisissez votre pays (facultative)",
      FEEDBACK: "Retour d'information (facultative)",
      SUBMIT: "Soumettre 👍🏻",
      FEEDBACK_ALREADY_SUBMITTED: "Retour d'information déjà soumis",
      FEEDBACK_ALREADY_SUBMITTED_MESSAGE:
        "Vous avez déjà laissé un retour d'information pour aujourd'hui. Voulez-vous réessayer?",
      NO: "Non",
      YES: "Oui",
      LEAVE_GOOGLE_REVIEW: "🥰 Souhaitez-vous laisser un avis Google?",
      LEAVE_GOOGLE_REVIEW_MESSAGE:
        "Nous apprécions vos commentaires positifs ! Nous aimerions vraiment que vous puissiez partager votre expérience sur Google.",
      COPY_NAVIGATE: "Copier et naviguer",
      LEAVE_REVIEW: "Laisser un avis Google",
      INVALID_EMAIL:
        "Adresse e-mail invalide. Veuillez corriger l'e-mail avant de soumettre",
      ACKNOWLEDGE_CONSENT:
        "En cliquant sur Soumettre, je reconnais et consens à ce que {{businessName}} collecte et utilise mon adresse e-mail et mon numéro de téléphone fournis pour m'envoyer des informations sur leurs produits et services.",
      FEEDBACK_COPIED:
        "Vos commentaires sont copiés! Redirection vers la page d'avis...",
      THANK_YOU: "Merci",
      THANK_YOU_MESSAGE:
        "Nous vous sommes vraiment reconnaissants pour vos commentaires. Vos avis nous aident à nous améliorer continuellement et à vous offrir la meilleure expérience possible. Veuillez vérifier votre e-mail pour des offres exclusives et des coupons de la part de {{businessName}}. Votre soutien signifie beaucoup pour nous 💛",

      // Subscription Strings
      subscriptions: "Abonnements",
      choose_plan: "Choisissez votre forfait",
      choose_plan_description:
        "Choisissez votre forfait approprié et accédez à toutes les fonctionnalités de Review+. Recevez également les futures mises à jour et gardez une longueur d'avance sur vos concurrents.",
      basic_plan: "PLAN DE BASE",
      standard_plan: "PLAN STANDARD",
      premium_plan: "PLAN PREMIUM",
      basic_features: [
        "Taps ou Scans illimités",
        "Gestion de base des clients jusqu'à 250 clients",
        "Émettre max 10 coupons numériques par mois",
        "Comprend 1 support NFC",
        "Analyses et rapports de base",
        "Support par email",
      ],
      standard_features: [
        "Gérer jusqu'à 450 clients",
        "Programmer des offres d'anniversaire exclusives pour vos clients",
        "Émettre max 100 coupons numériques par mois",
        "Lancer jusqu'à 3 promotions automatisées",
        "Comprend 2 supports NFC personnalisés",
        "Analyses et rapports améliorés",
        "Outils de marketing standard - email",
        "Support par email standard",
        "Supporter le mode kiosque client",
        "Accès prioritaire aux nouvelles fonctionnalités",
      ],
      premium_features: [
        "Toutes les fonctionnalités du plan Standard",
        "Gérer des clients illimités",
        "Gérer des promotions illimitées",
        "Émettre des coupons illimités",
        "Comprend 3 supports NFC personnalisés",
        "Analyses et rapports détaillés",
        "Lier et partager des promotions sur vos comptes de réseaux sociaux",
        "Importer des données clients d'autres systèmes POS",
        "Outils de marketing avancés - sms & email",
        "Support client dédié par téléphone ou email",
      ],
      secure_payment:
        "*Les paiements seront traités en toute sécurité par Stripe/ACH via Zoho. Vous pouvez annuler votre abonnement à tout moment, mais veuillez noter que votre adhésion continuera jusqu'à la fin du cycle de facturation en cours. Nous offrons une période d'essai gratuite pour les nouveaux utilisateurs afin de tester 'Review Plus', qui vous permet de collecter et de gérer facilement les avis clients. Avec Review Plus, vous pouvez améliorer votre réputation locale et augmenter vos ventes. Veuillez noter que des taxes applicables peuvent être ajoutées au prix de votre abonnement en fonction de votre emplacement. Si vous avez des questions concernant la facturation ou notre application web, n'hésitez pas à contacter notre équipe de support à info@tezooo.com",
      complete_subscription: "Complétez Votre Abonnement",
      payment_details:
        "Veuillez compléter votre abonnement en remplissant vos coordonnées de paiement ci-dessous.",
      close: "Fermer",
      UNSUBSCRIBE: "UnSubscribe",
      SUBSCRIBE: "Subscribe",

      // Confirmation Dialog Strings
      confirm_unsubscribe_title: "Confirmer l'annulation",
      confirm_unsubscribe_message:
        "Êtes-vous sûr de vouloir annuler votre forfait? Il sera arrêté immédiatement.",
      cancel: "Annuler",
      confirm: "Confirmer",
      CUSTOMER_DETAILS: "Détails du client",
      FEEDBACK_HELPER:
        "Vos commentaires seront directement vus par la direction. Vous serez redirigé vers la page d'avis Google.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
