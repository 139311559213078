import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import BmAnalyticsChart from "./BmAnalyticsChart";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#EDF3FE",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    overflow: "visible",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  icon: {
    marginTop: theme.spacing(2),
    fontSize: "3rem",
  },
  mediumText: {
    fontWeight: "bold",
  },
  largeText: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "2rem",
    color: "black",
    "& h5": {
      fontSize: "2rem",
    },
  },
  chartContainer: {
    height: 200,
    marginTop: theme.spacing(2),
  },
}));

function BmGoogleReviewCard({
  icon,
  mediumText,
  initialReviews,
  currentReviews,
  plan,
}) {
  const classes = useStyles();
  const [displayedNumber, setDisplayedNumber] = useState(0);

  // Updated logic to handle cases where initialReviews is 0
  const reviewIncrease = initialReviews
    ? ((currentReviews - initialReviews) / initialReviews) * 100
    : currentReviews > 0
    ? "N/A"
    : 0;

  useEffect(() => {
    let currentNumber = 0;
    const increment = currentReviews / 20;
    const animationInterval = setInterval(() => {
      if (currentNumber >= currentReviews) {
        clearInterval(animationInterval);
        setDisplayedNumber(currentReviews);
      } else {
        currentNumber += increment;
        setDisplayedNumber(Math.round(currentNumber));
      }
    }, 50);

    return () => clearInterval(animationInterval);
  }, [currentReviews]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.icon}>{icon}</Typography>
            <Typography variant="subtitle1" className={classes.mediumText}>
              {mediumText}
            </Typography>
            <Typography variant="h5" className={classes.largeText}>
              {displayedNumber}
            </Typography>
            {plan !== "BASIC_01" && (
              <Typography variant="body2" color="textSecondary">
                {`Increase: ${
                  !isFinite(reviewIncrease)
                    ? "Not Available"
                    : reviewIncrease.toFixed(2) + "%"
                }`}
              </Typography>
            )}
          </Grid>
          {plan !== "BASIC_01" && (
            <Grid item xs={6} className={classes.chartContainer}>
              <BmAnalyticsChart
                initial={initialReviews}
                current={currentReviews}
                label="Reviews"
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default BmGoogleReviewCard;
