import React, { useEffect, useState, useContext } from "react";
import Page from "../../../utils/Page";
import {
  Typography,
  Grid,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SubscriptionCard from "../../../components/merchant/SubscriptionCard";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MerchantContext from "../../../config/MerchantContext";
import { unsubscribeFromZoho } from "../../../config/cloudApis";

const useStyles = makeStyles((theme) => ({
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: "100%",
    padding: theme.spacing(3),
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  topMargin: {
    marginBottom: theme.spacing(3),
  },
  footerText: {
    color: "#333333",
    fontSize: 12,
    userSelect: "none",
  },
  container: {
    background: "rgba(255, 255, 255, 0.2)", // Optional: Add transparency to see the gradient
    borderRadius: "15px",
    padding: theme.spacing(3),
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    userSelect: "none",
  },
  iframe: {
    width: "100%",
    height: "600px", // Adjust the height as needed
    border: "none",
    paddingBottom: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
    userSelect: "none",
  },
  dialogFooter: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    textAlign: "right",
    userSelect: "none",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  title: {
    userSelect: "none",
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: theme.palette.text.primary, // Usually black or dark grey
    marginBottom: theme.spacing(1), // Add some spacing below the title
  },
  planTitle: {
    userSelect: "none",
    fontSize: "1.4rem",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  titleDescription: {
    userSelect: "none",
    color: theme.palette.text.secondary, // Usually a lighter grey
    fontSize: "1rem",
  },
}));

const SubscriptionView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentMerchant, isSubscribed, plan } = useContext(MerchantContext);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [planToUnsubscribe, setPlanToUnsubscribe] = useState(null);

  useEffect(() => {
    setCurrentPlan(plan);
    if (!currentMerchant?.merchantID) {
      navigate("/app/dashboard");
      return;
    }
    setLoading(false);
  }, [currentMerchant, plan, isSubscribed, navigate]);

  const basicFeatures = t("basic_features", { returnObjects: true });
  const standardFeatures = t("standard_features", { returnObjects: true });
  const premiumFeatures = t("premium_features", { returnObjects: true });

  const handleSubscribe = (planUrl) => {
    const merchantId = currentMerchant?.merchantID;
    let subscribeUrl = "";
    switch (planUrl) {
      case "Basic":
        subscribeUrl = `https://subscriptions.zoho.com/subscribe/8d05a5e1d74368dea438b192d75b6e66a7726ba08b65d6ccad482bf477cf719e/FREE_01?cf_merchant_id=${merchantId}`;
        break;
      case "Standard":
        subscribeUrl = `https://subscriptions.zoho.com/subscribe/8d05a5e1d74368dea438b192d75b6e66a7726ba08b65d6ccad482bf477cf719e/STAN_01?cf_merchant_id=${merchantId}`;
        break;
      case "Premium":
        subscribeUrl = `https://subscriptions.zoho.com/subscribe/8d05a5e1d74368dea438b192d75b6e66a7726ba08b65d6ccad482bf477cf719e/PREMIUM_01?cf_merchant_id=${merchantId}`;
        break;
      default:
        subscribeUrl = "";
    }
    setIframeSrc(subscribeUrl);
    setOpenDialog(true);
  };

  const handleConfirmUnsubscribe = (plan) => {
    setPlanToUnsubscribe(plan);
    setConfirmDialogOpen(true);
  };

  const handleUnsubscribe = async () => {
    setConfirmDialogOpen(false);
    try {
      console.log(currentMerchant?.merchantID);
      await unsubscribeFromZoho(currentMerchant?.merchantID);
      alert(`Successfully unsubscribed from ${planToUnsubscribe} plan.`);
      // Update the local state to reflect the subscription status change
      setCurrentPlan(null);

      setTimeout(() => {
        navigate("/app/dashboard", {
          replace: true,
        });
      }, 3000);
    } catch (error) {
      console.error("Failed to unsubscribe:", error);
      alert("Failed to unsubscribe. Please try again.");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIframeSrc("");
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Page title={t("subscriptions")}>
      <Container className={classes.root}>
        <Box className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            {t("subscriptions")}
          </Typography>
          <Typography variant="body1" className={classes.titleDescription}>
            {t("choose_plan_description")}
          </Typography>
        </Box>
        <Typography align="center" className={classes.planTitle}>
          {t("choose_plan")}
        </Typography>

        <Grid
          className={classes.topMargin}
          container
          direction="row"
          spacing={4}
        >
          <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
            <SubscriptionCard
              titleText={t("basic_plan")}
              priceText="$29.99"
              features={basicFeatures}
              isPopular={false}
              isHighlighted={false}
              onClick={() =>
                isSubscribed && currentPlan === "BASIC_01"
                  ? handleConfirmUnsubscribe("Basic")
                  : handleSubscribe("Basic")
              }
              buttonText={
                isSubscribed && currentPlan === "BASIC_01"
                  ? t("UNSUBSCRIBE")
                  : t("SUBSCRIBE")
              }
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
            <SubscriptionCard
              titleText={t("standard_plan")}
              priceText="$69.99"
              features={standardFeatures}
              isPopular={true}
              isHighlighted={true}
              onClick={() =>
                isSubscribed && currentPlan === "STAN_01"
                  ? handleConfirmUnsubscribe("Standard")
                  : handleSubscribe("Standard")
              }
              buttonText={
                isSubscribed && currentPlan === "STAN_01"
                  ? t("UNSUBSCRIBE")
                  : t("SUBSCRIBE")
              }
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
            <SubscriptionCard
              titleText={t("premium_plan")}
              priceText="$109.99"
              features={premiumFeatures}
              isPopular={false}
              isHighlighted={false}
              onClick={() =>
                isSubscribed && currentPlan === "PREMIUM_01"
                  ? handleConfirmUnsubscribe("Premium")
                  : handleSubscribe("Premium")
              }
              buttonText={
                isSubscribed && currentPlan === "PREMIUM_01"
                  ? t("UNSUBSCRIBE")
                  : t("SUBSCRIBE")
              }
            />
          </Grid>
        </Grid>

        <Box mt="auto" maxWidth="xl">
          <Typography
            variant="caption"
            className={`${classes.topMargin} ${classes.footerText}`}
          >
            {t("secure_payment")}
          </Typography>
        </Box>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <Box className={classes.dialogTitle}>
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {t("complete_subscription")}
            </Typography>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="body1" gutterBottom>
              {t("payment_details")}
            </Typography>
            <iframe
              src={iframeSrc}
              className={classes.iframe}
              title="Subscription"
            ></iframe>
          </DialogContent>
          <Box className={classes.dialogFooter}>
            <Button onClick={handleCloseDialog} color="primary">
              {t("close")}
            </Button>
          </Box>
        </Dialog>

        <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
          <DialogTitle>
            <Typography
              variant="h6"
              component="div"
              style={{ fontWeight: "bold", fontSize: "1.5rem" }} // Increased font size
            >
              {t("confirm_unsubscribe_title")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontSize: "1.2rem" }}>
              {t("confirm_unsubscribe_message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCloseConfirmDialog}
              color="secondary"
              style={{ fontSize: "1.2rem" }} // Increased font size
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleUnsubscribe}
              color="primary"
              autoFocus
              style={{ fontSize: "1.2rem" }} // Increased font size
            >
              {t("confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default SubscriptionView;
