import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import { Grid, Divider, Box } from "@mui/material";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    backdropFilter: "blur(10px)",
    background: "rgba(255, 255, 255, 0.15)",
    border: `2px solid transparent`,
    position: "relative",
    overflow: "hidden",
    marginTop: theme.spacing(3), // Added margin to ensure space for the label
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  highlighted: {
    borderColor: theme.palette.primary.main,
  },
  priceText: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "2rem",
    color: "#333333",
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: "bold", // Make all plan titles bold
    color: (props) =>
      props.isHighlighted
        ? `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
        : "#333333",
    background: (props) =>
      props.isHighlighted ? `${theme.palette.secondary.main}` : "none",
    WebkitBackgroundClip: (props) => (props.isHighlighted ? "text" : "none"),
    WebkitTextFillColor: (props) =>
      props.isHighlighted ? "transparent" : "inherit",
  },
  button: {
    marginTop: theme.spacing(2),
    color: "#ffffff",
  },
  unsubscribeButton: {
    color: "#000",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  feature: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "#333333",
  },
  featureIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  popularLabel: {
    position: "absolute",
    top: 0,
    left: theme.spacing(2),
    transform: "translateY(-50%)", // Move label up by half its height
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    color: "#ffffff",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    zIndex: 1,
  },
  glassCardContent: {
    padding: theme.spacing(3),
  },
  divider: {
    backgroundColor: theme.palette.grey[400],
    margin: theme.spacing(2, 0),
  },
}));

function SubscriptionCard(props) {
  const {
    titleText,
    priceText,
    features,
    onClick,
    isPopular,
    isHighlighted,
    buttonText,
  } = props;
  const classes = useStyles({ isHighlighted });

  const { t } = useTranslation();
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Box position="relative">
        {isPopular && <div className={classes.popularLabel}>Popular</div>}
        <Card
          className={`${classes.root} ${
            isHighlighted ? classes.highlighted : ""
          }`}
        >
          <CardContent className={classes.glassCardContent}>
            <Grid item>
              <Typography align="center" variant="h4" className={classes.title}>
                {titleText}
              </Typography>
            </Grid>
            <Typography
              align="center"
              variant="h3"
              className={classes.priceText}
            >
              {priceText} /month
            </Typography>
            <Divider className={classes.divider} />
            <Typography align="center" variant="h6" className={classes.title}>
              This Plan Includes:
            </Typography>
            {features.map((feature, index) => (
              <div key={index} className={classes.feature}>
                <CheckCircleIcon className={classes.featureIcon} />
                <Typography variant="body1">{feature}</Typography>
              </div>
            ))}

            <Button
              color="secondary"
              fullWidth
              name="submit"
              value="submit"
              variant="contained"
              onClick={handleOnClick}
              className={`${classes.button} ${
                buttonText === t("UNSUBSCRIBE") ? classes.unsubscribeButton : ""
              }`}
            >
              {buttonText}
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default SubscriptionCard;
