import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import Lottie from "lottie-react";
import thankyouAnim from "../../utils/animations/thankyou.json";
import logo from "../../icons/logo.png";
import Footer from "../../components/Widget/Footer";
import { Facebook, Instagram } from "react-feather";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 300,
  },
  header: {
    margin: 0,
    textTransform: "uppercase",
    fontSize: 80,
    fontWeight: 300,
    lineHeight: 1.1,
    hyphens: "auto",
  },
  body: {
    paddingTop: "40px",
    minWidth: "200px",
    fontSize: 20,
    lineHeight: 1.2,
  },
  marginTp: {
    marginTop: theme.spacing(2),
  },
  subBody: {
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  footer: {
    paddingTop: "50px",
    fontSize: 12,
  },
}));

const CloverThankYou = () => {
  const classes = useStyles();
  const { state } = useLocation();

  //Lottie options
  const defaultOptions = {
    // loop: 5,
    autoplay: true,
    animationData: thankyouAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {}, []);

  return (
    <Page
      className={classes.root}
      title="Thank you"
      style={{ background: "radial-gradient(#ffffff, #FAC94E)" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {
          <div>
            {state.logo !== undefined &&
            state.logo !== "" &&
            state.logo !== null ? (
              <Box
                component="img"
                height="130px"
                alt="Business Logo"
                src={state.logo}
              />
            ) : (
              <Box
                className={classes.marginTp}
                component="img"
                sx={{
                  maxHeight: { xs: 110 },
                  maxWidth: { xs: 110 },
                }}
                alt="Business Logo"
                src={logo}
              />
            )}
            <br />
          </div>
        }
        <Container maxWidth="md">
          <Box alignItems="center" justifyContent="center">
            <Lottie options={defaultOptions} height={320} width={320} />
          </Box>

          <Typography
            style={{ color: "black" }}
            variant="subtitle1"
            gutterBottom
            align="center"
          >
            Click on Completed Setup button on Clover. You can now close this
            page.
          </Typography>
        </Container>
      </Box>
      {/* <Footer /> */}
    </Page>
  );
};

export default CloverThankYou;
