import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Container,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowForward from "@mui/icons-material/ArrowForward";
import GoogleIcon from "@mui/icons-material/Google";
import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import firebase from "../../../config/firebase";
import Page from "../../../utils/Page";
import logo from "../../../icons/logo.png";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: "100%",
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  title: {
    color: "#ffffff",
    fontSize: 18,
    userSelect: "none",
  },
  subtitle: {
    fontSize: 16,
    userSelect: "none",
    textAlign: "center",
  },
  errorText: {
    color: theme.palette.error.main,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  marginTp: {
    marginTop: theme.spacing(2),
  },
  iconSmall: {
    fontSize: 20,
  },
  typography: {
    padding: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    color: "#fff",
    fontSize: "0.9rem",
    opacity: 0.8,
    width: "100%",
    userSelect: "none",
  },
  seperator: {
    marginTop: theme.spacing(2),
    fontSize: 15,
    textAlign: "center",
  },
  glowingIcon: {
    margin: theme.spacing(1, 0),
    animation: "$glow 2.5s infinite",
    borderRadius: "50%",
    width: "110px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.7)",
    position: "relative",
    userSelect: "none",
    background: "#fff",
  },
  "@keyframes glow": {
    "0%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
    "50%": { boxShadow: "0 0 20px rgba(255, 255, 255, 1)" },
    "100%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
  },
  btnArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& button": {
      margin: theme.spacing(1, 0),
    },
  },
}));

const EmployeeCompleteRegistration = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [fieldValues, setFieldValues] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [isDataFilled, setIsDataFilled] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const merchantID = queryParams.get("merchantID");

    if (!email || !merchantID) {
      navigate("/not-found");
      return;
    }

    setFieldValues((prevState) => ({
      ...prevState,
      email: email,
    }));
  }, [location]);

  useEffect(() => {
    if (
      fieldValues.email &&
      fieldValues.password.length >= 6 &&
      fieldValues.confirmpassword.length >= 6 &&
      fieldValues.password === fieldValues.confirmpassword
    ) {
      setIsDataFilled(true);
    } else {
      setIsDataFilled(false);
    }
  }, [fieldValues]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmition = async (e) => {
    e.preventDefault();
    if (fieldValues.password !== fieldValues.confirmpassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      const queryParams = new URLSearchParams(location.search);
      const merchantID = queryParams.get("merchantID");

      const employeeExists = await firebase.getSingleEmployee(
        merchantID,
        fieldValues.email
      );

      if (!employeeExists) {
        alert(
          "You are not authorized to register as an employee for this merchant."
        );
        return;
      }

      const userDoc = await firebase.getUserByEmail(fieldValues.email);
      if (userDoc && userDoc.isEmailVerified) {
        setIsError(true);
      } else {
        await firebase.register(fieldValues.email, fieldValues.password);
        await firebase.updateUserRoles(
          fieldValues.email,
          merchantID,
          "employee"
        );
        navigateToDashboard();
      }
    } catch (error) {
      console.error("Error during registration: ", error);
      alert(error.message);
    }
  };

  const registerWithGoogle = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const merchantID = queryParams.get("merchantID");

      const employeeExists = await firebase.getSingleEmployee(
        merchantID,
        fieldValues.email
      );

      if (!employeeExists) {
        alert(
          "You are not authorized to register as an employee for this merchant."
        );
        return;
      }

      const userDoc = await firebase.getUserByEmail(fieldValues.email);
      if (userDoc && userDoc.isEmailVerified) {
        setIsError(true);
      } else {
        await firebase.registerWithGoogle();
        await firebase.updateUserRoles(
          fieldValues.email,
          merchantID,
          "employee"
        );
        navigateToDashboard();
      }
    } catch (error) {
      console.error("Error during Google registration: ", error);
      alert(error.message);
    }
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <Page className={classes.root} title="Review+ Employee Registration">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="md" align="center">
          <div className={classes.glowingIcon}>
            <img
              src={logo}
              alt="Business Logo"
              style={{
                borderRadius: "50%",
                width: "110px",
                height: "110px",
                objectFit: "cover",
              }}
            />
          </div>
          <Typography
            color="secondary"
            gutterBottom
            variant="h1"
            align="center"
          >
            <b>REVIEW+</b>
          </Typography>
          <Typography variant="h2" color="black" gutterBottom align="center">
            <strong>Complete Employee Setup</strong>
          </Typography>
          <Typography
            variant="caption"
            className={classes.subtitle}
            gutterBottom
            align="center"
          >
            Finish the setup to get access to Review+ Portal. As an Employee,
            you can manage your assigned tasks and access business data.
          </Typography>

          <Dialog
            open={isError}
            onClose={() => setIsError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The email provided is already registered and verified. Please
                login or use a different email.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsError(false)}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Box mt={2}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={fieldValues.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={fieldValues.password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmpassword"
              label="Confirm Password"
              type="password"
              id="confirmpassword"
              autoComplete="current-password"
              value={fieldValues.confirmpassword}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mt={2} className={classes.btnArea}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              disabled={!isDataFilled}
              onClick={handleSubmition}
            >
              Complete Setup
              <ArrowForward
                className={classNames(classes.rightIcon, classes.iconSmall)}
              />
            </Button>
            <Typography className={classes.seperator}>OR</Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={registerWithGoogle}
              fullWidth
            >
              Complete Setup with Google
              <GoogleIcon
                sx={{
                  color: "#F2B50F",
                  display: "inline-flex",
                  alignItems: "center",
                  ml: 1,
                }}
                className={classNames(classes.rightIcon, classes.iconSmall)}
              />
            </Button>
          </Box>

          <Box className={classes.footer}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              className={classes.footer}
            >
              &copy; {new Date().getFullYear()} Powered by Review+ All rights
              reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default EmployeeCompleteRegistration;
