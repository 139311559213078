import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import BmCaChart from "./BmCaChart";
import firebase from "../../config/firebase";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    minHeight: 300, // Set a minimum height
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  number: {
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: 1,
  },
  formControl: {
    minWidth: 120,
  },
  select: {
    borderRadius: 25,
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:focus": {
      borderRadius: 25,
      backgroundColor: "white",
    },
    maxWidth: "150px",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },

  body: {
    fontSize: "0.9rem",
  },
}));

const BmCustomerAnalysis = ({ currentMerchant }) => {
  const classes = useStyles();
  const months = [6, 9, 12];
  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);

  const getAllCustomers = (merchantID) => {
    setLoading(true);
    firebase
      .getAllMerchantCustomers(merchantID)
      .then((data) => {
        setCustomerData(data);
      })
      .catch((error) => {
        alert(`Failed to fetch customers: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentMerchant && currentMerchant.merchantID) {
      getAllCustomers(currentMerchant.merchantID);
    }
  }, [currentMerchant]);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.title} gutterBottom>
            Customer Analysis
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className={classes.select}
            >
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {`${month} Months`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          {loading ? (
            <Box className={classes.loadingBox}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              <Typography variant="h5" className={classes.number}>
                {customerData.length}
              </Typography>
              <Box>
                <BmCaChart
                  selectedMonth={selectedMonth}
                  customerData={customerData}
                />
                <Typography
                  variant="body2"
                  className={classes.body}
                  gutterBottom
                >
                  This Data contains information regarding total customers
                  gained every month.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default BmCustomerAnalysis;
