import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FancyFeatureTewentyFour from "../../components/home/features/FancyFeatureTewentyFour";
import FancyFeatureTewentyTwo from "../../components/home/features/FancyFeatureTewentyTwo";
import HeaderLandingAppointment from "../../components/home/header/landing/HeaderLandingAppointment";
import HeroBannerNine from "../../components/home/hero-banner/HeroBannerNine";
// import TestimonialSeven from "../../components/home/testimonial/TestimonialSeven";
import SocialTwo from "../../components/home/social/SocialTwo";
import FooterEight from "../../components/home/footer/FooterEight";
import CopyRightFour from "../../components/home/footer/CopyRightFour";
import Modal from "react-modal";
import HeaderPopupForm from "../../components/home/form/HeaderPopupForm";
import { ToastContainer, toast, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendDemoBookingEmail } from "../../config/cloudApis";

import { CircularProgress } from "@mui/material";

Modal.setAppElement("#root");

const HomeScreenView = () => {
  // For header select menu
  const [email, setEmail] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModalOne = () => {
    setIsOpen(!isOpen);
  };

  const handleFormSuccess = async (data) => {
    // Print the data
    console.log("Submitted Data:", data);

    // Check if name, email, and message exist
    if (!data.name || !data.email || !data.sendMessage) {
      console.error("Name, email, and message are required.");
      toast.error("Name, email, and message are required.", {
        position: "bottom-center",
      });
      return;
    }

    try {
      setLoading(true); // Show progress bar

      // Call the API to send the demo booking email
      await sendDemoBookingEmail(data.name, data.email, data.sendMessage);

      // Show success toast message
      toast.success(
        "Your information has been sent. Someone will reach out soon.",
        {
          position: "bottom-center",
        }
      );

      // Close the modal
      setIsOpen(false);

      // Clear the email state
      setEmail("");
    } catch (error) {
      console.error("Failed to send demo booking email:", error);
      toast.error("Failed to send your information. Please try again later.", {
        position: "bottom-center",
      });
    } finally {
      setLoading(false); // Hide progress bar
    }
  };

  return (
    <>
      <div className="main-page-wrapper p0 font-gordita">
        <Helmet>
          <title>Review+ | Elevate Your Business with Customer Feedback</title>
          <meta property="og:site_name" content="Review+" />
          <meta property="og:url" content="https://reviewplusapp.com" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Review+ | Elevate Your Business with Customer Feedback | Increase google reviews"
          />
          <meta
            name="keywords"
            content="customer feedback, feedback management, business growth, customer satisfaction, small business, SaaS, customer insights, review management, customer reviews, improve business"
          />
          <meta
            name="description"
            content="Review+ is a powerful customer feedback management software designed to help small business owners collect, analyze, and act on customer feedback to drive business growth and improve customer satisfaction."
          />
          <meta
            property="og:description"
            content="Review+ is a powerful customer feedback management software designed to help small business owners collect, analyze, and act on customer feedback to drive business growth and improve customer satisfaction."
          />
        </Helmet>
        {/* End Page SEO Content */}

        <HeaderLandingAppointment
          toggleModalOne={toggleModalOne}
          setEmail={setEmail}
        />
        {/* End Header Landing Appointment Scheduling */}

        <div id="home">
          <HeroBannerNine toggleModalOne={toggleModalOne} setEmail={setEmail} />
        </div>
        {/* End Hero Banner Nine */}

        {/* =============================================
            Fancy Feature Twenty Two
        ==============================================  */}
        <div className="fancy-feature-twentyTwo mt-100 md-mt-100">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-7 col-md-8 m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="title-style-eight text-center mb-40 md-mb-10">
                  <h2>
                    One Platform. For any{" "}
                    <span>
                      Business <img src="images/shape/191.svg" alt="shape" />
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <FancyFeatureTewentyTwo />
            </div>
          </div>
        </div>
        {/* /.fancy-feature-twentyTwo */}

        {/* =============================================
           REVIEW+ FEATURES SECTION START
        ==============================================  */}
        <div
          className="fancy-feature-twentyThree pt-100 md-pt-100"
          id="features"
        >
          <div className="container">
            <div
              className="title-style-nine text-center pb-50 md-pb-50"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h6>Features</h6>
              <h2>
                Review+ is all about Collecting Valuable Feedback, Increasing
                Google Reviews, &{" "}
                <span>
                  Growing Your Business{" "}
                  <img src="images/shape/192.svg" alt="shape" />
                </span>
              </h2>
              <p>
                Review+ helps you gather instant feedback, improve your Google
                reviews and ratings, and communicate directly with your
                customers based on their experiences. Unlock actionable
                insights, enhance customer engagement, and grow your business
                effortlessly.
              </p>
            </div>
            {/* End title */}

            <div className="block-style-twentyThree">
              <div className="row align-items-center">
                {
                  //IMAGE
                }
                <div
                  className="col-lg-6 order-lg-last ms-auto "
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="screen-container ms-auto">
                    <div
                      className="oval-shape"
                      style={{ background: "#69FF9C" }}
                    ></div>
                    <div
                      className="oval-shape"
                      style={{ background: "#FFF170" }}
                    ></div>
                    <img
                      //src="images/assets/screen_18.png"
                      style={{
                        borderRadius: "15px", // Add rounded corners
                      }}
                      src="images/assets/4.jpg"
                      alt="Feature screen"
                      className="shapes shape-one"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                {
                  //TEXT
                }
                <div
                  className="col-lg-5 order-lg-first mb-50 md-mb-50"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Effortless Feedback Collection</h6>
                    <h3 className="title">
                      Gather Customer Feedback Seamlessly
                    </h3>
                    <p>
                      Collect unlimited customer reviews through easy taps or
                      scans, making it simple to gather real-time feedback.
                    </p>
                  </div>
                  {/*  /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}

            <div className="block-style-twentyThree">
              <div className="row">
                <div className="col-lg-6 ">
                  <div
                    className="screen-container me-auto"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div
                      className="oval-shape"
                      style={{ background: "#FFDE69" }}
                    ></div>
                    <div
                      className="oval-shape"
                      style={{ background: "#FF77D9" }}
                    ></div>
                    <img
                      //src="images/assets/screen_19.png"
                      style={{
                        borderRadius: "15px", // Add rounded corners
                      }}
                      src="images/assets/2.jpg"
                      alt="Feature screen"
                      className="shapes  shape-three"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Actionable Insights</h6>
                    <h3 className="title">
                      Turn Feedback into Actionable Insights
                    </h3>
                    <p>
                      Analyze the collected feedback to gain valuable insights
                      and make informed decisions to improve your business.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}

            <div className="block-style-twentyThree">
              <div className="row">
                <div
                  className="col-lg-6 order-lg-last ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="screen-container ms-auto">
                    <div
                      className="oval-shape"
                      style={{ background: "#00F0FF" }}
                    ></div>
                    <div
                      className="oval-shape"
                      style={{ background: "#FC6BFF" }}
                    ></div>
                    <img
                      //src="images/assets/screen_20.png"
                      style={{
                        borderRadius: "15px", // Add rounded corners
                      }}
                      src="images/assets/5.jpg"
                      alt="Feature screen"
                      className="shapes shape-three"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Customer Engagement</h6>
                    <h3 className="title">
                      Enhance Engagement with Automated Promotions
                    </h3>
                    <p>
                      Boost customer engagement with digital coupons and
                      automated promotions, encouraging repeat business and
                      loyalty.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}
          </div>
        </div>

        {/* 	=============================================
            REVIEW+ FEATURES SECTION END
        ==============================================  */}

        {/* =============================================
      UNDERSTANDING THE PROCESS START
  ==============================================  */}
        <div className="fancy-feature-twentyThree" id="howitworks">
          <div className="container">
            <div
              className="title-style-nine text-center  mb-100 md-mb-100"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h6>Feedback Process</h6>
              <h2>
                <span>
                  UNDERSTAND THE FEEDBACK PROCESS{" "}
                  <img src="images/shape/192.svg" alt="shape" />
                </span>
              </h2>
              <p>
                Your customers can easily tap or scan the QR code displayed on a
                custom NFC-based stand. The effectiveness of your campaigns will
                depend on how well you advertise them.
              </p>
            </div>
            {/* End title */}

            <div className="block-style-twentyThree">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 order-lg-last ms-auto  mb-100 md-mb-100"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="screen-container ms-auto">
                    <div
                      className="oval-shape"
                      style={{ background: "#69FF9C" }}
                    ></div>
                    <div
                      className="oval-shape"
                      style={{ background: "#FFF170" }}
                    ></div>
                    <img
                      style={{
                        borderRadius: "15px", // Add rounded corners
                      }}
                      src="images/assets/step1-scan.jpg"
                      alt="Customer Review Screen"
                      className="shapes shape-one"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Tap/Scan the QR Code</h6>
                    <h3 className="title">Step 1</h3>
                    <p>
                      Customers can tap or scan the QR code thats shown on the
                      stand that is displayed over the counter or on the tables.
                      This leads to a smooth transition to collect customer
                      feedback. In Kiosk mode customer can directly leave review
                      by directly interacting with the tablet.
                    </p>
                  </div>
                  {/*  /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}

            <div className="block-style-twentyThree">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="screen-container me-auto mb-50 md-mb-50"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div
                      className="oval-shape"
                      style={{ background: "#FFDE69" }}
                    ></div>
                    <div
                      className="oval-shape"
                      style={{ background: "#FF77D9" }}
                    ></div>
                    <img
                      style={{
                        borderRadius: "15px", // Add rounded corners
                      }}
                      src="images/assets/screen_banner.png"
                      alt="Feature screen"
                      className="shapes  shape-three"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 ms-auto mb-100 md-mb-100"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Select Experience</h6>
                    <h3 className="title">Step 2</h3>
                    <p>
                      Customers can now select their experience from the
                      available options, providing your Business with valuable
                      feedback. If they have a negative experience, Business
                      Owner will directly receive Email and SMS to the mobile.
                      Business Owner can directly respond to the customers based
                      on this and offer them a coupon or even resolve the issues
                      they've had.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}

            <div className="block-style-twentyThree">
              <div className="row">
                <div
                  className="col-lg-6 order-lg-last ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="screen-container ms-auto">
                    <div
                      className="oval-shape"
                      style={{ background: "#00F0FF" }}
                    ></div>
                    <div
                      className="oval-shape"
                      style={{ background: "#FC6BFF" }}
                    ></div>
                    <img
                      style={{
                        borderRadius: "15px", // Add rounded corners
                      }}
                      src="images/assets/step6.jpg"
                      alt="notification image"
                      className="shapes shape-three"
                    />
                  </div>
                  {/* /.screen-container */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div
                    className="text-wrapper
                  ."
                  >
                    <h6>Collect Customer Details</h6>
                    <h3 className="title">Step 3</h3>
                    <p>
                      Based on their experience, collect required customer
                      details. Automated emails will be sent to Business owner
                      and customer. This Data will be useful to set birthday
                      offers for your customers, create targetted marketing
                      campaigns and much more!
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-twentyThree */}
          </div>
        </div>
        {/* =============================================
      UNDERSTANDING THE PROCESS END
  ==============================================  */}

        <div className="fancy-feature-twentyFour pt-140 md-pt-60" id="service">
          <div id="businesstypes" className="container">
            <div className="title-style-nine text-center">
              <h6>Businesses Industries Include</h6>
              <h2>
                Review+ Serves Any{" "}
                <span>
                  Business Segment{" "}
                  <img src="images/shape/194.svg" alt="shape" />
                </span>
              </h2>
            </div>
          </div>
          <div className="bg-wrapper mt-100 md-mt-80">
            <a
              href="#businesstypes"
              className="click-scroll-button scroll-target d-flex justify-content-center"
            >
              <img src="images/shape/200.svg" alt="shape" />
            </a>
            <div className="container">
              <FancyFeatureTewentyFour />
            </div>
            <img
              src="images/shape/195.svg"
              alt="shape"
              className="shapes shape-one"
            />
            <img
              src="images/shape/196.svg"
              alt="shape"
              className="shapes shape-two"
            />
            <img
              src="images/shape/197.svg"
              alt="shape"
              className="shapes shape-three"
            />
            <img
              src="images/shape/198.svg"
              alt="shape"
              className="shapes shape-four"
            />
            <img
              src="images/shape/199.svg"
              alt="shape"
              className="shapes shape-five"
            />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* End .fancy feature twenty four */}

        {/* =====================================================
            Client Feedback Slider Six
        ===================================================== */}
        {/** 
        <div
          className="client-feedback-slider-six pt-170 md-pt-120"
          id="feedback"
        >
          <div className="inner-container">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-7 m-auto"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <div className="title-style-nine text-center">
                    <h6>Testimonials</h6>
                    <h2>
                      What Our{" "}
                      <span>
                        Clients <img src="images/shape/201.svg" alt="shape" />
                      </span>{" "}
                      Think About Us
                    </h2>
                  </div>
                </div>
              </div>
            </div>
        
            <div className="clientSliderSix style-two">
              <TestimonialSeven />
            </div>
          </div>         
        </div>

        */}
        {/* /.client-feedback-slider-six */}

        {/*     =====================================================
        Partner Section One
    ===================================================== */}
        <div className="partner-section-one mt-170 md-mt-90 pb-120 md-pb-80">
          <div className="container">
            <div
              id="integrations"
              className="title-style-nine text-center mb-100"
            >
              <h6>Integrations</h6>
              <h2>
                Integrate with{" "}
                <span>
                  Several POS Systems{" "}
                  <img src="images/shape/201.svg" alt="shape" />
                </span>{" "}
              </h2>
            </div>
            {/* End title-style-nine */}
            <SocialTwo />
          </div>
          {/* End .container */}

          <img
            src="images/shape/202.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/203.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/204.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/205.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="images/shape/206.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <img
            src="images/shape/207.svg"
            alt="shape"
            className="shapes shape-six"
          />
          <img
            src="images/shape/208.svg"
            alt="shape"
            className="shapes shape-seven"
          />
          <img
            src="images/shape/209.svg"
            alt="shape"
            className="shapes shape-eight"
          />
        </div>
        {/*  /.partner-section-one */}

        {/* 	=====================================================
				Fancy Short Banner Ten
			===================================================== */}
        <div className="fancy-short-banner-ten mt-170 md-mt-80">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-8 col-lg-11 m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="text-center">
                  <h2>Try Review+ Free</h2>
                  <p>
                    After your 7-day trial of our Premium plan, you wont go
                    back!
                  </p>
                </div>
                {/*  /.title-style-six */}
              </div>
            </div>
            {/* End .row */}
            <div
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <div className="download-btn">
                <button onClick={toggleModalOne} type="button">
                  Book a Demo
                </button>
              </div>
              {/* End dropdown */}
              <div className="info-text">
                No Credit Card Required. Cancel Anytime
              </div>
            </div>
          </div>
          {/*  /.container */}
          <img
            src="images/shape/210.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/211.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
        {/*  /.fancy-short-banner-ten */}

        {/*  =====================================================
            Footer Style Eight
        ===================================================== */}
        <footer className="theme-footer-eight mt-100">
          <div className="top-footer">
            <div className="container">
              <FooterEight />
            </div>
            {/* /.container */}
          </div>

          <div className="container">
            <div className="bottom-footer mt-50 md-mt-30">
              <CopyRightFour />
            </div>
          </div>
        </footer>
        {/* /.theme-footer-eight  */}
      </div>

      {/* Mobile Menu End */}

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner ">
          <main className="main-body box_inner modal-content clearfix">
            <button className="close" onClick={toggleModalOne}>
              <img src="images/icon/close.svg" alt="close" />
            </button>
            {/* End close icon */}

            <div className="left-side">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                      “Your reputation is your greatest asset. Let us help you
                      protect it.”
                    </blockquote>
                    <span className="bio">—Review+</span>
                  </div>
                </div>
                <img
                  src="images/assets/ils_18.svg"
                  alt=""
                  className="illustration mt-auto"
                />
              </div>
            </div>
            {/* /.left-side */}

            <div className="right-side">
              <h2 className="form-title">Contact us</h2>
              {loading && <CircularProgress />}
              <HeaderPopupForm email={email} onSuccess={handleFormSuccess} />
            </div>
            {/*  /.right-side */}
          </main>
          {/* /.main-body */}
        </div>
      </Modal>
      {/* Book a Demo */}
      <ToastContainer />
    </>
  );
};

export default HomeScreenView;
