import React from "react";
import { useState, useEffect } from "react";
import firebase from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";

const LogoutMerchant = () => {
  const history = createBrowserHistory();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      logout();
    }, 2500);
  }, []);

  async function logout() {
    try {
      await firebase.logout();
      sessionStorage.removeItem("currentMerchant");
      sessionStorage.removeItem("merchantData");
      history.push("/login");
      navigate("/login", { replace: true });
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <div>
     Please wait logging out...
    </div>
  );
};

export default LogoutMerchant;
