import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Card,
  CardContent,
  IconButton,
  Fade,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CopyAll,
  DateRangeOutlined,
  Flag,
  Transgender,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { Email, Phone, ArrowBack, Feedback } from "@mui/icons-material";
import { Male, Female } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Page from "../../utils/Page";
import Logo from "../../icons/logo.png";
import firebase from "../../config/firebase";
import countries from "../../utils/countries";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  sendSadCustEmail,
  sendHappyCustEmail,
  sendFirstTimeSadCustEmail,
  sendFirstTimeHappyCustEmail,
  notifyCustomerFeedbackToMerchant,
  sendSMS,
} from "../../config/cloudApis";
import ConfettiAnim from "../../utils/animations/confetti.json";
import Lottie from "lottie-react";
import { BsPencilFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { User } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 90%)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    animation: "$fadeIn 2s",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  logoContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  logo: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    boxShadow: "0px 0px 15px 5px rgba(255,255,255,0.7)",
  },
  title: {
    fontSize: "2.5rem", // Increased font size
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(8px)",
    borderRadius: "10px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    position: "relative",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  backButton: {
    position: "absolute",
    left: theme.spacing(1),
  },
  cardTitle: {
    fontWeight: "bold",
    fontSize: "1.5rem", // Increased font size
    color: theme.palette.text.primary,
  },
  changingText: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "1.2rem", // Increased font size
    animation: "$fadeText 3s linear infinite",
    whiteSpace: "nowrap",
    overflow: "hidden",
    userSelect: "none",
  },
  "@keyframes fadeText": {
    "0%": { opacity: 0 },
    "10%": { opacity: 1 },
    "90%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
  inputField: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black", // Changed border color to black
      },
      "&:hover fieldset": {
        borderColor: "black", // Changed border color to black
      },
      "&.Mui-focused fieldset": {
        borderColor: "black", // Changed border color to black
      },
      background: "rgba(255, 255, 255, 0.1)",
      backdropFilter: "blur(10px)",
      borderRadius: "10px",
      color: "black", // Changed font color to black
    },
    "& .MuiInputLabel-root": {
      color: "black", // Changed font color to black
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "black", // Changed font color to black
    },
    "& .MuiInputAdornment-root svg": {
      color: "black", // Changed icon color to black
    },
  },
  snackbarContent: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    justifyContent: "center", // Center the content
    width: "100%", // Ensure full width for centering
    display: "flex",
  },
  button: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    background: `${theme.palette.secondary.light}`,
    color: theme.palette.secondary.lighter,
    fontWeight: "bold",
    width: "100%",
    fontSize: "24px",
    borderRadius: "24px",
    padding: theme.spacing(1, 2),
    animation: "$buttonGlow 2.5s infinite",
    textTransform: "none",
    userSelect: "none",
    "&:hover": {
      background: `${theme.palette.secondary.dark}`,
    },
  },
  confettiAnimation: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 1,
    pointerEvents: "none",
    animation: "$fadeTopToBottom 5s linear infinite",
  },
  "@keyframes fadeTopToBottom": {
    "0%": { opacity: 1, transform: "translateY(0)" },
    "100%": { opacity: 0, transform: "translateY(100%)" },
  },
  genderSelection: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(3), // Increased margin
    marginBottom: theme.spacing(3), // Increased margin
  },
  genderCard: {
    padding: theme.spacing(4), // Increased padding
    cursor: "pointer",
    borderRadius: "10px",
    textAlign: "center",
    color: "#000",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    "&.selected": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&:active": {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Prevent highlight on press
    },
  },
}));

const changingTexts = [
  "RECEIVE_BIRTHDAY_COUPONS",
  "TRACK_VISITS",
  "EARN_COUPONS",
  "GET_NOTIFIED",
  "GAIN_POINTS",
  "FEEDBACK_MANAGEMENT",
  "JOIN_LOYALTY",
  "PARTICIPATE_EVENTS",
];

const CustomerDetailsTabletMode = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameVisible, setNameVisible] = useState(false);
  const [phoneVisible, setPhoneVisible] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [countryVisible, setCountryVisible] = useState(false);
  const [currentText, setCurrentText] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [reviewDialog, setReviewDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [fullCustoDetails, setFullCustoDetails] = useState(false);
  const [collectCustoDetails, setCollectCustoDetails] = useState(true);
  const defaultLogoImgUrl =
    "https://firebasestorage.googleapis.com/v0/b/reviews-plus-8779c.appspot.com/o/businessLogos%2Flogo.png?alt=media&token=152350ef-963f-4483-8adc-0e763abad2d9";

  const [data, setData] = useState({
    email: "",
    phone: "",
    feedback: "",
    fullName: "",
    country: { label: "Canada" },
    dob: "",
    gender: "",
    optEmail: false,
  });

  const [dates, setDates] = useState({
    dob: "",
  });

  const handleDateChange = (name, date) => {
    setDates({
      ...dates,
      [name]: date,
    });

    setData((prevData) => ({
      ...prevData,
      [name]: date ? date.format("YYYY-MM-DD") : "", // Format the date or set to empty string
    }));
  };

  const handleGenderSelect = (gender) => {
    setData((prevData) => ({
      ...prevData,
      gender,
    }));
  };

  const isValidEmailAddress = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    const lowerCaseEmail = value.toLowerCase();
    setEmail(lowerCaseEmail);
    setData((prevData) => ({
      ...prevData,
      email: lowerCaseEmail,
    }));

    setPhoneVisible(false);
    setFeedbackVisible(false);
    setNameVisible(false);

    if (lowerCaseEmail && !isValidEmailAddress(lowerCaseEmail)) {
      setEmailError(t("INVALID_EMAIL"));
    } else {
      setEmailError("");
    }
  };

  const handleDefaultEmailSending = async () => {
    const customerName = data.fullName || "Customer";
    const customerEmail = data.email;
    const googleReviewLink = state.googleReviewUrl;
    const imgUrl = state.businessLogoUrl || defaultLogoImgUrl;
    const businessName = state.businessName;
    const businessAddress = state.businessAddress;

    if (["sad", "angry", "ok"].includes(state.choice)) {
      const result = await sendSadCustEmail(
        state.merchantID,
        customerEmail,
        customerName,
        imgUrl,
        businessName,
        businessAddress
      );

      if (result) {
        console.log("Default Apology email sent successfully");
      } else {
        console.log("Default Failed to send apology email");
      }
    } else if (["happy", "loved"].includes(state.choice)) {
      const result = await sendHappyCustEmail(
        state.merchantID,
        customerEmail,
        customerName,
        googleReviewLink,
        imgUrl,
        businessName,
        businessAddress
      );
      if (result) {
        console.log("Default Happy email sent successfully");
      } else {
        console.log("Default Failed to send happy email");
      }
    }
  };

  const handleFirstTimeEmailSending = async () => {
    const email = data.email;
    const name = data.fullName;
    const googleReviewLink = state.googleReviewUrl;
    const imgUrl = state.businessLogoUrl || defaultLogoImgUrl;
    const businessName = state.businessName;
    const businessAddress = state.businessAddress;

    if (["sad", "angry", "ok"].includes(state.choice)) {
      const result = await sendFirstTimeSadCustEmail(
        state.merchantID,
        email,
        name,
        imgUrl,
        businessName,
        businessAddress
      );
      if (result) {
        console.log("First Time Apology email sent successfully");
      } else {
        console.log("First Time Failed to send apology email");
      }
    } else if (["happy", "loved"].includes(state.choice)) {
      const result = await sendFirstTimeHappyCustEmail(
        state.merchantID,
        email,
        name,
        googleReviewLink,
        imgUrl,
        businessName,
        businessAddress
      );
      if (result) {
        console.log("First Time Happy email sent successfully");
      } else {
        console.log("First Time Failed to send happy email");
      }
    }
  };

  const notifyMerchantOfResponse = async () => {
    const merchantEmail = state.merchantEmail;
    const businessLogoUrl = state.businessLogoUrl || defaultLogoImgUrl;
    const businessName = state.businessName;
    const customerName = data.fullName || "Customer";
    const customerEmail = data.email;
    const customerPhone = data.phone || "Not Provided";
    const customerCountry = data.country.label || "Not Provided";
    const customerDob = data.dob
      ? dayjs(data.dob).format("YYYY-MM-DD")
      : "Not Provided";
    const customerGender = data.gender || "Not Provided";
    const customerFeedback = data.feedback || "No feedback provided";
    const customerFeeling = state.choice;

    const result = await notifyCustomerFeedbackToMerchant(
      state.merchantID,
      merchantEmail,
      businessLogoUrl,
      businessName,
      customerName,
      customerEmail,
      customerPhone,
      customerDob,
      customerGender,
      customerCountry,
      customerFeedback,
      customerFeeling
    );

    if (result) {
      console.log("Merchant notified successfully");
    } else {
      console.log("Failed to notify merchant");
    }
  };

  const checkEmail = async () => {
    setLoading(true);
    const customerRef = firebase.db
      .collection(`merchants/${state.merchantID}/customers`)
      .doc(email);
    const customerDoc = await customerRef.get();

    if (!customerDoc.exists) {
      setIsExistingCustomer(false);
      setPhoneVisible(true);
      setFeedbackVisible(true);
      setCountryVisible(true);
      setNameVisible(true);
    } else {
      setIsExistingCustomer(true);
      const customerData = customerDoc.data();
      const currentDate = dayjs().format("YYYY-MM-DD");

      const scansRef = customerRef.collection("scans").doc(currentDate);
      const scansDoc = await scansRef.get();

      setData({
        ...data,
        fullName: customerData.name,
        dob: customerData.dob ? dayjs(customerData.dob) : "Not provided",
        email: customerData.email,
        phone: customerData.phone,
        gender: customerData.gender,
        country: customerData.country,
      });

      if (customerData.name === "Not Provided") {
        setNameVisible(true);
      }

      if (scansDoc.exists) {
        setOpenDialog(true);
      } else {
        await customerRef.update({
          visits: (customerData.visits || 0) + 1,
        });

        await firebase.db
          .collection(`merchants/${state.merchantID}/experiences`)
          .add({
            feeling: state.choice,
            timestamp: Date.now(),
            email: data.email,
          });
        setFeedbackVisible(true);
      }
    }
    setLoading(false);
  };

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return !match[2]
        ? match[1]
        : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ""}`;
    }
    return value;
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    const formattedPhone = formatPhoneNumber(value);
    setData((prevData) => ({
      ...prevData,
      phone: formattedPhone,
    }));
  };

  const onChangeEvent = (ev) => {
    const { name, value } = ev.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onCountryChangeEvent = (event, newValue) => {
    if (newValue) {
      setData((prevData) => ({
        ...prevData,
        country: newValue,
      }));
    }
  };

  const handleDialogClose = async (retry) => {
    setOpenDialog(false);
    if (retry) {
      setFeedbackVisible(true);
    }
  };

  const copyFeedbackToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(data.feedback).then(
        () => {
          setSnackbarOpen(true);
          setSnackbarMessage(t("FEEDBACK_COPIED"));
          setTimeout(() => {
            handleReviewDialogClose();
          }, 2000);
        },
        (err) => {
          handleReviewDialogClose();
        }
      );
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = data.feedback;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setSnackbarOpen(true);
        setSnackbarMessage(t("FEEDBACK_COPIED"));

        setTimeout(() => {
          handleReviewDialogClose();
        }, 2000);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleReviewDialogClose = () => {
    setReviewDialog(false);
    navigate("/thankyou-tablet-mode", {
      state: {
        logo: state?.businessLogoUrl,
        name: state?.businessName,
        address: state?.businessAddress,
        merchantId: state?.merchantID,
      },
      replace: true,
    });
  };

  const handleSubmit = async () => {
    if (email && !emailError) {
      setLoading(true);

      const userRef = firebase.db.collection("users").doc(email);
      const userDoc = await userRef.get();

      const userUpdates = {
        [`customer.${state.merchantID}`]: true,
      };

      if (!userDoc.exists) {
        // Initialize the user document with empty maps if it doesn't exist
        await userRef.set({
          customer: {},
          admin: {},
          employee: {},
        });
      }

      await userRef.update(userUpdates);

      const dobToSave = data.dob ? data.dob : "Not provided";

      await firebase.saveCustomerResponse({
        merchantID: state.merchantID,
        customerID: email,
        name: data.fullName,
        email: data.email,
        phone: data.phone,
        dob: dobToSave,
        gender: data.gender,
        feedback: data.feedback,
        country: data.country.label,
        feeling: state.choice,
        optEmail: data.optEmail,
      });

      // Send email to merchant
      await notifyMerchantOfResponse();

      if (
        ["sad", "angry", "ok"].includes(state.choice) &&
        ["STAN_01", "PREMIUM_01"].includes(state.subscriptions.plan)
      ) {
        const message = `Review+ Customer Feedback Alert:\n\nName: ${
          data.fullName || "Not Provided"
        }\nEmail: ${data.email}\nPhone: ${
          data.phone || "Not Provided"
        }\nDate of birth: ${dobToSave}\nGender: ${
          data.gender || "Not Provided"
        }\nCountry: ${data.country.label || "Not Provided"}\nExperience: ${
          state.choice
        }\nFeedback: ${
          data.feedback || "No feedback provided"
        }\n\nFeel free to directly reach out to the customer to resolve the issue for much better customer experience.`;

        await sendSMS(state.merchantID, email, state.ownerPhone, message);
      }

      if (isExistingCustomer) {
        await handleDefaultEmailSending();
        navigate("/thankyou-tablet-mode", {
          state: {
            logo: state?.businessLogoUrl,
            name: state?.businessName,
            address: state?.businessAddress,
            merchantId: state?.merchantID,
          },
          replace: true,
        });
      } else {
        await handleFirstTimeEmailSending();

        navigate("/thankyou-tablet-mode", {
          state: {
            logo: state?.businessLogoUrl,
            name: state?.businessName,
            address: state?.businessAddress,
            merchantId: state?.merchantID,
          },
          replace: true,
        });
      }

      setLoading(false);
    } else {
      setSnackbarOpen(true);
      setSnackbarMessage(t("INVALID_EMAIL"));
      console.log("Invalid email address.");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % changingTexts.length);
    }, 3000);
    if (state !== null) {
      setFullCustoDetails(state.settings.fullCustDetails);
      setCollectCustoDetails(state.settings.collectCustDetails);
    } else {
      navigate("/not-found", { replace: true });
    }
    return () => clearInterval(interval);
  }, [state, navigate]);

  return (
    <Page className={classes.root} title={t("CUSTOMER_DETAILS")}>
      {["happy", "loved"].includes(state.choice) && !phoneVisible && (
        <Lottie
          animationData={ConfettiAnim}
          loop={true}
          className={classes.confettiAnimation}
        />
      )}
      <Container
        maxWidth="sm"
        style={{ overflowY: "auto", maxHeight: "100vh" }}
      >
        <div className={classes.logoContainer}>
          {state?.businessLogoUrl ? (
            <img
              src={state.businessLogoUrl}
              alt={t("BUSINESS_NAME")}
              className={classes.logo}
            />
          ) : (
            <img src={Logo} alt={t("BUSINESS_NAME")} className={classes.logo} />
          )}
        </div>
        <Typography className={classes.title}>
          {state?.businessName || t("BUSINESS_NAME")}
        </Typography>
        <Card className={classes.card}>
          <div className={classes.header}>
            <IconButton
              className={classes.backButton}
              onClick={() => navigate(-1)}
            >
              <ArrowBack style={{ color: "#000" }} />
            </IconButton>
            <Typography className={classes.cardTitle}>
              {t("TELL_US_ABOUT_YOU")}
            </Typography>
          </div>
          <CardContent>
            <Typography className={classes.changingText}>
              {t(changingTexts[currentText])}
            </Typography>
            <TextField
              className={classes.inputField}
              fullWidth
              variant="outlined"
              label={t("ENTER_EMAIL")}
              name="email"
              value={email}
              onChange={handleEmailChange}
              helperText={emailError}
              error={!!emailError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            {(phoneVisible || nameVisible) && (
              <TextField
                className={classes.inputField}
                fullWidth
                variant="outlined"
                label={t("ENTER_FULL_NAME")}
                name="fullName"
                value={data.fullName}
                onChange={onChangeEvent}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User />
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: "24px" }}
              />
            )}

            {!phoneVisible && !feedbackVisible && (
              <Button
                className={classes.button}
                fullWidth
                style={{ marginTop: "16px" }}
                variant="contained"
                onClick={checkEmail}
                disabled={!email || !!emailError || loading}
              >
                {loading ? <CircularProgress size={24} /> : t("NEXT")}
              </Button>
            )}
            {phoneVisible && (
              <Fade in={phoneVisible}>
                <Box mt={2}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    variant="outlined"
                    label={t("ENTER_MOBILE_NUMBER")}
                    onChange={handlePhoneChange}
                    name="phone"
                    value={data.phone}
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Fade>
            )}
            {!isExistingCustomer &&
              state.choice !== "sad" &&
              state.choice !== "angry" &&
              state.choice !== "ok" && (
                <>
                  {phoneVisible ? (
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: "#000",
                          marginTop: "16px",
                        }}
                      >
                        {t("CHOOSE_GENDER")}
                      </Typography>
                      <div className={classes.genderSelection}>
                        <Card
                          className={`${classes.genderCard} ${
                            data.gender === "male" ? "selected" : ""
                          }`}
                          onClick={() => handleGenderSelect("male")}
                        >
                          <Male style={{ fontSize: 60, color: "#000" }} />{" "}
                          {/* Increased icon size */}
                          <Typography
                            style={{
                              color: "#000",
                            }}
                            variant="body1"
                          >
                            {t("MALE")}
                          </Typography>
                        </Card>
                        <Card
                          className={`${classes.genderCard} ${
                            data.gender === "female" ? "selected" : ""
                          }`}
                          onClick={() => handleGenderSelect("female")}
                        >
                          <Female style={{ fontSize: 60, color: "#000" }} />{" "}
                          {/* Increased icon size */}
                          <Typography
                            style={{
                              color: "#000",
                            }}
                            variant="body1"
                          >
                            {t("FEMALE")}
                          </Typography>
                        </Card>
                        <Card
                          className={`${classes.genderCard} ${
                            data.gender === "others"
                              ? "selected"
                              : "Not Provided"
                          }`}
                          onClick={() => handleGenderSelect("others")}
                        >
                          <Transgender
                            style={{ fontSize: 40, color: "#000" }}
                          />
                          <Typography style={{ color: "#000" }} variant="body1">
                            {t("OTHERS")}
                          </Typography>
                        </Card>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          className={classes.inputField}
                          label={t("DATE_OF_BIRTH")}
                          value={dates.dob}
                          name="dob"
                          disableFuture
                          maxDate={dayjs().subtract(10, "year")}
                          onChange={(newValue) => {
                            handleDateChange("dob", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={classes.inputField}
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <DateRangeOutlined
                                      style={{ fontSize: 30 }}
                                    />{" "}
                                    {/* Increased icon size */}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <FormHelperText>{t("CHOOSE_DOB")}</FormHelperText>
                      <Autocomplete
                        className={classes.inputField}
                        fullWidth
                        options={countries}
                        getOptionLabel={(option) => `${option.label}`}
                        value={data.country}
                        onChange={onCountryChangeEvent}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ marginTop: "16px" }}
                            label={t("CHOOSE_COUNTRY")}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Flag style={{ fontSize: 30 }} />{" "}
                                  {/* Increased icon size */}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div style={{ display: "none" }} />
                  )}
                </>
              )}
            {feedbackVisible && (
              <Fade in={feedbackVisible}>
                <Box mt={2}>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    variant="outlined"
                    label={t("FEEDBACK")}
                    name="feedback"
                    multiline
                    rows={4}
                    onChange={onChangeEvent}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Feedback style={{ fontSize: 30 }} />{" "}
                          {/* Increased icon size */}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <FormHelperText>{t("FEEDBACK_HELPER")}</FormHelperText> */}
                  <Typography
                    variant="body2"
                    style={{
                      color: "#000",
                      marginTop: "16px",
                      marginBottom: "6px",
                      fontSize: "0.9rem", // Increased font size
                    }}
                  >
                    {t("ACKNOWLEDGE_CONSENT", {
                      businessName: state.businessName,
                    })}
                  </Typography>

                  <Button
                    className={classes.button}
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : t("SUBMIT")}
                  </Button>
                </Box>
              </Fade>
            )}
          </CardContent>
        </Card>
      </Container>
      <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            style={{ fontWeight: "bold", fontSize: "1.5rem" }} // Increased font size
          >
            {t("FEEDBACK_ALREADY_SUBMITTED")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: "1.2rem" }}>
            {t("FEEDBACK_ALREADY_SUBMITTED_MESSAGE")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleDialogClose(false)}
            color="primary"
            style={{ fontSize: "1.2rem" }} // Increased font size
          >
            {t("NO")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
            style={{ fontSize: "1.2rem" }} // Increased font size
          >
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={reviewDialog} onClose={handleReviewDialogClose}>
        <DialogTitle>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", fontSize: "1.5rem" }} // Increased font size
          >
            {t("LEAVE_GOOGLE_REVIEW")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: "1.2rem" }}>
            {t("LEAVE_GOOGLE_REVIEW_MESSAGE")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {data.feedback && (
            <Button
              variant="contained"
              onClick={copyFeedbackToClipboard}
              color="primary"
              sx={{ fontSize: "1.2rem" }} // Increased font size
              startIcon={<CopyAll style={{ fontSize: 30 }} />} // Increased icon size
            >
              {t("COPY_NAVIGATE")}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleReviewDialogClose}
            color="primary"
            sx={{ fontSize: "1.2rem" }} // Increased font size
            startIcon={<BsPencilFill style={{ fontSize: 30 }} />} // Increased icon size
          >
            {t("LEAVE_REVIEW")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Center the Snackbar
      >
        <SnackbarContent
          className={classes.snackbarContent}
          message={snackbarMessage}
        />
      </Snackbar>
    </Page>
  );
};

export default CustomerDetailsTabletMode;
