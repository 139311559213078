import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import { useState, useEffect } from "react";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import GoogleIcon from "@mui/icons-material/Google";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { EmailOutlined } from "@mui/icons-material";
import LockOutlined from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import PerformanceWidget from "../../components/Widget/PerformanceWidget";
import Lottie from "lottie-react";
//import bannerAnimation from '../../utils/animfinsetup.json';
import bannerAnimation from "../../utils/animations/setupfinishbk.json";
import firebase from "../../config/firebase";
import { useLocation } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
require("../../App.css");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: 14,
  },
  btnArea: {
    display: "flex",
    justifyContent: "space-around",
    margin: `${theme.spacing(2)}px 0`,
    fontSize: 12,
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& button": {
        width: "100%",
        margin: 5,
      },
    },
  },
  errorText: {
    color: theme.palette.error.main,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  typography: {
    padding: theme.spacing(2),
  },
  footer: {
    paddingTop: "50px",
    fontSize: 15,
  },
  seperator: {
    paddingTop: "4px",
    paddingBottom: "4px",
    fontSize: 15,
  },
}));

//Lottie options
const defaultOptions = {
  loop: 5,
  autoplay: false,
  animationData: bannerAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const SetupComplete = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [fieldValues, setFieldValues] = useState({
    email: null,
    password: null,
    confirmpassword: null,
  });

  const [percent, setPercent] = useState(90);
  const [isDataFilled, setIsDataFilled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorEmailMessage, setEmailErrorMessage] = useState("");
  const [errorPwdMessage, setPwdErrorMessage] = useState("");
  const [errorConPwdMessage, setConPwdErrorMessage] = useState("");

  useEffect(() => {
    //set the progress bar
    if (state !== null) {
      setPercent(75);
    } else {
      alert("Business details are not found. Please scan your QR code again.");
      navigate("/login");
    }
  }, []);

  function isValidEmailAddress(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  function validLength(password) {
    if (password.length >= 6) {
      return true;
    } else {
      return false;
    }
  }

  const showAlertBox = () => {
    setIsError(true);
  };

  const hideAlertBox = () => {
    setIsError(false);
  };

  const completeRegistration = () => {
    setIsError(false);
    firebase.updateCloverMerchantProfile(state, fieldValues.email).then(() => {
      navigateToThankyou();
    });
  };

  useEffect(() => {
    if (
      fieldValues.email !== null &&
      !isValidEmailAddress(fieldValues.email) &&
      fieldValues.email !== ""
    ) {
      setEmailErrorMessage("Incorrect Email is entered. Please try again");
    } else {
      setEmailErrorMessage("");
    }
  }, [fieldValues.email]);

  useEffect(() => {
    if (
      fieldValues.password !== null &&
      !validLength(fieldValues.password) &&
      fieldValues.password !== ""
    ) {
      setPwdErrorMessage("Minimum 6 characters are required");
    } else {
      setPwdErrorMessage("");
    }
  }, [fieldValues.password]);

  useEffect(() => {
    if (
      fieldValues.confirmpassword !== null &&
      !validLength(fieldValues.confirmpassword) &&
      fieldValues.confirmpassword !== ""
    ) {
      setConPwdErrorMessage("Minimum 6 characters are required");
    } else {
      setConPwdErrorMessage("");
    }
  }, [fieldValues.confirmpassword]);

  const handleSubmition = (e) => {
    e.preventDefault();
    //new Date().toUTCString();
    if (
      fieldValues.email !== null &&
      fieldValues.password !== null &&
      fieldValues.password.length >= 6 &&
      fieldValues.confirmpassword !== null &&
      fieldValues.confirmpassword.length >= 6
    ) {
      if (fieldValues.password !== fieldValues.confirmpassword) {
        alert("Passwords do not match. Please try again");
      } else {
        register(state);
      }
    } else {
      console.log(
        fieldValues.email +
          " " +
          fieldValues.password +
          " " +
          fieldValues.confirmpassword
      );
      alert("Please enter proper details to complete registration");
    }
  };

  const handleChange = (data) => {
    setFieldValues((prevState) => ({
      ...prevState,
      [data.target.name]: data.target.value,
    }));
  };

  useEffect(() => {
    if (state !== null) {
      if (
        fieldValues.email !== null &&
        fieldValues.password !== null &&
        fieldValues.confirmpassword !== null
      ) {
        if (fieldValues.password === fieldValues.confirmpassword) {
          setIsDataFilled(true);
        } else {
          setIsDataFilled(false);
        }
      }
    }
  }, [fieldValues.confirmpassword]);

  async function register(state) {
    let merchantInfo = await firebase.checkIfRegistered(fieldValues.email);

    if (merchantInfo.length > 0) {
      //email already registered
      showAlertBox();
    } else {
      //email not registered
      try {
        await firebase
          .register(fieldValues.email, fieldValues.password, state)
          .then(() => {
            firebase.updateMerchantProfile(state).then(() => {
              navigateToThankyou();
            });
          });
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    }
  }

  async function registerWithGoogle() {
    let merchantInfo = await firebase.checkIfRegistered(fieldValues.email);

    if (merchantInfo.length > 0) {
      //email already registered
      showAlertBox();
    } else {
      //email not registered
      try {
        await firebase.registerWithGoogle(state).then((res) => {
          if (res !== undefined || res !== null) {
            firebase.updateMerchantProfile(state).then(() => {
              navigateToThankyou();
            });
          }
        });
      } catch (error) {
        console.log(error);
        //alert(error.message);
      }
    }
  }

  function navigateToDashboard() {
    navigate("/app/dashboard");
  }

  function navigateToThankyou() {
    firebase.logout().then(() => {
      const logoUrl = state.businessLogoUrl;
      navigate("/cs-thankyou", {
        state: {
          businessName: state.businessName,
          logo: logoUrl,
        },
      });
    });
  }

  return (
    <Page
      className={classes.root}
      title="Review+ Connect"
      style={{ background: "#fff", paddingBottom: "3rem", overflow: "scroll" }}
      // style={{ background: "radial-gradient(#ffffff, #FAC94E)" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="md">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Lottie animationData={bannerAnimation} style={{ height: "300px" }} />
          <Typography variant="h3" gutterBottom>
            Finish Setup
          </Typography>
          <Typography
            variant="caption"
            className={classes.subtitle}
            gutterBottom
            align="center"
          >
            Register with your Email Id and Password to get access to Review
            Plus Portal. As a Merchant, You can configure app settings, get
            recent reviews, promote social media accounts, collect customer data
            and offer discounts based on visits etc in future updates.
          </Typography>

          <Dialog
            open={isError}
            onClose={hideAlertBox}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                A Merchant account already exists with the Email ID{" "}
                {fieldValues.email}. Do you want to link this merchant account
                to same email ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={hideAlertBox} color="secondary" autoFocus>
                No
              </Button>
              <Button onClick={completeRegistration} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <section>
            <div>
              <PerformanceWidget passToChild={percent} />
            </div>
          </section>
          <form onSubmit={handleSubmition} autoComplete="off">
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              name="email"
              type="email"
              placeholder="Enter your Email"
              value={fieldValues.email || ""}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              helperText={errorEmailMessage}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Password"
              margin="normal"
              name="password"
              type="password"
              value={fieldValues.password || ""}
              autoComplete="new-password"
              variant="outlined"
              helperText={errorPwdMessage}
              placeholder="Enter your Password"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              margin="normal"
              name="confirmpassword"
              type="password"
              helperText={errorConPwdMessage}
              placeholder="Confirm your Password"
              value={fieldValues.confirmpassword || ""}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />

            <div className={classes.btnArea}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                disabled={!isDataFilled ? true : false}
              >
                Complete Setup
                <ArrowForward
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                />
              </Button>
            </div>

            <Typography className={classes.seperator} align="center">
              or
            </Typography>

            <div className={classes.btnArea}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => registerWithGoogle()}
              >
                Register with Google
                <GoogleIcon
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                />
              </Button>
            </div>
          </form>
        </Container>
      </Box>
      {/* <Hidden mdUp>
        <NavLink to="/">
          <img src={logo} alt="Review+" />
          Review+
        </NavLink>
      </Hidden>     */}
    </Page>
  );
};

export default SetupComplete;
