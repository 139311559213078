import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTheme } from "@mui/material/styles";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BmAnalyticsChart = ({ initial, current, label }) => {
  const theme = useTheme();

  const data = {
    labels: ["Initial", "Current"],
    datasets: [
      {
        label: label,
        data: [initial, current],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.secondary.light,
        ],
        borderColor: [theme.palette.primary.dark, theme.palette.secondary.dark],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `${label} Analytics`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BmAnalyticsChart;
