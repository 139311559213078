import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderLandingAppointment from "../../components/home/header/landing/HeaderLandingAppointment";
import FooterEight from "../../components/home/footer/FooterEight";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");

  const toggleModalOne = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>Terms & Conditions || Review+</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingAppointment
        toggleModalOne={toggleModalOne}
        setEmail={setEmail}
      />
      {/* End Header */}

      {/* =====================================================
        Terms and Condition
      ===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Website terms of use
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Terms and conditions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      3. Privacy policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      4. Cookie policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      5. Third Party Links
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                      6. GDPR
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Website Terms of Use</h2>
                    <div className="update-date">
                      LAST UPDATED: 08/July/2024
                    </div>
                    <h3>1. Introduction</h3>
                    <p>
                      Welcome to Review+. By accessing our website, you agree to
                      comply with and be bound by the following terms and
                      conditions of use, which together with our privacy policy
                      govern Review+'s relationship with you in relation to this
                      website. If you disagree with any part of these terms and
                      conditions, please do not use our website.
                    </p>
                    <h3>2. About Us</h3>
                    <p>
                      Review+ is a customer feedback management software
                      designed to help small business owners collect, analyze,
                      and act on customer feedback to drive business growth and
                      improve customer satisfaction.
                    </p>
                    <h3>3. Accessing Our Website</h3>
                    <p>
                      Access to our website is permitted on a temporary basis,
                      and we reserve the right to withdraw or amend the service
                      we provide on our site without notice. We will not be
                      liable if for any reason our site is unavailable at any
                      time or for any period.
                    </p>
                  </div>
                  <div id="opt2">
                    <h2 className="font-gilroy-bold">Terms & Conditions</h2>
                    <div className="update-date">
                      LAST UPDATED: 08/July/2024
                    </div>
                    <h3>1. Introduction</h3>
                    <p>
                      This page, together with our Privacy Policy and the
                      Website Terms of Use tells you information about us and
                      the legal terms and conditions (the "Terms") on which we
                      make available to you our online feedback management
                      solution (the "Service").
                    </p>
                    <h3>2. Information About Us</h3>
                    <p>
                      Review+ is a product of Tezooo Innovations Inc. We are
                      registered in Canada and India, and have our registered
                      office at 2 Robert Speck Parkway, Suite 750, Mississauga,
                      Ontario L4Z 1H8. Our Business Number is 780087953RC0001.
                    </p>
                    <p>
                      You can contact us by emailing{" "}
                      <a href="mailto:info@tezooo.com">info@tezooo.com</a>.
                    </p>
                  </div>
                  <div id="opt3">
                    <h2 className="font-gilroy-bold">Privacy Policy</h2>
                    <div className="update-date">
                      LAST UPDATED: 08/July/2024
                    </div>
                    <h3>1. Introduction</h3>
                    <p>
                      We are committed to protecting and respecting your
                      privacy. This policy (together with our terms of use and
                      any other documents referred to on it) sets out the basis
                      on which any personal data we collect from you, or that
                      you provide to us, will be processed by us.
                    </p>
                    <h3>2. Information We May Collect From You</h3>
                    <p>
                      We may collect and process the following data about you:
                      Information that you provide by filling in forms on our
                      site. This includes information provided at the time of
                      registering to use our site, subscribing to our service,
                      posting material, or requesting further services.
                    </p>
                    <h3>3. Cookies</h3>
                    <p>
                      Our website uses cookies to distinguish you from other
                      users of our website. This helps us to provide you with a
                      good experience when you browse our website and also
                      allows us to improve our site.
                    </p>
                  </div>
                  <div id="opt4">
                    <h2 className="font-gilroy-bold">Cookie Policy</h2>
                    <div className="update-date">
                      LAST UPDATED: 08/July/2024
                    </div>
                    <h3>1. Introduction</h3>
                    <p>
                      We use cookies to enhance your experience on our site. By
                      continuing to use our site, you agree to our use of
                      cookies.
                    </p>
                    <h3>2. What Are Cookies?</h3>
                    <p>
                      Cookies are small data files that are placed on your
                      computer or mobile device when you visit a website. They
                      are widely used by website owners in order to make their
                      websites work, or to work more efficiently, as well as to
                      provide reporting information.
                    </p>
                  </div>
                  <div id="opt5">
                    <h2 className="font-gilroy-bold">Third Party Links</h2>
                    <div className="update-date">
                      LAST UPDATED: 08/July/2024
                    </div>
                    <h3>1. Introduction</h3>
                    <p>
                      Our website may contain links to third-party websites,
                      plug-ins, and applications. Clicking on those links or
                      enabling those connections may allow third parties to
                      collect or share data about you. We do not control these
                      third-party websites and are not responsible for their
                      privacy statements.
                    </p>
                  </div>
                  <div id="opt6">
                    <h2 className="font-gilroy-bold">GDPR</h2>
                    <div className="update-date">
                      LAST UPDATED: 08/July/2024
                    </div>
                    <h3>1. Introduction</h3>
                    <p>
                      We have taken a number of steps to comply with the GDPR.
                      For more details about our compliance and how we handle
                      your data please check our latest legal documents below:
                    </p>
                    <ul>
                      <li>
                        <a href="#opt3">Privacy policy</a>
                      </li>
                      <li>
                        <a href="#opt2">Terms and conditions</a>
                      </li>
                    </ul>
                    <h3>2. About Us</h3>
                    <p>
                      When you use Review+ for collecting data from your
                      customers, you are the “data controller” of all your
                      customers’ data. We are a “data processor”, which means
                      that we won’t do anything with your customers’ data other
                      than what you need us to in order to provide our service.
                    </p>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TermsConditions;
