import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Container,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../../utils/Page";
import { useNavigate } from "react-router-dom";
import firebase from "../../../config/firebase";
import BmCardContainer from "../../../components/merchant/BmCardContainer";
import BmCustomerAnalysis from "../../../components/merchant/BmCustomerAnalysis";
import BmExperienceCard from "../../../components/merchant/BmExperinceCard";
import BmQrScansGraph from "../../../components/merchant/BmQrScansGraph";
import BmRecentReviewsContainer from "../../../components/merchant/BmRecentReviewsContainer";
import { createBrowserHistory } from "history";
import { getGoogleReviewDetails } from "../../../config/cloudApis";
import MerchantContext from "../../../config/MerchantContext";
import { useTranslation } from "react-i18next";

import "../../../utils/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: "100vh",
    marginBottom: theme.spacing(6),
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  title: {
    color: "#000000",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  select: {
    borderRadius: 25,
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:focus": {
      borderRadius: 25,
      backgroundColor: "white",
    },
    maxWidth: "200px", // Set fixed width
  },
  menuItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const DashboardView = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    currentMerchant,
    handleLocationChange,
    selectedLocation,
    merchantData,
    plan,
  } = useContext(MerchantContext);
  const [customerFeelings, setCustomerFeelings] = useState({});
  const [analytics, setAnalytics] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const history = createBrowserHistory();

  useEffect(() => {
    if (currentMerchant) {
      getCustomerFeelings(currentMerchant.merchantID);
      calculateAnalytics(currentMerchant);
    }
  }, [currentMerchant]);

  const handleLogout = () => {
    logout();
  };

  async function logout() {
    try {
      await firebase.logout();
      sessionStorage.removeItem("currentMerchant");
      sessionStorage.removeItem("merchantData");
      sessionStorage.removeItem("selectedLocation");
      navigate("/login", { replace: true });
    } catch (error) {
      alert(error.message);
    }
  }

  const findObjectById = (id, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].merchantID === id) {
        return data[i];
      }
    }
    return null;
  };

  const getCustomerFeelings = (merchantId) => {
    try {
      firebase.getCustomerExperience(merchantId).then((data) => {
        console.log("Fetched customer feelings data:", data); // Log customer feelings data

        setCustomerFeelings(data);
      });
    } catch (error) {
      alert(error.message);
    }
  };

  const calculateAnalytics = (merchant) => {
    if (merchant) {
      const { initGoogleReviews, initGoogleRating, googlePlaceID } = merchant;
      getGoogleReviewDetails(googlePlaceID).then((result) => {
        if (result && result.data) {
          const { user_ratings_total: currentReviews, rating: currentRating } =
            result.data;
          const reviewIncrease = initGoogleReviews
            ? ((currentReviews - initGoogleReviews) / initGoogleReviews) * 100
            : 0;
          const ratingIncrease = initGoogleRating
            ? ((currentRating - initGoogleRating) / initGoogleRating) * 100
            : 0;
          setAnalytics({
            currentReviews,
            currentRating,
            reviewIncrease: reviewIncrease.toFixed(2),
            ratingIncrease: ratingIncrease.toFixed(2),
          });
        }
      });
    }
  };

  const handleSubscriptionClick = () => {
    navigate("/app/subscription", {
      replace: true,
    });
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.marginBtm}
          mt={2}
        >
          <Typography
            variant="h4"
            className={classes.title}
            sx={{ mt: 1, fontWeight: "bold" }}
          >
            {t("WELCOME_BACK")}
          </Typography>
          <Select
            className={classes.select}
            value={selectedLocation}
            onChange={(e) => handleLocationChange(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Choose location" }}
          >
            <MenuItem className={classes.menuItem} value="" disabled>
              Choose Location
            </MenuItem>
            {merchantData.map((location) => (
              <MenuItem
                className={classes.menuItem}
                value={location.merchantID}
                key={location.merchantID}
              >
                {`${location.businessName} - ${location.businessAddress}`}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {currentMerchant ? (
          <BmCardContainer currentMerchant={currentMerchant} />
        ) : (
          <p>Loading...</p>
        )}

        <Grid mt={1} container direction="row" spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {currentMerchant ? (
              <BmCustomerAnalysis currentMerchant={currentMerchant} />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {customerFeelings && Object.keys(customerFeelings).length > 0 && (
              <BmExperienceCard
                percentage={[
                  parseFloat(customerFeelings.angry),
                  parseFloat(customerFeelings.sad),
                  parseFloat(customerFeelings.ok),
                  parseFloat(customerFeelings.happy),
                  parseFloat(customerFeelings.loved),
                ]}
              />
            )}
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={4} mt={1}>
          <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
            {currentMerchant ? (
              <BmQrScansGraph currentMerchant={currentMerchant} />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
            {currentMerchant ? (
              <BmRecentReviewsContainer currentMerchant={currentMerchant} />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {}}
        disableEscapeKeyDown
      >
        <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
          Subscription Required! 😣
        </DialogTitle>
        <DialogContent>
          <Typography>
            Your subscription has expired. Please renew your subscription to
            access all features. If already subscribed, please relogin to gain
            access.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleLogout}>
            Logout
          </Button>
          <Button
            onClick={handleSubscriptionClick}
            color="secondary"
            variant="contained"
          >
            Subscribe Now
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default DashboardView;
