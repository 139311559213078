const countries = [
    { label: "Afghanistan" },
    { label: "Albania" },
    { label: "Algeria" },
    { label: "American Samoa" },
    { label: "Andorra" },
    { label: "Angola" },
    { label: "Anguilla" },
    { label: "Antarctica" },
    { label: "Antigua and Barbuda" },
    { label: "Argentina" },
    { label: "Armenia" },
    { label: "Aruba" },
    { label: "Australia" },
    { label: "Austria" },
    { label: "Azerbaijan" },
    { label: "Bahamas (the)" },
    { label: "Bahrain" },
    { label: "Bangladesh" },
    { label: "Barbados" },
    { label: "Belarus" },
    { label: "Belgium" },
    { label: "Belize" },
    { label: "Benin" },
    { label: "Bermuda" },
    { label: "Bhutan" },
    { label: "Bolivia (Plurinational State of)" },
    { label: "Bonaire, Sint Eustatius and Saba" },
    { label: "Bosnia and Herzegovina" },
    { label: "Botswana" },
    { label: "Bouvet Island" },
    { label: "Brazil" },
    { label: "British Indian Ocean Territory (the)" },
    { label: "Brunei Darussalam" },
    { label: "Bulgaria" },
    { label: "Burkina Faso" },
    { label: "Burundi" },
    { label: "Cabo Verde" },
    { label: "Cambodia" },
    { label: "Cameroon" },
    { label: "Canada" },
    { label: "Cayman Islands (the)" },
    { label: "Central African Republic (the)" },
    { label: "Chad" },
    { label: "Chile" },
    { label: "China" },
    { label: "Christmas Island" },
    { label: "Cocos (Keeling) Islands (the)" },
    { label: "Colombia" },
    { label: "Comoros (the)" },
    { label: "Congo (the Democratic Republic of the)" },
    { label: "Congo (the)" },
    { label: "Cook Islands (the)" },
    { label: "Costa Rica" },
    { label: "Croatia" },
    { label: "Cuba" },
    { label: "Curaçao" },
    { label: "Cyprus" },
    { label: "Czechia" },
    { label: "Côte d'Ivoire" },
    { label: "Denmark" },
    { label: "Djibouti" },
    { label: "Dominica" },
    { label: "Dominican Republic (the)" },
    { label: "Ecuador" },
    { label: "Egypt" },
    { label: "El Salvador" },
    { label: "Equatorial Guinea" },
    { label: "Eritrea" },
    { label: "Estonia" },
    { label: "Eswatini" },
    { label: "Ethiopia" },
    { label: "Falkland Islands (the) [Malvinas]" },
    { label: "Faroe Islands (the)" },
    { label: "Fiji" },
    { label: "Finland" },
    { label: "France" },
    { label: "French Guiana" },
    { label: "French Polynesia" },
    { label: "French Southern Territories (the)" },
    { label: "Gabon" },
    { label: "Gambia (the)" },
    { label: "Georgia" },
    { label: "Germany" },
    { label: "Ghana" },
    { label: "Gibraltar" },
    { label: "Greece" },
    { label: "Greenland" },
    { label: "Grenada" },
    { label: "Guadeloupe" },
    { label: "Guam" },
    { label: "Guatemala" },
    { label: "Guernsey" },
    { label: "Guinea" },
    { label: "Guinea-Bissau" },
    { label: "Guyana" },
    { label: "Haiti" },
    { label: "Heard Island and McDonald Islands" },
    { label: "Holy See (the)" },
    { label: "Honduras" },
    { label: "Hong Kong" },
    { label: "Hungary" },
    { label: "Iceland" },
    { label: "India" },
    { label: "Indonesia" },
    { label: "Iran (Islamic Republic of)" },
    { label: "Iraq" },
    { label: "Ireland" },
    { label: "Isle of Man" },
    { label: "Israel" },
    { label: "Italy" },
    { label: "Jamaica" },
    { label: "Japan" },
    { label: "Jersey" },
    { label: "Jordan" },
    { label: "Kazakhstan" },
    { label: "Kenya" },
    { label: "Kiribati" },
    { label: "Korea (the Democratic People's Republic of)" },
    { label: "Korea (the Republic of)" },
    { label: "Kuwait" },
    { label: "Kyrgyzstan" },
    { label: "Lao People's Democratic Republic (the)" },
    { label: "Latvia" },
    { label: "Lebanon" },
    { label: "Lesotho" },
    { label: "Liberia" },
    { label: "Libya" },
    { label: "Liechtenstein" },
    { label: "Lithuania" },
    { label: "Luxembourg" },
    { label: "Macao" },
    { label: "Madagascar" },
    { label: "Malawi" },
    { label: "Malaysia" },
    { label: "Maldives" },
    { label: "Mali" },
    { label: "Malta" },
    { label: "Marshall Islands (the)" },
    { label: "Martinique" },
    { label: "Mauritania" },
    { label: "Mauritius" },
    { label: "Mayotte" },
    { label: "Mexico" },
    { label: "Micronesia (Federated States of)" },
    { label: "Moldova (the Republic of)" },
    { label: "Monaco" },
    { label: "Mongolia" },
    { label: "Montenegro" },
    { label: "Montserrat" },
    { label: "Morocco" },
    { label: "Mozambique" },
    { label: "Myanmar" },
    { label: "Namibia" },
    { label: "Nauru" },
    { label: "Nepal" },
    { label: "Netherlands (the)" },
    { label: "New Caledonia" },
    { label: "New Zealand" },
    { label: "Nicaragua" },
    { label: "Niger (the)" },
    { label: "Nigeria" },
    { label: "Niue" },
    { label: "Norfolk Island" },
    { label: "Northern Mariana Islands (the)" },
    { label: "Norway" },
    { label: "Oman" },
    { label: "Pakistan" },
    { label: "Palau" },
    { label: "Palestine, State of" },
    { label: "Panama" },
    { label: "Papua New Guinea" },
    { label: "Paraguay" },
    { label: "Peru" },
    { label: "Philippines (the)" },
    { label: "Pitcairn" },
    { label: "Poland" },
    { label: "Portugal" },
    { label: "Puerto Rico" },
    { label: "Qatar" },
    { label: "Republic of North Macedonia" },
    { label: "Romania" },
    { label: "Russian Federation (the)" },
    { label: "Rwanda" },
    { label: "Réunion" },
    { label: "Saint Barthélemy" },
    { label: "Saint Helena, Ascension and Tristan da Cunha" },
    { label: "Saint Kitts and Nevis" },
    { label: "Saint Lucia" },
    { label: "Saint Martin (French part)" },
    { label: "Saint Pierre and Miquelon" },
    { label: "Saint Vincent and the Grenadines" },
    { label: "Samoa" },
    { label: "San Marino" },
    { label: "Sao Tome and Principe" },
    { label: "Saudi Arabia" },
    { label: "Senegal" },
    { label: "Serbia" },
    { label: "Seychelles" },
    { label: "Sierra Leone" },
    { label: "Singapore" },
    { label: "Sint Maarten (Dutch part)" },
    { label: "Slovakia" },
    { label: "Slovenia" },
    { label: "Solomon Islands" },
    { label: "Somalia" },
    { label: "South Africa" },
    { label: "South Georgia and the South Sandwich Islands" },
    { label: "South Sudan" },
    { label: "Spain" },
    { label: "Sri Lanka" },
    { label: "Sudan (the)" },
    { label: "Suriname" },
    { label: "Svalbard and Jan Mayen" },
    { label: "Sweden" },
    { label: "Switzerland" },
    { label: "Syrian Arab Republic" },
    { label: "Taiwan" },
    { label: "Tajikistan" },
    { label: "Tanzania, United Republic of" },
    { label: "Thailand" },
    { label: "Timor-Leste" },
    { label: "Togo" },
    { label: "Tokelau" },
    { label: "Tonga" },
    { label: "Trinidad and Tobago" },
    { label: "Tunisia" },
    { label: "Turkey" },
    { label: "Turkmenistan" },
    { label: "Turks and Caicos Islands (the)" },
    { label: "Tuvalu" },
    { label: "Uganda" },
    { label: "Ukraine" },
    { label: "United Arab Emirates (the)" },
    { label: "United Kingdom of Great Britain and Northern Ireland (the)" },
    { label: "United States Minor Outlying Islands (the)" },
    { label: "United States of America (the)" },
    { label: "Uruguay" },
    { label: "Uzbekistan" },
    { label: "Vanuatu" },
    { label: "Venezuela (Bolivarian Republic of)" },
    { label: "Viet Nam" },
    { label: "Virgin Islands (British)" },
    { label: "Virgin Islands (U.S.)" },
    { label: "Wallis and Futuna" },
    { label: "Western Sahara" },
    { label: "Yemen" },
    { label: "Zambia" },
    { label: "Zimbabwe" },
    { label: "Åland Islands" },
  ];

  export default countries;