import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Switch,
  Container,
  Rating,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Avatar,
  TablePagination,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import Page from "../../../utils/Page";
import chatGptIcon from "./chat-gpt.png";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinkGoogleBusinessBtn from "./LinkGoogleBusiness";
import UnLinkGoogleBusinessBtn from "./UnLinkGoogleBusiness";
import MerchantContext from "../../../config/MerchantContext";
import firebase from "../../../config/firebase";
import Lottie from "lottie-react";

import {
  fetchAccountDetails,
  exchangeAuthorizationCodeForTokens,
  refreshAccessToken,
  verifyToken,
  generateAIResponse,
} from "../../../config/cloudApis";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ai_loading from "../../../utils/animations/ai_loading.json";
import { gapi } from "gapi-script";
import googleBusinessAnim from "../../../utils/animations/googleBusiness.json";

import aiReplyBoxLoadingAnim from "../../../utils/animations/ai-reply-box-loading.json";
import GoogleIcon from "./google-icon.png";
import { Search as SearchIcon } from "react-feather";
import zIndex from "@mui/material/styles/zIndex";
import settingsImage from "../../../utils/images/settings_women.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  title: {
    userSelect: "none",
    fontSize: "2rem",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  fixedWidthContainer: {
    animation: "$fadeIn 2s",
    position: "relative",
    maxWidth: "700px",
    margin: "0 auto",
    textAlign: "center",
  },
  linkButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  unlinkButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  unlinkButton: {
    marginTop: theme.spacing(2),
  },
  card: {
    maxWidth: 300,
    width: "100%",
    margin: theme.spacing(2),
    border: "2px solid transparent",
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  selectedCard: {
    borderColor: theme.palette.primary.main,
  },
  cardBottomBorder: {
    borderBottomWidth: "4px",
    borderBottomStyle: "solid",
  },
  businessDetails: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  detailsLabel: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  reviewsTable: {
    marginTop: theme.spacing(4),
    maxWidth: "100%",
  },

  noReviewsMessage: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  linkedDetails: {
    marginBottom: theme.spacing(3),
  },
  reviewCell: {
    maxWidth: "250px",
    overflowY: "auto",
    whiteSpace: "pre-wrap",
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
  ratingStars: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  responseCell: {
    minWidth: "300px",
    position: "relative",
  },
  googleIcon: {
    width: "20px",
    height: "20px",
  },
  listItem: {
    display: "flex",
    flexDirection: "row", // Ensure row layout
    justifyContent: "space-between", // Push content to the ends
    alignItems: "center", // Vertically center align items
    width: "100%", // Ensure it takes full width
  },
  listItemText: {
    flexGrow: 1, // Allow text to take available space
    maxWidth: "85%", // Limit the maximum width to prevent overflow
    paddingRight: theme.spacing(2), // Add padding to avoid overlap with the button
  },
  listItemSecondaryAction: {
    display: "flex", // Ensure it's aligned properly
    alignItems: "center", // Vertically center the button
    width: "35%",
  },
  listItemSecondaryActionSwitch: {
    display: "flex", // Ensure it's aligned properly
    alignItems: "center", // Vertically center the button
  },
  searchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
  submitReplyBtn: {
    width: "85%", // Ensure both buttons have the same width
    backgroundColor: "#fff",
    border: "1px solid transparent",
    borderColor: `${theme.palette.secondary.main}`,
    borderRadius: "8px",
    color: "#000",
    alignItems: "center",
    marginTop: theme.spacing(2),
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  generateResponseBtn: {
    width: "85%", // Ensure both buttons have the same width
    backgroundColor: "#fff",
    border: "1px solid transparent",
    borderColor: `#8800FF`,
    borderRadius: "8px",
    color: "#000",
    alignItems: "center",
    marginTop: theme.spacing(2),
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
    animation: "$buttonGlow 2.5s infinite",
  },
  "@keyframes buttonGlow": {
    "0%": { boxShadow: "0 0 4px rgba(251, 36, 108, 0.5)" },
    "50%": { boxShadow: "0 0 6px rgba(251, 36, 108, 1)" },
    "100%": { boxShadow: "0 0 4px rgba(251, 36, 108, 0.5)" },
  },
  chatGptIcon: {
    width: 24, // Specify icon size explicitly
    height: 24,
  },
  // Responsive design adjustment
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto", // Prevents responsiveness below tablet size
    },
  },
  responseContainer: {
    position: "relative", // Set relative to allow absolute positioning of children
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
    zIndex: 10, // Ensure it is above other elements
  },
  updateTimeLabel: {
    textAlign: "right", // Align text to the right
    marginTop: theme.spacing(0.5), // Small margin above the label
    display: "block",
    color: theme.palette.text.secondary, // Secondary text color
  },
  illustration: {
    display: "block",
    margin: "20px auto",
    width: "50%", // Adjust the width as needed
    maxWidth: "300px", // Optional: limit the maximum width
    height: "auto",
  },
}));

const GoogleReviewManagementView = () => {
  const { currentMerchant, plan, isSubscribed } = useContext(MerchantContext);

  const classes = useStyles();
  const navigate = useNavigate();
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [scope, setScope] = useState("");
  const [isLinked, setIsLinked] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [googleBusinessConfig, setGoogleBusinessConfig] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [linkedLocation, setLinkedLocation] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("date");
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviewCount, setTotalReviewCount] = useState(0);
  const [reviewsError, setReviewsError] = useState(false);
  const [responseText, setResponseText] = useState({});
  const [fetchedReviewCount, setFetchedReviewCount] = useState(0);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // New state for confirmation dialog
  const [selectedReview, setSelectedReview] = useState(null); // New state to store the review being replied to
  const [reviewsLoaded, setReviewsLoaded] = useState(false); // State to track if reviews are loaded
  const [loadingAiResponse, setLoadingAiResponse] = useState(null);
  const [isAiAutomationEnabled, setIsAiAutomationEnabled] = useState(false);
  const [openAiConfirmDialog, setOpenAiConfirmDialog] = useState(false);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false); // State for upgrade dialog

  const DEV_REDIRECT_URI = "http://localhost:3000";
  const PROD_REDIRECT_URI = "https://reviewplusapp.com";

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleLoadMoreReviews = async () => {
    if (nextPageToken) {
      setIsLoadingMore(true);
      try {
        // Call fetchReviews function with the nextPageToken
        await fetchReviews(
          linkedLocation.locationId,
          googleBusinessConfig.accountId,
          nextPageToken
        );
      } catch (error) {
        console.warn("Error loading more reviews:", error);
        toast.error("Failed to load more reviews. Please try again...");
      } finally {
        setIsLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    // Check subscription and plan eligibility
    if (
      !isSubscribed ||
      (plan !== "TRAIL_01" && plan !== "STAN_01" && plan !== "PREMIUM_01")
    ) {
      setOpenUpgradeDialog(true);
    } else {
      setOpenUpgradeDialog(false);
    }
  }, [isSubscribed, plan]);

  const handleUpgradeClick = () => {
    navigate("/app/subscription");
  };

  useEffect(() => {
    // Include reviews with comments or just star ratings
    const filtered = reviews.filter(
      (review) =>
        (review.comment &&
          review.comment.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!review.comment && review.starRating) // Include reviews with only ratings
    );

    // Sort reviews based on selected sort option
    const sorted = filtered.sort((a, b) => {
      if (sortOption === "date") {
        return new Date(b.createTime) - new Date(a.createTime);
      } else if (sortOption === "rating") {
        return (
          starRatingToNumber(b.starRating) - starRatingToNumber(a.starRating)
        );
      } else if (sortOption === "ratingAsc") {
        return (
          starRatingToNumber(a.starRating) - starRatingToNumber(b.starRating)
        );
      } else if (sortOption === "dateAsc") {
        return new Date(a.createTime) - new Date(b.createTime);
      }
      return 0;
    });

    setFilteredReviews(sorted);
  }, [reviews, searchTerm, sortOption]);

  const starRatingToNumber = (rating) => {
    switch (rating) {
      case "ONE":
        return 1;
      case "TWO":
        return 2;
      case "THREE":
        return 3;
      case "FOUR":
        return 4;
      case "FIVE":
        return 5;
      default:
        return 0; // Handle 'STAR_RATING_UNSPECIFIED' and any other unspecified values
    }
  };

  let colorIndex = 0;

  const getRandomBottomBorderColor = () => {
    const colors = ["#B5EAEA", "#FF5733", "#33FF57", "#FFBCBC", "#3357FF"];
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length;
    return color;
  };

  // Function to handle AI response generation
  const handleGenerateResponseClick = async (review) => {
    setLoadingAiResponse(review.reviewId);
    try {
      const businessType = linkedLocation?.type || "local business";
      const customerName = review.reviewer?.displayName || "";
      const reviewText = review.comment || ""; // Default to empty string if no comment
      const rating = review.starRating; // Convert enum to number

      const aiResponse = await generateAIResponse(
        reviewText,
        rating,
        businessType,
        customerName
      );

      setResponseText((prevState) => ({
        ...prevState,
        [review.reviewId]: aiResponse,
      }));

      toast("AI Response generated successfully!");
    } catch (error) {
      console.error("Failed to generate AI response:", error);
      toast.error("Failed to generate AI response. Please try again.");
    } finally {
      setLoadingAiResponse(null);
    }
  };

  const updateAiAutomationSetting = async (isEnabled) => {
    setIsAiAutomationEnabled(isEnabled);

    try {
      const googleBusinessData = {
        isAiEnabled: isEnabled,
      };

      await firebase.saveGoogleBusinessInfo(
        currentMerchant.id,
        googleBusinessData
      );

      toast(`AI Automation is now ${isEnabled ? "enabled" : "disabled"}.`);
    } catch (error) {
      console.error("Error updating AI automation setting:", error);
      toast.error("Failed to update AI automation setting. Please try again.");
    }
  };

  const handleConfirmDialogClose = () => {
    setOpenAiConfirmDialog(false);
  };

  const handleConfirmEnable = () => {
    updateAiAutomationSetting(true);
    setOpenAiConfirmDialog(false);
  };

  const handleSwitchChange = (checked) => {
    if (checked) {
      setOpenAiConfirmDialog(true);
    } else {
      updateAiAutomationSetting(false); // Directly disable without confirmation
    }
  };

  // Handle successful linking of account
  const handleLinkSuccess = async (res) => {
    const authorizationCode = res.code;
    try {
      const tokenResponse = await exchangeAuthorizationCodeForTokens(
        authorizationCode,
        clientId,
        clientSecret,
        PROD_REDIRECT_URI // Use appropriate redirect URI
      );

      // Store tokens and account details
      await firebase.storeGoogleTokens(currentMerchant.id, {
        accessToken: tokenResponse.access_token,
        refreshToken: tokenResponse.refresh_token,
        expiresIn: tokenResponse.expires_in,
        idToken: tokenResponse.id_token,
        lastUpdated: new Date().toISOString(),
        tokenType: tokenResponse.token_type,
      });

      setAccessToken(tokenResponse.access_token);
      const fetchedAccountId = await fetchAccountDetails(
        tokenResponse.access_token
      );

      if (fetchedAccountId) {
        await firebase.saveGoogleAccountId(
          currentMerchant.id,
          fetchedAccountId
        );
        setAccountId(fetchedAccountId);
        setIsLinked(true); // Set isLinked to true to indicate the profile is linked
        await fetchLocations(fetchedAccountId, tokenResponse.access_token); // Fetch locations immediately
      } else {
        toast.error(
          "No Google My Business profiles found...Try with a different profile."
        );
        setIsLinked(false);
      }
      //window.location.reload();
    } catch (error) {
      console.warn("Failed to link Google My Business:", error);
      toast.error("Failed to link Google My Business. Please try again...");
      setIsLinked(false);
      // window.location.reload();
    }
  };

  // Fetch locations based on the accountId
  const fetchLocations = async (googleAccountId, accessToken) => {
    try {
      // Ensure the access token is valid
      await ensureAccessTokenValid();

      const headers = new Headers();
      headers.append("Authorization", `Bearer ${accessToken}`);

      const response = await fetch(
        `https://mybusinessbusinessinformation.googleapis.com/v1/${googleAccountId}/locations?read_mask=name,title,phoneNumbers,categories,websiteUri,latlng,metadata,profile,storefrontAddress,regularHours,serviceItems`,
        { headers }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.locations) {
        setLocations(data.locations);
      } else {
        toast.error("No locations found for this account.");
        setIsLinked(false);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      toast.error("Failed to fetch locations. Please try again...");
    }
  };

  const ensureAccessTokenValid = async () => {
    if (googleBusinessConfig) {
      await checkForTokenValidation(googleBusinessConfig);
    }
  };

  const handleCardClick = (location) => {
    setSelectedLocation(location);
    setOpenDialog(true);
  };

  const handleConfirmLink = async () => {
    setOpenDialog(false);

    const locationDetails = {
      title: selectedLocation.title,
      description: selectedLocation.profile?.description,
      phone: selectedLocation.phoneNumbers?.primaryPhone,
      website: selectedLocation.websiteUri,
      locationId: selectedLocation.name,
      type: selectedLocation.categories?.primaryCategory?.displayName,
    };

    setLinkedLocation(locationDetails);

    try {
      await firebase.saveGoogleBusinessInfo(currentMerchant.id, {
        locationDetails,
        isAiEnabled: false,
      });
      toast.success(`${selectedLocation.title} is linked successfully!`);
      setReviewsLoaded(false);
      await fetchReviews(selectedLocation.name, googleBusinessConfig.accountId);
    } catch (error) {
      console.warn("Error saving selected location details:", error);
      toast.error(
        "Failed to save business location details. Please try again later..."
      );
      setIsLinked(false);
    }
  };

  // Fetch reviews for the linked location
  const fetchReviews = async (locationName, accountId, pageToken = null) => {
    try {
      // Ensure the access token is valid
      await ensureAccessTokenValid();

      const headers = new Headers();
      headers.append(
        "Authorization",
        `Bearer ${googleBusinessConfig.accessToken}`
      );

      const params = new URLSearchParams();
      if (pageToken) params.append("pageToken", pageToken);

      const response = await fetch(
        `https://mybusiness.googleapis.com/v4/${accountId}/${locationName}/reviews?${params.toString()}`,
        { headers }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reviewsData = await response.json();
      if (reviewsData && reviewsData.reviews) {
        setReviews((prevReviews) => {
          const existingReviewsMap = new Map(
            prevReviews.map((review) => [review.reviewId, review])
          );

          // Filter out any reviews that already exist
          const newReviews = reviewsData.reviews.filter(
            (review) => !existingReviewsMap.has(review.reviewId)
          );

          // Merge the previous reviews with the new unique reviews
          return [...prevReviews, ...newReviews];
        });

        setFetchedReviewCount(
          (prevCount) => prevCount + reviewsData.reviews.length
        );
        setTotalReviewCount(reviewsData.totalReviewCount || 0);
        setAverageRating(reviewsData.averageRating || 0);
        setNextPageToken(reviewsData.nextPageToken || null);
        setReviewsLoaded(true); // Indicate that reviews have been loaded
      } else {
        setReviewsError(true);
      }
    } catch (error) {
      console.warn("Error fetching reviews:", error);
      setReviewsError(true);
      toast.error("Failed to fetch reviews for the selected location.");
    }
  };

  const loadMoreReviews = async () => {
    if (nextPageToken) {
      setIsLoadingMore(true);
      await fetchReviews(
        linkedLocation.locationId,
        googleBusinessConfig.accountId,
        nextPageToken
      );
      setIsLoadingMore(false);
    }
  };

  const handleUnlink = async () => {
    try {
      await firebase.deleteGoogleBusinessConfig(currentMerchant.id);
      setIsLinked(false);
      setAccessToken("");
      fetchGoogleConsoleDetails();
      window.location.reload();
    } catch (error) {
      console.warn("Error unlinking Google Business account:", error);
      toast.error("Failed to unlink Google Business account.");
      setIsLinked(false);
    }
  };

  const fetchGoogleConsoleDetails = async () => {
    try {
      const details = await firebase.getGoogleConsoleDetails();
      setClientId(details.clientId);
      setClientSecret(details.clientSecret);
      setScope(details.scope);

      const googleBusinessConfig = await firebase.getGoogleBusinessInfo(
        currentMerchant.id
      );
      setGoogleBusinessConfig(googleBusinessConfig);
    } catch (error) {
      console.error("Error fetching Google Console details:", error);
    }
  };

  //   const initGapiClient = async () => {
  //     try {
  //       //console.log("Initializing GAPI client...");
  //       await new Promise((resolve, reject) => {
  //         gapi.load("client:auth2", () => {
  //           //  console.log("GAPI client loaded...");
  //           gapi.client
  //             .init({
  //               clientId: clientId,
  //               scope: scope,
  //             })
  //             .then(() => {
  //               //  console.log("GAPI client initialized...");
  //               resolve();
  //             })
  //             .catch((error) => {
  //               console.error("Error initializing GAPI client:", error);
  //               reject(error);
  //             });
  //         });
  //       });
  //     } catch (error) {
  //       console.warn("Failed to initialize Gapi client:", error);
  //     }
  //   };

  const checkForTokenValidation = async (googleBusinessConfig) => {
    if (googleBusinessConfig) {
      const { accessToken, refreshToken, expirationTime } =
        googleBusinessConfig;
      const isTokenExpired = expirationTime
        ? new Date().getTime() > new Date(expirationTime).getTime()
        : true;

      if (isTokenExpired) {
        const refreshed = await refreshAndStoreNewAccessToken(
          googleBusinessConfig
        );
        if (refreshed && googleBusinessConfig.locationDetails) {
          setLinkedLocation(googleBusinessConfig.locationDetails);
          setIsLinked(true);
        } else {
          toast("Google Profile is disconnected...Please reconnect...");
          setIsLinked(false);
        }
      } else {
        const tokenInfo = await verifyToken(accessToken);
        if (tokenInfo && tokenInfo.expires_in > 0) {
          setIsLinked(true);
          if (googleBusinessConfig.locationDetails) {
            setLinkedLocation(googleBusinessConfig.locationDetails);
          }
        } else {
          const refreshed = await refreshAndStoreNewAccessToken(
            googleBusinessConfig
          );
          if (refreshed && googleBusinessConfig.locationDetails) {
            setLinkedLocation(googleBusinessConfig.locationDetails);
            setIsLinked(true);
          } else {
            toast("Google Profile is disconnected...Please reconnect...");
            setIsLinked(false);
          }
        }
      }
    } else {
      toast("Google Profile is disconnected...Please reconnect");
      setIsLinked(false);
    }
  };

  const refreshAndStoreNewAccessToken = async (googleBusinessConfig) => {
    const newTokens = await refreshAccessToken(
      googleBusinessConfig.refreshToken,
      clientId,
      clientSecret
    );

    if (newTokens) {
      googleBusinessConfig.accessToken = newTokens.access_token;
      googleBusinessConfig.expiresIn = newTokens.expires_in;

      if (newTokens.id_token) {
        googleBusinessConfig.idToken = newTokens.id_token;
      }

      await firebase.storeGoogleTokens(
        currentMerchant.id,
        googleBusinessConfig
      );

      setAccessToken(newTokens.access_token);
      setGoogleBusinessConfig(googleBusinessConfig);
      return true;
    } else {
      console.error("Failed to refresh access token.");
      return false;
    }
  };

  useEffect(() => {
    if (currentMerchant && currentMerchant.id) {
      fetchGoogleConsoleDetails();
    }
  }, [currentMerchant]);

  // Initialize GAPI client and fetch locations if Google Business config is available
  useEffect(() => {
    const initialize = async () => {
      if (clientId && scope && googleBusinessConfig) {
        //await initGapiClient();
        await checkForTokenValidation(googleBusinessConfig);
        if (googleBusinessConfig.accountId) {
          //console.log("Locations token " + googleBusinessConfig.accessToken);
          fetchLocations(
            googleBusinessConfig.accountId,
            googleBusinessConfig.accessToken
          );
          setIsAiAutomationEnabled(googleBusinessConfig.isAiEnabled);
        }
      }
    };
    initialize();
  }, [clientId, scope, googleBusinessConfig]);

  useEffect(() => {
    if (
      linkedLocation &&
      googleBusinessConfig &&
      googleBusinessConfig.accountId
    ) {
      setIsLinked(true);
      fetchReviews(linkedLocation.locationId, googleBusinessConfig.accountId);
    }
  }, [linkedLocation, googleBusinessConfig]);

  // Update handleSubmitReplyClick to open confirmation dialog
  const handleSubmitReplyClick = (review) => {
    setSelectedReview(review);
    setConfirmDialogOpen(true);
  };

  // Confirm and submit the reply
  const confirmAndSubmitReply = async () => {
    if (!selectedReview) return;

    try {
      // Ensure the access token is valid
      await ensureAccessTokenValid();

      const headers = new Headers();
      headers.append(
        "Authorization",
        `Bearer ${googleBusinessConfig.accessToken}`
      );
      headers.append("Content-Type", "application/json");

      const response = await fetch(
        `https://mybusiness.googleapis.com/v4/${selectedReview.name}/reply`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify({
            comment: responseText[selectedReview.reviewId],
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      toast.success("Reply submitted successfully.");

      // Update the review state to reflect the change
      //   setReviews((prevReviews) =>
      //     prevReviews.map((r) =>
      //       r.reviewId === selectedReview.reviewId
      //         ? { ...r, reviewReply: responseData }
      //         : r
      //     )
      //   );
      await fetchReviews(
        linkedLocation.locationId,
        googleBusinessConfig.accountId
      );
    } catch (error) {
      console.error("Failed to submit the reply:", error);
      toast.error("Failed to submit the reply.");
    } finally {
      setConfirmDialogOpen(false);
      setSelectedReview(null);
    }
  };

  // Handle page change for the reviews table
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    if (newPage * rowsPerPage >= fetchedReviewCount && nextPageToken) {
      await loadMoreReviews();
    }
  };

  // Handle rows per page change for the reviews table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title="Manage Google Reviews">
      <Box sx={{ flexGrow: 1, p: 3 }} className={classes.root}>
        {/* Show Upgrade Dialog if user is not subscribed or not on eligible plan */}

        <Dialog
          open={openUpgradeDialog}
          onClose={() => {}}
          aria-labelledby="upgrade-dialog-title"
          aria-describedby="upgrade-dialog-description"
          disableEscapeKeyDown
        >
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Subscription Required! 😣
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="upgrade-dialog-description">
              To access Google Review Management, please upgrade to the Standard
              or Premium plan.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              onClick={handleUpgradeClick}
              color="secondary"
              variant="contained"
            >
              Subscribe Now
            </Button>
          </DialogActions>
        </Dialog>

        <div>
          {clientId && (
            <GoogleOAuthProvider clientId={clientId}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box className={classes.titleContainer}>
                    <Box display="flex" alignItems="center">
                      <Lottie
                        animationData={googleBusinessAnim}
                        style={{
                          height: "80%",
                          width: "10%",
                          marginRight: 2,
                        }}
                      />
                      <Typography variant="h4" className={classes.title}>
                        Google Reviews Automation 🚀
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      className={classes.titleDescription}
                    >
                      Manage and Automate Google Reviews with the help of AI.
                      Enable switch to automate the handling of reviews for your
                      business. You can link your google account which belongs
                      to your business. You can respond to reviews, monitor
                      feedback, and gain insights to improve your business. Once
                      linked, you can setup to automate AI based replies or
                      control manually.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={settingsImage}
                    alt="media"
                    className={classes.illustration}
                  />

                  <Container maxWidth="lg">
                    {/* First List */}
                    <List>
                      {isLinked ? (
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            primary={
                              <Box display="flex" alignItems="center">
                                <img
                                  src={GoogleIcon}
                                  alt="Google logo"
                                  className={classes.googleIcon}
                                />
                                <Typography
                                  variant="body1"
                                  style={{ marginLeft: 8 }}
                                >
                                  Google Business Profile
                                </Typography>
                              </Box>
                            }
                            secondary="Change Google Business Profile or Disconnect now?"
                            className={classes.listItemText}
                          />
                          <ListItemSecondaryAction
                            className={classes.listItemSecondaryAction}
                          >
                            <IconButton edge="end" aria-label="unlink">
                              <UnLinkGoogleBusinessBtn
                                onUnlinkSuccess={handleUnlink}
                                clientId={clientId}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.listItem}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <ListItemText
                              primary={
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={GoogleIcon}
                                    alt="Google logo"
                                    className={classes.googleIcon}
                                  />
                                  <Typography
                                    variant="body1"
                                    style={{ marginLeft: 4 }}
                                  >
                                    Link Your Google Business Account
                                  </Typography>
                                </Box>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  style={{
                                    wordWrap: "break-word",
                                    maxWidth: "calc(100% - 50px)", // Prevent overlap with the button
                                  }}
                                >
                                  Manage and Automate Google Business Profile
                                </Typography>
                              }
                              className={classes.listItemText}
                            />
                            <ListItemSecondaryAction
                              className={classes.listItemSecondaryAction}
                            >
                              <IconButton edge="end" aria-label="link">
                                <LinkGoogleBusinessBtn
                                  onSuccess={handleLinkSuccess}
                                  clientId={clientId}
                                />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </Box>
                        </ListItem>
                      )}
                    </List>

                    {/* Second List */}
                    {linkedLocation && (
                      <Grid container>
                        <List>
                          <ListItem className={classes.listItem}>
                            <ListItemText
                              primary="🤖 Enable AI Automation for Google Reviews"
                              secondary="Use AI to analyze the sentiment of your Customer Reviews and Respond accordingly. The responses to the reviews will automatically be scheduled after one day."
                              className={classes.listItemText}
                            />
                            <ListItemSecondaryAction
                              className={classes.listItemSecondaryActionSwitch}
                            >
                              <Switch
                                checked={isAiAutomationEnabled}
                                onChange={(e) =>
                                  handleSwitchChange(e.target.checked)
                                }
                                color="primary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>

                        {/* Business Details Card */}
                        <Card className={classes.businessDetails}>
                          <CardContent>
                            <Typography
                              variant="h5"
                              className={classes.detailsLabel}
                            >
                              Business Details
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.detailsLabel}
                            >
                              Name:
                            </Typography>
                            <Typography variant="body1">
                              {linkedLocation.title}
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.detailsLabel}
                            >
                              Description:
                            </Typography>
                            <Typography variant="body2">
                              {linkedLocation.description}
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.detailsLabel}
                            >
                              Phone:
                            </Typography>
                            <Typography variant="body2">
                              {linkedLocation.phone}
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.detailsLabel}
                            >
                              Website:
                            </Typography>
                            <Typography variant="body2">
                              <a
                                href={linkedLocation.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {linkedLocation.website}
                              </a>
                            </Typography>

                            <Typography
                              variant="h6"
                              className={classes.detailsLabel}
                            >
                              Average Rating:
                            </Typography>
                            <Rating value={averageRating} readOnly />

                            <Typography
                              variant="h6"
                              className={classes.detailsLabel}
                            >
                              Total Reviews:
                            </Typography>
                            <Typography variant="body2">
                              {totalReviewCount}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </Container>
                  {!linkedLocation && locations.length > 0 && (
                    <Grid container>
                      <Typography variant="h6" className={classes.title} mt={3}>
                        Available Locations
                      </Typography>
                      <Grid container mt={1}>
                        <Typography>
                          Select the location that you want to manage and handle
                          Google reviews. You can configure to automate your
                          responses for your google reviews.
                        </Typography>
                      </Grid>
                      <Grid container>
                        {locations.map((location) => (
                          <Card
                            key={location.name}
                            className={`${classes.card} ${
                              selectedLocation === location &&
                              classes.selectedCard
                            } ${classes.cardBottomBorder}`}
                            style={{
                              borderBottomColor: getRandomBottomBorderColor(),
                            }}
                          >
                            <CardActionArea
                              onClick={() => handleCardClick(location)}
                            >
                              <CardContent>
                                <Typography variant="h6">
                                  {location.title}
                                </Typography>
                                {location.categories &&
                                  location.categories.primaryCategory && (
                                    <Typography variant="body2">
                                      {
                                        location.categories.primaryCategory
                                          .displayName
                                      }
                                    </Typography>
                                  )}
                                {location.websiteUri && (
                                  <Typography variant="body2">
                                    <a
                                      href={location.websiteUri}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {location.websiteUri}
                                    </a>
                                  </Typography>
                                )}
                                {location.phoneNumbers && (
                                  <Typography variant="body2">
                                    Phone: {location.phoneNumbers.primaryPhone}
                                  </Typography>
                                )}
                                {location.storefrontAddress && (
                                  <Typography variant="body2">
                                    Address:{" "}
                                    {location.storefrontAddress.addressLines.join(
                                      ", "
                                    )}
                                  </Typography>
                                )}
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {linkedLocation && (
                <>
                  <Box className={classes.searchContainer}>
                    <TextField
                      className={classes.searchInput}
                      placeholder="Search reviews"
                      variant="outlined"
                      size="small"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Select
                      value={sortOption}
                      onChange={handleSortChange}
                      variant="outlined"
                      size="small"
                      style={{ marginLeft: 10 }}
                    >
                      <MenuItem value="date">Latest Reviews</MenuItem>
                      <MenuItem value="dateAsc">Old Reviews</MenuItem>
                      <MenuItem value="rating">Ratings High to Low</MenuItem>
                      <MenuItem value="ratingAsc">Ratings Low to High</MenuItem>
                    </Select>
                  </Box>
                  <Typography variant="h6" sx={{ mt: 4 }}>
                    Your Google Reviews
                  </Typography>
                  {filteredReviews.length > 0 ? (
                    <TableContainer
                      component={Paper}
                      className={`${classes.reviewsTable} ${classes.tableContainer}`}
                      sx={{ mt: 3 }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Reviewer</TableCell>
                            <TableCell>Review</TableCell>
                            <TableCell className={classes.responseCell}>
                              Response
                            </TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredReviews
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((review) => (
                              <TableRow
                                key={`${review.reviewId}-${review.createTime}`} // Ensure the key is unique
                              >
                                <TableCell>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                  >
                                    {review.reviewer.profilePhotoUrl ? (
                                      <Avatar
                                        alt={review.reviewer.displayName}
                                        src={review.reviewer.profilePhotoUrl}
                                        className={classes.avatar}
                                      />
                                    ) : (
                                      <Avatar
                                        alt={review.reviewer.displayName}
                                        className={classes.avatar}
                                      >
                                        {review.reviewer.displayName.charAt(0)}
                                      </Avatar>
                                    )}
                                    <Typography variant="body2">
                                      {review.reviewer.displayName}
                                    </Typography>
                                    <Typography variant="caption">
                                      {new Date(
                                        review.createTime
                                      ).toLocaleDateString()}
                                    </Typography>
                                    <Rating
                                      value={starRatingToNumber(
                                        review.starRating
                                      )}
                                      readOnly
                                    />
                                  </Box>
                                </TableCell>
                                <TableCell className={classes.reviewCell}>
                                  {review.comment ||
                                    `This customer left a rating of ${starRatingToNumber(
                                      review.starRating
                                    )} stars.`}
                                  {review.updateTime && (
                                    <Typography
                                      variant="caption"
                                      className={classes.updateTimeLabel}
                                    >
                                      Last updated:{" "}
                                      {new Date(
                                        review.updateTime
                                      ).toLocaleString()}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell className={classes.responseCell}>
                                  <div className={classes.responseContainer}>
                                    <TextField
                                      fullWidth
                                      placeholder="Enter your response"
                                      variant="outlined"
                                      multiline
                                      rows={6}
                                      value={
                                        responseText[review.reviewId] ||
                                        (review.reviewReply
                                          ? review.reviewReply.comment
                                          : "")
                                      }
                                      onChange={(e) =>
                                        setResponseText({
                                          ...responseText,
                                          [review.reviewId]: e.target.value,
                                        })
                                      }
                                    />
                                    {loadingAiResponse === review.reviewId && (
                                      <div className={classes.loadingOverlay}>
                                        <Lottie
                                          animationData={aiReplyBoxLoadingAnim}
                                          style={{ height: 80, width: 80 }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Button
                                      className={classes.submitReplyBtn}
                                      variant="contained"
                                      style={{ width: "85%" }}
                                      color="primary"
                                      onClick={() =>
                                        handleSubmitReplyClick(
                                          review,
                                          responseText[review.reviewId]
                                        )
                                      }
                                    >
                                      💭 Submit Reply
                                    </Button>
                                    {loadingAiResponse === review.reviewId ? (
                                      <Lottie
                                        animationData={ai_loading}
                                        style={{ height: 50, width: 50 }}
                                      />
                                    ) : (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.generateResponseBtn}
                                        startIcon={
                                          <img
                                            src={chatGptIcon}
                                            alt="ChatGPT AI Icon"
                                            className={classes.chatGptIcon}
                                          />
                                        }
                                        onClick={() =>
                                          handleGenerateResponseClick(review)
                                        }
                                      >
                                        AI Reply
                                      </Button>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalReviewCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      {nextPageToken &&
                        fetchedReviewCount < totalReviewCount && (
                          <Box display="flex" justifyContent="center" my={2}>
                            <Button
                              hidden
                              onClick={handleLoadMoreReviews}
                              variant="contained" // Changed to "contained" for a more prominent look
                              color="primary" // Changed to primary for a bolder color
                              disabled
                              startIcon={
                                isLoadingMore ? (
                                  <CircularProgress size={20} color="inherit" /> // Loading spinner icon
                                ) : (
                                  <span role="img" aria-label="down-arrow">
                                    ⬇️
                                  </span>
                                )
                              }
                            >
                              {isLoadingMore ? "Loading..." : "More Reviews"}
                            </Button>
                          </Box>
                        )}
                    </TableContainer>
                  ) : (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      No reviews are available for this location. Please check
                      back later.
                    </Typography>
                  )}
                </>
              )}
              <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
              >
                <DialogTitle id="confirm-dialog-title">
                  Confirm Google Business Profile
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-dialog-description">
                    Are you sure you want to link the business:{" "}
                    {selectedLocation?.title}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenDialog(false)}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmLink}
                    variant="contained"
                    color="secondary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Confirmation Dialog for Submitting Reply */}
              <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                aria-labelledby="confirm-submit-dialog-title"
                aria-describedby="confirm-submit-dialog-description"
              >
                <DialogTitle id="confirm-submit-dialog-title">
                  Reply Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-submit-dialog-description">
                    Are you sure you want to submit this reply?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setConfirmDialogOpen(false)}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={confirmAndSubmitReply}
                    variant="contained"
                    color="secondary"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openAiConfirmDialog}
                onClose={handleConfirmDialogClose}
              >
                <DialogTitle>Enable AI Automation</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Do you want to enable the use of AI to analyze the sentiment
                    of your Customer Reviews and respond accordingly?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleConfirmDialogClose} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmEnable}
                    color="secondary"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              <ToastContainer />
            </GoogleOAuthProvider>
          )}
        </div>
      </Box>
    </Page>
  );
};

export default GoogleReviewManagementView;
