import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  Select,
  Autocomplete,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Save, ArrowBack, Send, SendOutlined } from "@mui/icons-material";
import { sendRegistrationEmail } from "../../config/cloudApis";
import firebase from "../../config/firebase";
import Page from "../../utils/Page";
import SearchLocation from "../../components/Maps/SearchLocation";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import storage from "../../config/firebaseStorage";
import RefreshIcon from "@mui/icons-material/Refresh";
import Logo from "../../icons/logo.png";
import { User } from "react-feather";

import {
  Link,
  Business,
  BusinessOutlined,
  LocationSearchingOutlined,
} from "@mui/icons-material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defaultBusinessTypes } from "../../utils/BusinessTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,

    minHeight: "100vh",
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginTp: {
    marginTop: theme.spacing(2),
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  select: {
    borderRadius: 25,
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:focus": {
      borderRadius: 25,
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "300px",
    },
  },
  menuItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  logoContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  logo: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    boxShadow: "0px 0px 15px 5px rgba(255,255,255,0.7)",
  },
  title: {
    userSelect: "none",

    color: theme.palette.text.primary, // Usually black or dark grey
  },
  titleDescription: {
    userSelect: "none",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
  },
  backButton: {
    fontSize: "1rem",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

const AdminMerchantEditComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { merchant } = location.state || {};
  const [loading, setLoading] = useState(false);

  const [fieldValues, setFieldValues] = useState({
    merchantID: "",
    businessName: "",
    email: "",
    businessAddress: "",
    googlePlaceID: "",
    googleReviewUrl: "",
    businessLogoUrl: "",
    website: "",
    initGoogleRating: 0.0,
    initGoogleReviews: 0,
    lati: "",
    longi: "",
    businessType: "",
    ownerEmail: "",
    ownerPhone: "",
    urls: {
      facebook: "",
      instagram: "",
      tiktok: "",
      tripadvisor: "",
      yellowpages: "",
      yelp: "",
    },
    settings: {
      accessPin: "",
      collectCustDetails: true,
      fullCustDetails: false,
      defaultLanguage: "en",
    },
  });

  const [isEmailConfirmationOpen, setIsEmailConfirmationOpen] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [imageAsUrl, setImageAsUrl] = useState("");

  useEffect(() => {
    if (merchant) {
      //console.log("Merchant Data:", merchant);

      setFieldValues({
        merchantID: merchant.id || "",
        businessName: merchant.businessName || "",
        email: merchant.email || "",
        businessAddress: merchant.businessAddress || "",
        googlePlaceID: merchant.googlePlaceID || "",
        googleReviewUrl: merchant.googleReviewUrl || "",
        businessType: merchant.businessType || "Not Available",
        businessLogoUrl: merchant.businessLogoUrl || "",
        website: merchant.website || "",
        ownerName: merchant.ownerName || "",
        ownerEmail: merchant.ownerEmail || "",
        ownerPhone: merchant.ownerPhone || "",
        initGoogleRating: merchant.initGoogleRating || 0.0,
        initGoogleReviews: merchant.initGoogleReviews || 0,

        urls: {
          facebook: merchant.urls?.facebook || "",
          instagram: merchant.urls?.instagram || "",
          tiktok: merchant.urls?.tiktok || "",
          tripadvisor: merchant.urls?.tripadvisor || "",
          yellowpages: merchant.urls?.yellowpages || "",
          yelp: merchant.urls?.yelp || "",
        },
        settings: {
          accessPin: merchant.settings?.accessPin || "",
          defaultLanguage: merchant.settings?.defaultLanguage || "en",
          collectCustDetails: merchant.settings?.collectCustDetails || false,
          fullCustDetails: merchant.settings?.fullCustDetails || false,
        },
        lati: merchant.location[0],
        longi: merchant.location[1],
      });
      setImageAsUrl(merchant.businessLogoUrl);
    }
  }, [merchant]);

  useEffect(() => {
    if (mapData) {
      const {
        name,
        formatted_address,
        place_id,
        geometry,
        website,
        rating,
        user_ratings_total,
      } = mapData;
      const lati = geometry?.location?.lat() ?? "";
      const longi = geometry?.location?.lng() ?? "";
      const googleReviewUrl = createReviewURL(place_id);

      setFieldValues((prevState) => ({
        ...prevState,
        businessName: name,
        businessAddress: formatted_address,
        googlePlaceID: place_id,
        lati: lati,
        longi: longi,
        website: website ?? "Not Available",
        googleReviewUrl: googleReviewUrl,
        initGoogleRating: rating,
        initGoogleReviews: user_ratings_total,
      }));
    }
  }, [mapData]);

  function createReviewURL(data) {
    var baseURL = "https://search.google.com/local/writereview?placeid=";
    return baseURL + data;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      [
        "facebook",
        "instagram",
        "tiktok",
        "yellowpages",
        "tripadvisor",
        "yelp",
      ].includes(name)
    ) {
      if (["facebook", "instagram", "tiktok"].includes(name)) {
        const url = constructSocialMediaUrl(name, value);
        setFieldValues((prevState) => ({
          ...prevState,
          urls: {
            ...prevState.urls,
            [name]: url,
          },
        }));
      } else {
        setFieldValues((prevState) => ({
          ...prevState,
          urls: {
            ...prevState.urls,
            [name]: value,
          },
        }));
      }
    } else {
      if (name === "ownerPhone") {
        value = formatPhoneNumber(value);
      }

      setFieldValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return !match[2]
        ? match[1]
        : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ""}`;
    }
    return value;
  };

  const constructSocialMediaUrl = (platform, accountName) => {
    const trimmedAccountName = accountName.trim();
    if (!trimmedAccountName) return "";

    if (
      trimmedAccountName.startsWith(`https://www.${platform}.com/`) ||
      (platform === "tiktok" &&
        trimmedAccountName.startsWith("https://www.tiktok.com/@"))
    ) {
      return trimmedAccountName;
    }

    switch (platform) {
      case "facebook":
        return `https://www.facebook.com/${trimmedAccountName}`;
      case "instagram":
        return `https://www.instagram.com/${trimmedAccountName}`;
      case "tiktok":
        return `https://www.tiktok.com/@${trimmedAccountName}`;
      default:
        return "";
    }
  };

  const handleSaveChanges = async () => {
    try {
      await firebase.updateMerchantBusinessSettings(fieldValues);
      toast.success("Merchant settings updated successfully");

      // window.location.reload();
    } catch (error) {
      toast.error("Failed to update merchant settings");

      console.error("Error updating merchant settings: ", error);
    }
  };

  const handleRefreshGooglePlaceId = () => {
    console.log("Refreshing Google Place ID...");
  };

  const handleLogoAsFile = (e) => {
    const image = e.target.files[0];
    const uploadTask = storage
      .ref(`/businessLogos/${fieldValues.merchantID}`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref("businessLogos")
          .child(fieldValues.merchantID)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setImageAsUrl(fireBaseUrl);
            setFieldValues((prevState) => ({
              ...prevState,
              businessLogoUrl: fireBaseUrl,
            }));
          });
      }
    );
  };

  const handleAccessPinChange = (event) => {
    setFieldValues((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        accessPin: event.target.value,
      },
    }));
  };

  const handleSendRegistrationEmail = () => {
    setIsEmailConfirmationOpen(true);
  };

  const confirmSendRegistrationEmail = async () => {
    setLoading(true);
    try {
      const result = await sendRegistrationEmail(fieldValues);
      if (result) {
        toast.success("Registration email sent successfully.");
      } else {
        toast.success("Failed to send registration email.");
      }
      setIsEmailConfirmationOpen(false);
    } catch (error) {
      toast.error("Error sending registration email.");
      console.error("Error sending registration email: ", error);
    } finally {
      setLoading(false); // Set loading to false when process ends
      setIsEmailConfirmationOpen(false);
    }
  };

  return (
    <Page className={classes.root} title="Edit Merchant">
      <Container maxWidth="md">
        <IconButton className={classes.backButton} onClick={() => navigate(-1)}>
          <ArrowBack /> Back
        </IconButton>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" className={classes.title}>
            Edit Merchant Settings
          </Typography>

          <Container maxWidth="md" sx={{ mt: 2 }}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Typography variant="h4" gutterBottom>
                {fieldValues.businessName}
              </Typography>

              <div className={classes.logoContainer}>
                {imageAsUrl !== "" ? (
                  <img
                    src={imageAsUrl}
                    alt="Business Logo"
                    className={classes.logo}
                  />
                ) : (
                  <img
                    src={Logo}
                    alt="Business Logo"
                    className={classes.logo}
                  />
                )}
              </div>
              <Button
                className={classes.marginBtm}
                variant="contained"
                color="primary"
                component="label"
                startIcon={<PhotoCamera />}
              >
                Upload Business Logo
                <input
                  type="file"
                  id="businessLogo"
                  name="businessLogo"
                  onChange={handleLogoAsFile}
                  hidden
                />
              </Button>
            </Box>

            <TextField
              fullWidth
              label="Merchant ID"
              margin="normal"
              name="merchantID"
              type="text"
              value={fieldValues.merchantID}
              variant="outlined"
              disabled
            />

            <Box display="flex" alignItems="center" mt={1}>
              <TextField
                fullWidth
                label="Business Place ID"
                margin="normal"
                name="googlePlaceId"
                type="text"
                value={fieldValues.googlePlaceID}
                variant="outlined"
                disabled
              />
              <IconButton
                color="primary"
                onClick={handleRefreshGooglePlaceId}
                aria-label="refresh"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
            <FormHelperText>
              Merhcants Business Place ID. This will auto change every year. If
              the google business page is not opening, click on refresh button
              to get latest place ID.
            </FormHelperText>

            <Autocomplete
              className={classes.marginTp}
              fullWidth
              margin="normal"
              name="businessType"
              variant="outlined"
              onSelect={handleChange}
              options={defaultBusinessTypes}
              renderInput={(params) => (
                <TextField
                  className={classes.inputField}
                  name="businessType"
                  id="businessType"
                  onChange={handleChange}
                  value={fieldValues.businessType}
                  {...params}
                  label="Select your Business Type"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Business />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Owner Name"
              margin="normal"
              name="ownerName"
              type="text"
              placeholder="Business Owner Name"
              value={fieldValues.ownerName}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <User />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Owner Phone"
              margin="normal"
              name="ownerPhone"
              type="text"
              placeholder="Business Owner Phone"
              value={fieldValues.ownerPhone}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Owner Email"
              margin="normal"
              name="ownerEmail"
              type="email"
              placeholder="Business Owner Email"
              value={fieldValues.ownerEmail}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Business Name"
              margin="normal"
              name="businessName"
              type="text"
              value={fieldValues.businessName}
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlined />
                  </InputAdornment>
                ),
              }}
            />

            <SearchLocation
              lati={fieldValues.lati || 0}
              longi={fieldValues.longi || 0}
              passChildData={setMapData}
            />

            <TextField
              fullWidth
              label="Business Address"
              margin="normal"
              name="businessAddress"
              type="text"
              value={fieldValues.businessAddress}
              variant="outlined"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationSearchingOutlined />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Website"
              margin="normal"
              name="website"
              type="text"
              placeholder="Enter your website url"
              value={fieldValues.website}
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />

            {/* Social Media */}
            <Box mt={2}>
              <Typography variant="h5">Social Media Profiles</Typography>
              <FormHelperText>
                Setup Merchant's social media pages links here. These links can
                be used in promotions/coupons.
              </FormHelperText>
              <TextField
                fullWidth
                label="Facebook"
                margin="normal"
                name="facebook"
                value={fieldValues.urls.facebook}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Instagram"
                margin="normal"
                name="instagram"
                value={fieldValues.urls.instagram}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="TikTok Account Name"
                margin="normal"
                name="tiktok"
                type="text"
                value={fieldValues.urls.tiktok}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Yellow Pages URL"
                margin="normal"
                name="yellowpages"
                type="text"
                value={fieldValues.urls.yellowpages}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="TripAdvisor URL"
                margin="normal"
                name="tripadvisor"
                type="text"
                value={fieldValues.urls.tripadvisor}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Yelp URL"
                margin="normal"
                name="yelp"
                type="text"
                value={fieldValues.urls.yelp}
                variant="outlined"
                onChange={handleChange}
              />
            </Box>

            {/* Security Settings */}
            <Box mt={4}>
              <Typography variant="h5">Security Settings</Typography>
              <FormHelperText>
                Configure Merchant security settings including the access PIN
                used for coupon redemption. When a customer shows a QR code for
                coupon, scan from your phone and enter this pin to redeem it.
              </FormHelperText>
              <TextField
                fullWidth
                label="Access PIN"
                margin="normal"
                name="accessPin"
                type="text"
                value={fieldValues.settings.accessPin}
                variant="outlined"
                onChange={handleAccessPinChange}
              />
            </Box>

            {/* Customer Configuration */}
            <Box mt={4}>
              <Typography variant="h5">Customer Configuration</Typography>

              <Box marginTop={2}>
                <Typography>Collect Customer Feedback</Typography>
                <FormHelperText>
                  If this option is disabled, then no customer data will be
                  collected, and the customer will be navigated to the Google
                  review page.
                </FormHelperText>
                <Select
                  fullWidth
                  name="collectCustDetails"
                  value={
                    fieldValues.settings.collectCustDetails ? "true" : "false"
                  }
                  onChange={(e) =>
                    setFieldValues((prevState) => ({
                      ...prevState,
                      settings: {
                        ...prevState.settings,
                        collectCustDetails: e.target.value === "true",
                      },
                    }))
                  }
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Box>

              <Box marginTop={2}>
                <Typography>Default Language</Typography>
                <FormHelperText>
                  Select the default language for customer experience
                  components.
                </FormHelperText>
                <Select
                  fullWidth
                  name="defaultLanguage"
                  value={fieldValues.settings.defaultLanguage}
                  onChange={(e) =>
                    setFieldValues((prevState) => ({
                      ...prevState,
                      settings: {
                        ...prevState.settings,
                        defaultLanguage: e.target.value,
                      },
                    }))
                  }
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                </Select>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
              >
                <Typography>Business Activation</Typography>

                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<SendOutlined />}
                  onClick={handleSendRegistrationEmail}
                >
                  Send Now
                </Button>
              </Box>
              <FormHelperText>
                Send an Activation Email to merchant to setup email and password
                for dashboard access
              </FormHelperText>
            </Box>

            {/* Action Buttons */}
            <Box display="flex" justifyContent="center" mt={3} mb={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Save />}
                onClick={handleSaveChanges}
              >
                Save Changes
              </Button>
            </Box>
          </Container>

          {/* Confirmation Dialog for Sending Registration Email */}
          <Dialog
            open={isEmailConfirmationOpen}
            onClose={() => setIsEmailConfirmationOpen(false)}
          >
            <DialogTitle>Confirm Email</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to send a registration email to{" "}
                {fieldValues.ownerEmail || fieldValues.email}?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => setIsEmailConfirmationOpen(false)}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={confirmSendRegistrationEmail}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Confirm"
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
};

export default AdminMerchantEditComponent;
