import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Chip,
  TextField,
  CardMedia,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import firebase from "../../../config/firebase";
import Page from "../../../utils/Page";
import { makeStyles } from "@mui/styles";
import MerchantContext from "../../../config/MerchantContext"; // Import MerchantContext

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: "100%",
    padding: theme.spacing(3),
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    overflow: "hidden",
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`, // Lighter border
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  cardMedia: {
    width: "100%",
    height: 180, // Adjust image height as needed
    objectFit: "cover",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    userSelect: "none",
  },
  price: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    userSelect: "none",
    color: theme.palette.secondary.main, // Use secondary color for price
  },
  expiryDate: {
    fontSize: "0.9rem",
    userSelect: "none",
    color: theme.palette.text.secondary, // Use secondary text color for expiry date
  },
  description: {
    fontSize: "1rem",
    userSelect: "none",
    marginBottom: theme.spacing(2),
  },
  actionButtons: {
    marginTop: "auto", // Push buttons to the bottom
  },
  couponButton: {
    borderRadius: 25,
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    textTransform: "none",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.secondary.lighter,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  editButton: {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  title: {
    userSelect: "none",
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: theme.palette.text.primary, // Usually black or dark grey
    marginBottom: theme.spacing(1), // Add some spacing below the title
  },
  titleContainer: {
    // New style for title container
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  titleDescription: {
    userSelect: "none",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
  },
}));

const CouponManagement = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { currentMerchant } = useContext(MerchantContext);
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const getCouponLimit = () => {
    if (!currentMerchant || !currentMerchant.subscriptions) {
      return 0; // Return 0 if subscriptions are null to prevent further issues
    }

    switch (currentMerchant.subscriptions.plan) {
      case "BASIC_01":
      case "TRAIL_01":
        return 10;
      case "STAN_01":
        return 100;
      case "PREMIUM_01":
        return "Unlimited"; // No limit for premium
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (!currentMerchant || !currentMerchant.subscriptions) {
      // If currentMerchant or subscriptions is null, navigate to the dashboard
      navigate("/app/dashboard");
      return;
    }

    const fetchCoupons = async () => {
      try {
        const merchantID = currentMerchant.merchantID;
        const couponsData = await firebase.fetchCoupons(merchantID);
        setCoupons(couponsData);
        setOpenDialog(!currentMerchant.subscriptions.isSubscribed);
      } catch (err) {
        setError("Failed to load coupons");
        console.error("Error fetching coupons:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, [currentMerchant, navigate]);

  const handleLogout = () => {
    logout();
  };

  async function logout() {
    try {
      await firebase.logout();
      sessionStorage.removeItem("currentMerchant");
      sessionStorage.removeItem("merchantData");
      navigate("/login", { replace: true });
    } catch (error) {
      alert(error.message);
    }
  }

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredCoupons = coupons.filter((coupon) => {
    if (!searchText) {
      return true;
    }
    const lowercaseSearch = searchText.toLowerCase();
    return (
      coupon.name.toLowerCase().includes(lowercaseSearch) ||
      (coupon.description &&
        coupon.description.toLowerCase().includes(lowercaseSearch))
      // || (coupon.rules && coupon.rules.toLowerCase().includes(lowercaseSearch))
    );
  });

  const handleCreateCoupon = () => {
    if (!currentMerchant.subscriptions.isSubscribed) {
      alert(
        "Your subscription has expired. Please renew your subscription to create new coupons."
      );
      return;
    }

    const couponLimit = getCouponLimit();
    if (couponLimit !== "Unlimited" && coupons.length >= couponLimit) {
      alert(`You have reached the coupon creation limit for your plan.`);
      return;
    }

    navigate("/app/create-coupon");
  };

  const handleEditCoupon = (coupon) => {
    navigate("/app/edit-coupon", { state: { coupon } });
  };

  const handleDeleteCoupon = async (couponId) => {
    try {
      const merchantID = currentMerchant.merchantID;
      await firebase.deleteCoupon(merchantID, couponId);
      setCoupons(coupons.filter((coupon) => coupon.id !== couponId));
    } catch (error) {
      console.error("Failed to delete coupon:", error);
      setError("Failed to delete coupon");
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString();
    }
    return "";
  };

  const handleSubscriptionClick = () => {
    navigate("/app/subscription", {
      replace: true,
    });
  };

  return (
    <Page title="Coupon Management">
      <Box className={classes.root}>
        <Grid item>
          <Box className={classes.titleContainer}>
            <Typography variant="h4" className={classes.title}>
              Coupon Management
            </Typography>
            <Typography variant="body1" className={classes.titleDescription}>
              Create and manage coupons for your business. You can assign these
              coupons for birthday offers or use for winback customer feature.{" "}
              {`You can create ${
                getCouponLimit() === "Unlimited"
                  ? "unlimited"
                  : getCouponLimit() - coupons.length
              } coupons.`}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            component="form"
            sx={{
              display: "flex",
              justifyContent: "space-between", // Adjust as needed
              alignItems: "center",
              "& > :not(style)": {
                width: { xs: "100%", sm: "30ch" },
                mb: { xs: 2, sm: 2 },
              },
            }}
          >
            {/* Search Bar */}
            <TextField
              id="outlined-basic"
              label="Search"
              value={searchText}
              variant="outlined"
              onChange={handleSearchTextChange}
              sx={{ mr: { sm: 2 }, mb: { xs: 2, sm: 2 } }}
            />

            {/* Create Coupon Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateCoupon}
              sx={{ minWidth: 150 }}
            >
              Create New Coupon
            </Button>
          </Box>
        </Grid>

        {loading ? (
          <CircularProgress sx={{ mt: 2 }} />
        ) : error ? (
          <Typography sx={{ mt: 2 }} color="error">
            {error}
          </Typography>
        ) : filteredCoupons.length === 0 ? (
          <Typography sx={{ mt: 2 }}>
            No coupons found. Create new coupons.
          </Typography>
        ) : (
          <Grid sx={{ mt: 2 }} container spacing={2}>
            {filteredCoupons.map((coupon) => {
              const isExpired =
                coupon.expiryDate && new Date(coupon.expiryDate) < new Date();

              return (
                <Grid item xs={6} sm={6} md={4} lg={4} key={coupon.id}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={
                        coupon.imageUrl || "https://via.placeholder.com/200"
                      }
                      title={coupon.name}
                    >
                      {/* Overlay the Expired chip on the image */}
                      {isExpired && (
                        <Chip
                          label="Expired"
                          color="error"
                          size="small"
                          sx={{
                            mt: 2,
                            ml: 2,
                            position: "flex",
                            zIndex: 1, // Ensure the chip is on top of the image
                          }}
                        />
                      )}
                    </CardMedia>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h6" className={classes.title}>
                        {coupon.name}
                      </Typography>
                      <Box className={classes.details}>
                        {/* Price and Expiry Date container */}
                        {/* <Typography variant="body1" className={classes.price}>
                          ${coupon.price}
                        </Typography> */}
                        {coupon.expiryDate && (
                          <Typography
                            variant="body2"
                            className={classes.expiryDate}
                          >
                            Valid till: {coupon.expiryDate}
                          </Typography>
                        )}
                      </Box>
                      {/* <Typography className={classes.rules}>
                        {coupon.rules}
                      </Typography> */}
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {coupon.description.length > 200
                          ? `${coupon.description.slice(0, 200)}...`
                          : coupon.description}
                      </Typography>

                      <div className={classes.actionButtons}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditCoupon(coupon)}
                          className={`${classes.couponButton} ${classes.editButton}`}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDeleteCoupon(coupon.id)}
                          className={`${classes.couponButton} ${classes.deleteButton}`}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {}}
        disableEscapeKeyDown
      >
        <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
          Subscription Required! 😣
        </DialogTitle>
        <DialogContent>
          <Typography>
            Your subscription has expired. Please renew your subscription to
            access all features. If already subscribed, please relogin to gain
            access.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button
            onClick={handleSubscriptionClick}
            color="secondary"
            variant="contained"
          >
            Subscribe Now
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default CouponManagement;
