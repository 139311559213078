import React from "react";
import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <footer
      style={{
        position: "fixed",
        left: 0,
        width: "100%",
        bottom: 4,
        zIndex: 10,
        color: "white",
      }}
    >
      <Typography variant="body2" align="center">
        {"Copyright © "}
        <a
          style={{
            textDecoration: "none",
            color: "white",
          }}
          href="https://www.tezooo.com"
        >
          <strong>Tezooo Innovations Inc</strong>
        </a>{" "}
        {new Date().getFullYear()}{" "}
      </Typography>
    </footer>
  );
}

export default Footer;
