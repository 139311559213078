import React from "react";

const FancyFeatureTewentyFour = () => {
  return (
    <div className="row">
      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#3FE193" }}
            >
              <img src="images/icon/124.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Beauty Salon & Barber Shop</h4>
              <p>
                Enhance your services by collecting feedback from customers
                about their experiences with haircuts, styling, spa treatments,
                and more.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FF4F86" }}
            >
              <img src="images/icon/125.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Restaurants & Bars</h4>
              <p>
                Gather insights to improve dining experiences, menu offerings,
                and customer service in your restaurant or bar.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FFCF39" }}
            >
              <img src="images/icon/126.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Pet Shop</h4>
              <p>
                Capture feedback on pet grooming, pet supplies, and overall
                customer satisfaction to enhance the services offered at your
                pet shop.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#6D64FF" }}
            >
              <img src="images/icon/127.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Furniture Store</h4>
              <p>
                Improve your furniture store by gathering feedback on product
                quality, customer service, and delivery experiences.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#E752FF" }}
            >
              <img src="images/icon/128.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Florist</h4>
              <p>
                Collect valuable feedback on flower arrangements, delivery
                services, and customer experiences to enhance your floral
                business.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#29EEFB" }}
            >
              <img src="images/icon/129.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Repair Shop</h4>
              <p>
                Enhance the quality of your repair services by collecting
                feedback from customers about their experiences with repairs and
                customer service.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#32B5FF" }}
            >
              <img src="images/icon/130.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Cigar & Vape Shop</h4>
              <p>
                Gather feedback to improve product offerings, customer service,
                and overall satisfaction in your cigar and vape shop.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FFA361" }}
            >
              <img src="images/icon/131.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Medical Services</h4>
              <p>
                Enhance patient experiences by collecting feedback on services
                provided by massage therapists, dentists, medical clinics, and
                more.
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default FancyFeatureTewentyFour;
