import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
} from "firebase/firestore";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyD-P0pWcMvgEF0DwSEQHaKY9-f8hMR2imU",
  authDomain: "reviews-plus-8779c.firebaseapp.com",
  projectId: "reviews-plus-8779c",
  storageBucket: "reviews-plus-8779c.appspot.com",
  messagingSenderId: "293193770022",
  appId: "1:293193770022:web:645b8f58bbba3199cf32e7",
  measurementId: "G-E051WD0BCP",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

class Firebase {
  constructor() {
    this.auth = auth;
    this.db = db;
    this.storage = storage;
  }

  //todo test this funcution
  async login(email, password) {
    try {
      const result = await this.auth.signInWithEmailAndPassword(
        email,
        password
      );
      return result.user ? result : null;
    } catch (error) {
      console.log(error);
    }
  }

  logout() {
    return this.auth.signOut();
  }

  async loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    try {
      const result = await this.auth.signInWithPopup(provider);
      return result.user ? result : null;
    } catch (error) {
      console.log(error);
    }
  }

  /** GOOGLE BUSINESS APIS */
  async getGoogleConsoleDetails() {
    try {
      const googleConfigRef = this.db
        .collection("settings")
        .doc("googleConsoleDetails");
      const doc = await googleConfigRef.get();

      if (!doc.exists) {
        throw new Error("No Google Console details found");
      }

      const data = doc.data();
      // console.log("Google Console details retrieved successfully:", data);
      return data; // returns an object { clientId, scope }
    } catch (error) {
      console.error("Error fetching Google Console details:", error);
      throw new Error("Failed to fetch Google Console details");
    }
  }

  async storeGoogleTokens(merchantId, tokens) {
    try {
      const googleConfigRef = this.db.collection("merchants").doc(merchantId);

      // Calculate expirationTime based on current timestamp and expiresIn
      const expirationTime = Date.now() + tokens.expiresIn * 1000;

      // Store all tokens in a map under the same path
      await googleConfigRef.set(
        {
          settings: {
            googleBusinessConfig: {
              ...tokens, // Spread the tokens object to include all tokens
              expirationTime,
              lastUpdated: new Date().toISOString(),
            },
          },
        },
        { merge: true }
      );

      //console.log("Google tokens stored successfully");
    } catch (error) {
      console.error("Error storing Google tokens:", error);
      throw new Error("Failed to store Google tokens");
    }
  }

  async saveGoogleBusinessInfo(merchantId, googleBusinessData) {
    try {
      const merchantRef = this.db.collection("merchants").doc(merchantId);

      // Update the settings with the new Google Business info
      await merchantRef.set(
        {
          settings: {
            googleBusinessConfig: {
              ...googleBusinessData,
              lastUpdated: new Date().toISOString(),
            },
          },
        },
        { merge: true }
      );

      console.log("Google Business information saved successfully");
    } catch (error) {
      console.error("Error saving Google Business information:", error);
      throw new Error("Failed to save Google Business information");
    }
  }

  async saveGoogleAccountId(merchantId, accountId) {
    try {
      const merchantRef = this.db.collection("merchants").doc(merchantId);

      // Update the settings with the new Google Business info
      await merchantRef.set(
        {
          settings: {
            googleBusinessConfig: {
              accountId: accountId,
              lastUpdated: new Date().toISOString(),
            },
          },
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving Google Account Id information:", error);
      throw new Error("Failed to save Google Account information");
    }
  }

  async removeGoogleBusinessInfo(merchantId) {
    try {
      const merchantRef = this.db.collection("merchants").doc(merchantId);

      // Remove the googleBusinessConfig field from settings
      await merchantRef.update({
        "settings.googleBusinessConfig": firebase.firestore.FieldValue.delete(),
      });

      console.log("Google Business information removed successfully");
    } catch (error) {
      console.error("Error removing Google Business information:", error);
      throw new Error("Failed to remove Google Business information");
    }
  }

  async getGoogleBusinessInfo(merchantId) {
    try {
      const merchantRef = this.db.collection("merchants").doc(merchantId);
      const doc = await merchantRef.get();

      if (!doc.exists) {
        console.error(`Merchant document not found for ID: ${merchantId}`);
        throw new Error("Merchant document not found");
      }

      const data = doc.data();

      const googleBusinessConfig = data.settings?.googleBusinessConfig;

      if (googleBusinessConfig) {
        return googleBusinessConfig; // Returns the object containing accessToken, idToken, etc.
      } else {
        console.warn(
          "No Google Business configuration found in the merchant document."
        );
        throw new Error("No Google Business configuration found");
      }
    } catch (error) {
      console.warn(
        "Error fetching Google Business information:",
        error.message
      );
      // throw new Error("Failed to fetch Google Business information");
    }
  }

  async deleteGoogleBusinessConfig(merchantId) {
    try {
      const merchantRef = this.db.collection("merchants").doc(merchantId);

      // Use update to remove the googleBusinessConfig map
      await merchantRef.update({
        "settings.googleBusinessConfig": firebase.firestore.FieldValue.delete(),
      });

      console.log("Google Business configuration deleted successfully.");
    } catch (error) {
      console.error("Error deleting Google Business configuration:", error);
      throw new Error("Failed to delete Google Business configuration");
    }
  }
  /** GOOGLE BUSINESS APIS */

  async saveCustomerResponse(response) {
    const timestamp = Date.now();
    const currentTimeStampDate = new Date().toISOString().split("T")[0];
    const merchantCustomerRef = this.db
      .collection(`merchants/${response.merchantID}/customers`)
      .doc(response.email);

    const customerCollectionRef = this.db
      .collection("customers")
      .doc(response.email);

    const customerCollectionMerchantRef = customerCollectionRef
      .collection("merchants")
      .doc(response.merchantID);

    const experienceRef = this.db.collection(
      `merchants/${response.merchantID}/experiences`
    );

    const scansRef = merchantCustomerRef
      .collection("scans")
      .doc(currentTimeStampDate);

    try {
      // Use a transaction for all updates
      await this.db.runTransaction(async (transaction) => {
        // Get existing customer data (only from the main merchantCustomerRef)
        const customerDoc = await transaction.get(merchantCustomerRef);

        if (!customerDoc.exists) {
          // New customer
          const newCustomerData = {
            name: response.name || "Not Provided",
            email: response.email,
            phone: response.phone,
            dob: response.dob,
            gender: response.gender,
            country: response.country,
            visits: 1,
            optEmail: true,
            lastFeeling: response.feeling,
            visitDate: currentTimeStampDate,
            timestamp,
            lastFeedback: response.feedback,
            birthdayOfferDetails: {
              sendEmail: false,
            },
          };

          // Use transaction.set() to update/create documents atomically
          transaction.set(merchantCustomerRef, newCustomerData);

          const newCustCollectionData = {
            name: response.name || "Not Provided",
            email: response.email,
            phone: response.phone,
            dob: response.dob,
            gender: response.gender,
            country: response.country,
            timestamp,
          };

          transaction.set(customerCollectionRef, newCustCollectionData);

          transaction.set(customerCollectionMerchantRef, {
            lastVisit: currentTimeStampDate,
            timestamp,
            lastFeeling: response.feeling,
            lastFeedback: response.feedback,
            optEmail: true,
          });
        } else {
          // Existing customer
          transaction.update(merchantCustomerRef, {
            timestamp,
            visitDate: currentTimeStampDate,
            feeling: response.feeling,
            feedback: response.feedback,
            visits: firebase.firestore.FieldValue.increment(1),
          });

          // Update the customerCollectionMerchantRef as well
          transaction.set(
            customerCollectionMerchantRef,
            {
              lastVisit: currentTimeStampDate,
              timestamp,
              lastFeeling: response.feeling,
              lastFeedback: response.feedback,
            },
            { merge: true }
          ); // Merge with existing data
        }

        // Add the visit document
        transaction.set(scansRef, {
          feedback: response.feedback,
          feeling: response.feeling,
          timestamp: timestamp,
          visitDate: currentTimeStampDate,
        });

        // Add a new document to the experiences subcollection
        transaction.set(experienceRef.doc(), {
          feeling: response.feeling,
          feedback: response.feedback || "Not Provided",
          timestamp: timestamp,
          visitDate: currentTimeStampDate,
          email: response.email,
        });
      }); // End of transaction
    } catch (error) {
      console.error("Error saving customer response:", error);
      throw error;
    }
  }

  //BIRTHDAY APIS
  async setBirthdayOffer(merchantId, customerEmail, birthdayOfferData) {
    const customerCollectionRef = this.db
      .collection("customers")
      .doc(customerEmail)
      .collection("merchants")
      .doc(merchantId);

    const merchantCustomerRef = this.db
      .collection("merchants")
      .doc(merchantId)
      .collection("customers")
      .doc(customerEmail);

    try {
      // Update both documents in a single transaction
      await this.db.runTransaction(async (transaction) => {
        transaction.set(
          merchantCustomerRef,
          { birthdayOfferDetails: birthdayOfferData },
          { merge: true } // Use merge to prevent overwriting other fields
        );
        transaction.set(
          customerCollectionRef,
          { birthdayOfferDetails: birthdayOfferData },
          { merge: true } // Use merge to prevent overwriting other fields
        );
      });
    } catch (error) {
      console.error("Error updating birthday offer:", error);
      throw error; // Rethrow the error for higher-level handling
    }
  }

  //VISITS
  async deleteVisit(merchantId, customerEmail, visitDate) {
    try {
      await this.db
        .collection("merchants")
        .doc(merchantId)
        .collection("customers")
        .doc(customerEmail)
        .collection("scans")
        .doc(visitDate)
        .delete();
    } catch (error) {
      throw new Error("Failed to delete visit.");
    }
  }

  async register(email, password) {
    await this.auth.createUserWithEmailAndPassword(email, password);
  }

  async registerWithGoogle() {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    await this.auth.signInWithPopup(provider);
  }

  async importCustomerDataToFirebase(merchantID, data) {
    const batch = this.db.batch();
    const merchantRef = this.db.collection("merchants").doc(merchantID);
    const currentDate = new Date().toISOString().split("T")[0];
    const timestamp = Date.now();

    data.forEach(({ name, email, phone, address }) => {
      if (email) {
        const newDocRef = merchantRef.collection("customers").doc(email);
        batch.set(newDocRef, {
          merchantID,
          customerID: newDocRef.id,
          name: name || "",
          email: email || "",
          phone: phone ? String(phone) : "",
          dob: "", // Default value for date of birth
          gender: "", // Default value for gender
          lastFeedback: "", // Default value for last feedback
          lastFeeling: "", // Default value for last feeling
          optEmail: true, // Default optEmail to true
          visitDate: currentDate, // Default to current date
          timestamp, // Current timestamp
          address: address || "", // Address field with default empty string
          country: "", // Default value for country
          visits: 1, // Default visits count
          birthdayOfferDetails: {}, // Default empty map for birthday offer details
          optEmail: true, // Default sendEmail to true
        });
      }
    });

    await batch.commit();
  }

  async createMerchantProfile(state) {
    this.updateApprovedMerchantId(state.merchantID);
    const trialPeriodDays = 7;
    const trialEndDate = new Date();
    trialEndDate.setDate(trialEndDate.getDate() + trialPeriodDays);

    return this.db.doc(`merchants/${state.merchantID}`).set({
      id: state.merchantID,
      email: state.ownerEmail,
      merchantID: state.merchantID,
      businessName: state.businessName,
      businessAddress: state.businessAddress,
      businessLogoUrl: state.businessLogoUrl,
      settings: {
        fullCustDetails: false,
        collectCustDetails: true,
        defaultLanguage: "en",
        accessPin: "",
      },
      subscriptions: {
        isSubscribed: true,
        plan: "TRAIL_01",
        subscriptionStatus: "trial",
        trialEndDate: trialEndDate.toISOString().split("T")[0], // Set trial end date
      },
      businessType: state.businessType,
      ownerName: state.ownerName,
      ownerPhone: state.ownerPhone,
      ownerEmail: state.ownerEmail,
      isEmailVerified: false,
      website: state.website,
      initGoogleReviews: state.initGoogleReviews,
      initGoogleRating: state.initGoogleRating,
      googleReviewUrl: state.googleMyBusinessUrl,
      googlePlaceID: state.googlePlaceID,
      createdTimestamp: state.createdTimeStamp,
      location: [state.lat, state.lng],
      urls: {
        facebook: "",
        instagram: "",
        tiktok: "",
        yellowpages: "",
        yelp: "",
        tripadvisor: "",
      },
      timeStamp: Date.now(),
    });
  }

  async updateMerchantProfile(state) {
    if (!this.auth.currentUser) {
      return alert("Not authorized user. Please try again.");
    } else {
      this.updateApprovedMerchantId(state.merchantID);
      return this.db.doc(`merchants/${state.merchantID}`).set({
        id: this.auth.currentUser.uid,
        email: this.auth.currentUser.email,
        merchantID: state.merchantID,
        businessName: state.businessName,
        businessAddress: state.businessAddress,
        businessLogoUrl: state.businessLogoUrl,
        settings: {
          accessPin: "",
          fullCustDetails: false,
          collectCustDetails: true,
          defaultLanguage: "en",
        },
        subscriptions: {
          isSubscribed: false,
        },
        website: state.website,
        initGoogleReviews: state.initGoogleReviews,
        initGoogleRating: state.initGoogleRating,
        googleReviewUrl: state.googleReviewUrl,
        googlePlaceID: state.googlePlaceID,
        createdTimestamp: state.createdTimeStamp,
        location: [state.lat, state.lng],
        urls: {
          facebook: "",
          instagram: "",
          tiktok: "",
          yellowpages: "",
          yelp: "",
          tripadvisor: "",
        },
        timeStamp: Date.now(),
      });
    }
  }

  //merchant pin
  async verifyMerchantPin(merchantId, enteredPin) {
    const merchantDoc = await this.db
      .collection("merchants")
      .doc(merchantId)
      .get();

    if (!merchantDoc.exists) {
      throw new Error("Merchant not found");
    }

    const merchantData = merchantDoc.data();
    return merchantData.settings.accessPin === enteredPin;
  }

  //coupon redeemption
  async markCouponRedeemed(
    merchantId,
    customerEmail,
    couponId,
    redemptionDate
  ) {
    const batch = this.db.batch();

    try {
      // 1. Update the coupon document in the customer's merchant subcollection
      const customerCouponRef = this.db
        .collection("customers")
        .doc(customerEmail)
        .collection("merchants")
        .doc(merchantId)
        .collection("coupons")
        .doc(couponId);

      batch.update(customerCouponRef, {
        isCouponRedeemed: true,
        redemptionDate,
      });

      // 2. (Optional) Update the scan document in the merchant's customer subcollection
      const custCouponsRef = this.db
        .collection("merchants")
        .doc(merchantId)
        .collection("customers")
        .doc(customerEmail)
        .collection("coupons")
        .doc(couponId);

      batch.update(custCouponsRef, { isCouponRedeemed: true, redemptionDate });

      // 3. (Optional) Add to a redeemed coupons collection
      const redeemedCouponRef = this.db
        .collection("merchants")
        .doc(merchantId)
        .collection("redeemedCoupons") // New collection
        .doc();
      batch.set(redeemedCouponRef, {
        customerId: customerEmail,
        couponId,
        redemptionDate: firebase.firestore.FieldValue.serverTimestamp(), // Server timestamp
      });

      // 4. Commit the batch of updates
      await batch.commit();
    } catch (error) {
      console.error("Error marking coupon as redeemed:", error);
      throw error; // Rethrow the error for handling in your UI
    }
  }

  async updateMerchantEmailVerification(merchantID) {
    return this.db.doc(`merchants/${merchantID}`).update({
      isEmailVerified: true,
    });
  }

  async updateCloverMerchantProfile(state, emailId) {
    return this.db.doc(`merchants/${state.merchantID}`).set({
      email: emailId,
      merchantID: state.merchantID,
      businessName: state.businessName,
      businessAddress: state.businessAddress,
      businessLogoUrl: state.businessLogoUrl,
      settings: {
        fullCustDetails: false,
        collectCustDetails: true,
        isCouponEnabled: false,
        language: "en",
        accessPin: state.accessPin || "",
      },
      website: state.website,
      initGoogleReviews: state.initGoogleReviews,
      initGoogleRating: state.initGoogleRating,
      googleReviewUrl: state.googleReviewUrl,
      googlePlaceID: state.googlePlaceID,
      createdTimestamp: state.createdTimeStamp,
      location: [state.lat, state.lng],
      urls: {
        facebook: "",
        instagram: "",
        tiktok: "",
        yellowpages: "",
        yelp: "",
        tripadvisor: "",
      },
      timeStamp: Date.now(),
    });
  }

  async updateMerchantBusinessSettings(state) {
    return this.db.doc(`merchants/${state.merchantID}`).update({
      businessName: state.businessName,
      businessAddress: state.businessAddress,
      businessLogoUrl: state.businessLogoUrl,
      settings: {
        fullCustDetails: state.settings.fullCustDetails,
        collectCustDetails: state.settings.collectCustDetails,
        defaultLanguage: state.settings.defaultLanguage,
        accessPin: state.settings.accessPin || "",
      },
      website: state.website,
      initGoogleRating: state.initGoogleRating,
      initGoogleReviews: state.initGoogleReviews,
      googleReviewUrl: state.googleReviewUrl,
      googlePlaceID: state.googlePlaceID,
      location: [state.lati, state.longi],
      urls: {
        facebook: state.urls.facebook,
        instagram: state.urls.instagram,
        tiktok: state.urls.tiktok,
        yellowpages: state.urls.yellowpages,
        yelp: state.urls.yelp,
        tripadvisor: state.urls.tripadvisor,
      },
      timeStamp: Date.now(),
    });
  }

  async fetchPromoCards(merchantId) {
    try {
      const snapshot = await this.db
        .collection(`/merchants/${merchantId}/promoCards`)
        .get();
      const promoCards = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return promoCards;
    } catch (error) {
      console.error("Error fetching promo cards:", error);
      return [];
    }
  }

  async createPromoCard(merchantId, cardData) {
    try {
      const docRef = await this.db
        .collection(`/merchants/${merchantId}/promoCards`)
        .add(cardData);
      return docRef.id;
    } catch (error) {
      console.error("Error creating promo card:", error);
      return null;
    }
  }

  async reactivatePromoCard(merchantId, cardId, cardData) {
    try {
      const docRef = await this.db
        .collection(`/merchants/${merchantId}/promoCards`)
        .doc(cardId)
        .set(cardData);
      return docRef.id;
    } catch (error) {
      console.error("Error creating promo card:", error);
      return null;
    }
  }

  async deletePromoCard(merchantId, cardId) {
    try {
      await this.db
        .collection(`/merchants/${merchantId}/promoCards`)
        .doc(cardId)
        .delete();
    } catch (error) {
      console.error("Error deleting promo card:", error);
    }
  }

  async updatePromoCard(merchantId, cardId, cardData) {
    try {
      await this.db
        .collection(`/merchants/${merchantId}/promoCards`)
        .doc(cardId)
        .update(cardData);
    } catch (error) {
      console.error("Error updating promo card:", error);
    }
  }

  async updateMerchantQrScan(id) {
    const currentDate = new Date().toISOString().split("T")[0];
    return this.db
      .doc(`merchants/${id}`)
      .collection("scans")
      .doc(`${Date.now()}`)
      .set({
        id: Date.now(),
        date: currentDate,
        message: "A Customer scanned the QR code",
        visitDate: currentDate,
        timestamp: Date.now(),
      });
  }

  async getAllMerchantBusiness() {
    let data = [];
    try {
      const user = auth.currentUser;
      if (!user) {
        //throw new Error("User is not authenticated");
        return data;
      }

      const querySnapshot = await db
        .collection("merchants")
        .where("email", "==", user.email)
        .get();

      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
    } catch (error) {
      console.error("Error getting collection:", error);
    }
    return data;
  }

  async getMerchantDetails(merchantId) {
    let data;
    try {
      const snapshot = await this.db
        .collection("merchants")
        .doc(`${merchantId}`)
        .get();

      data = snapshot.data();
    } catch (e) {
      console.log(e);
    }
    return data;
  }

  async getMerchantCustomerDetails(merchantId, customerEmail) {
    let data;
    try {
      const snapshot = await this.db
        .collection("merchants")
        .doc(`${merchantId}`)
        .collection("customers")
        .doc(`${customerEmail}`)
        .get();

      data = snapshot.data();
    } catch (e) {
      console.log(e);
    }
    return data;
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  isLoggedIn() {
    return this.auth.currentUser ? true : false;
  }

  async getCurrentUserName() {
    const userName = await this.db
      .doc(`users/${this.auth.currentUser.uid}`)
      .get();

    return userName.get("name");
  }

  // async checkIfRegistered(email) {
  //   let data = [];
  //   try {
  //     const querySnapshot = await this.db
  //       .collection("merchants")
  //       .where("email", "==", email)
  //       .get();

  //     querySnapshot.forEach((doc) => {
  //       data.push(doc.data());
  //     });
  //   } catch (error) {
  //     console.log("Error getting collection: ", error);
  //   }
  //   return data;
  // }

  async checkIfRegistered(email) {
    let data = [];
    try {
      const querySnapshot = await this.db
        .collection("merchants")
        .where("email", "==", email)
        .get();

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
    } catch (error) {
      console.log("Error getting collection: ", error);
    }
    // console.log(`Returning data: ${JSON.stringify(data)}`);
    return data;
  }

  async getAllMerchantCustomers(merchantId) {
    let data = [];
    try {
      const querySnapshot = await this.db
        .collection("merchants")
        .doc(merchantId)
        .collection("customers")
        .get();

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
    } catch (error) {
      console.log("Error getting collection: ", error);
    }
    return data;
  }

  async deleteCustomer(merchantID, customerID) {
    const merchantCustomerRef = db
      .collection("merchants")
      .doc(merchantID)
      .collection("customers")
      .doc(customerID);

    const customerMerchantRef = db
      .collection("customers")
      .doc(customerID)
      .collection("merchants")
      .doc(merchantID);

    try {
      // Run a batch operation to delete the customer in both collections
      const batch = db.batch();

      // Delete the customer document from the merchant's customers collection
      batch.delete(merchantCustomerRef);

      // Delete the merchant document from the customer's merchants collection
      batch.delete(customerMerchantRef);

      // Commit the batch
      await batch.commit();
    } catch (error) {
      console.error("Error deleting customer:", error);
      throw error; // Rethrow the error for higher-level handling
    }
  }

  async getAllMerchantCustomersByMonthRange(
    merchantId,
    startTimestamp,
    endTimestamp
  ) {
    let data = [];
    try {
      const querySnapshot = await this.db
        .collection("merchants")
        .doc(merchantId)
        .collection("customers")
        .where("timestamp", ">=", startTimestamp)
        .where("timestamp", "<=", endTimestamp)
        .get();

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
    } catch (error) {
      console.log("Error getting collection: ", error);
    }
    return data;
  }

  async getMonthlyCustomers(merchantId) {
    const customers = [];

    // Calculate the start and end timestamps for the last 6 months
    const currentDate = new Date();
    const endTimestamp = Math.floor(currentDate.getTime() / 1000);
    const startTimestamp = endTimestamp - 6 * 30 * 24 * 60 * 60;

    try {
      const querySnapshot = await this.db
        .collection("merchants")
        .doc(merchantId)
        .collection("customers")
        .where("timestamp", ">=", startTimestamp)
        .where("timestamp", "<=", endTimestamp)
        .get();

      querySnapshot.forEach((doc) => {
        customers.push(doc.data());
      });
    } catch (error) {
      console.log("Error getting collection:", error);
    }

    return customers;
  }

  async getWeeklyCustomerGrowth(merchantId) {
    const customersRef = this.db
      .collection("merchants")
      .doc(merchantId)
      .collection("customers");

    const currentTimestamp = Date.now();
    const lastWeekStart = currentTimestamp - 7 * 24 * 60 * 60 * 1000;

    const thisWeekCustomers = await customersRef
      .where("timestamp", ">=", lastWeekStart)
      .where("timestamp", "<=", currentTimestamp)
      .get()
      .then((querySnapshot) => {
        const customers = [];
        querySnapshot.forEach((doc) => {
          customers.push(doc.data());
        });
        return customers;
      })
      .catch((error) => {
        console.log("Error getting this week's customers:", error);
        return [];
      });

    const lastWeekCustomers = await customersRef
      .where("timestamp", ">=", lastWeekStart - 7 * 24 * 60 * 60 * 1000)
      .where("timestamp", "<=", lastWeekStart)
      .get()
      .then((querySnapshot) => {
        const customers = [];
        querySnapshot.forEach((doc) => {
          customers.push(doc.data());
        });
        return customers;
      })
      .catch((error) => {
        console.log("Error getting last week's customers:", error);
        return [];
      });

    const thisWeekCount = thisWeekCustomers.length;
    const lastWeekCount = lastWeekCustomers.length;

    if (lastWeekCount === 0) {
      return thisWeekCount * 100;
    } else if (thisWeekCount === 0) {
      return 0;
    } else {
      const growth = ((thisWeekCount - lastWeekCount) / lastWeekCount) * 100;
      return Math.round(growth);
    }
  }

  async checkIfMerchantExist(merchantID) {
    let data = [];
    try {
      const querySnapshot = await this.db
        .collection("merchants")
        .where("merchantID", "==", merchantID)
        .get();

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
    } catch (error) {
      console.log("Error getting collection: ", error);
    }
    return data;
  }

  async checkIfApprovedId(merchantID) {
    try {
      const docRef = this.db
        .collection("approved-merchant-ids")
        .doc(merchantID);
      const doc = await docRef.get();
      if (doc.exists) {
        const data = doc.data();
        if (data.isActivated) {
          return "MID Active";
        } else {
          return "MID Inactive";
        }
      } else {
        return "MID don't exist";
      }
    } catch (error) {
      console.log("Error checking for approved merchant ID: ", error);
    }
  }

  async updateApprovedMerchantId(merchantID) {
    try {
      await this.db.doc(`approved-merchant-ids/${merchantID}`).set({
        isActivated: true,
        timeStamp: Date.now(),
      });
    } catch (error) {
      console.log("Error checking for approved merchant ID: ", error);
    }
  }

  async getCustomerFeelings(merchantId) {
    const customers = await this.getAllMerchantCustomers(merchantId);
    const feelings = {
      angry: 0,
      sad: 0,
      ok: 0,
      happy: 0,
      loved: 0,
    };
    const totalCustomers = customers.length;

    for (let i = 0; i < totalCustomers; i++) {
      const feeling = customers[i].feeling;
      if (feeling === "angry") {
        feelings.angry++;
      } else if (feeling === "sad") {
        feelings.sad++;
      } else if (feeling === "ok") {
        feelings.ok++;
      } else if (feeling === "happy") {
        feelings.happy++;
      } else if (feeling === "loved") {
        feelings.loved++;
      }
    }

    for (let key in feelings) {
      feelings[key] =
        totalCustomers > 0
          ? ((feelings[key] / totalCustomers) * 100).toFixed(2)
          : 0;
    }

    return feelings;
  }

  //Customer feelings on dashboard analytics
  async getCustomerExperience(merchantId) {
    const customers = await this.getMerchantCustomerExperiences(merchantId);
    const feelings = {
      angry: 0,
      sad: 0,
      ok: 0,
      happy: 0,
      loved: 0,
    };
    const totalCustomers = customers.length;

    for (let i = 0; i < totalCustomers; i++) {
      const feeling = customers[i].feeling;
      if (feeling === "angry") {
        feelings.angry++;
      } else if (feeling === "sad") {
        feelings.sad++;
      } else if (feeling === "ok") {
        feelings.ok++;
      } else if (feeling === "happy") {
        feelings.happy++;
      } else if (feeling === "loved") {
        feelings.loved++;
      }
    }

    for (let key in feelings) {
      feelings[key] =
        totalCustomers > 0
          ? ((feelings[key] / totalCustomers) * 100).toFixed(2)
          : 0;
    }

    return feelings;
  }

  async getMerchantCustomerExperiences(merchantId) {
    let data = [];
    try {
      const querySnapshot = await this.db
        .collection("merchants")
        .doc(merchantId)
        .collection("experiences")
        .get();

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
    } catch (error) {
      console.log("Error getting collection: ", error);
    }
    return data;
  }

  async getQrScansVsCustomers(merchantId) {
    const customersRef = this.db
      .collection("merchants")
      .doc(merchantId)
      .collection("customers");

    const scansRef = this.db
      .collection("merchants")
      .doc(merchantId)
      .collection("scans");

    const result = {};

    const currentDate = Date.now();
    const lastWeekStart = currentDate - 7 * 24 * 60 * 60 * 1000;

    try {
      const customersSnapshot = await customersRef
        .where("timestamp", ">=", lastWeekStart)
        .get();

      customersSnapshot.forEach((doc) => {
        const customer = doc.data();
        const date = new Date(customer.timestamp).toLocaleDateString();
        if (result[date]) {
          result[date][1]++;
        } else {
          result[date] = [0, 1];
        }
      });

      const scansSnapshot = await scansRef
        .where("timestamp", ">=", lastWeekStart)
        .get();

      scansSnapshot.forEach((doc) => {
        const scan = doc.data();
        const date = new Date(scan.timestamp).toLocaleDateString();
        if (result[date]) {
          result[date][0]++;
        } else {
          result[date] = [1, 0];
        }
      });

      for (let i = 6; i >= 0; i--) {
        const day = new Date(currentDate);
        day.setDate(day.getDate() - i);
        const date = day.toLocaleDateString();
        if (!result[date]) {
          result[date] = [0, 0];
        }
      }

      return result;
    } catch (error) {
      console.error("Error getting QR scans and customers data: ", error);

      for (let i = 6; i >= 0; i--) {
        const day = new Date(currentDate);
        day.setDate(day.getDate() - i);
        const date = day.toLocaleDateString();
        if (!result[date]) {
          result[date] = [0, 0];
        }
      }

      return result;
    }
  }

  async forgotPassword(email) {
    try {
      const result = await this.auth.fetchSignInMethodsForEmail(email);
      if (result.length > 0) {
        await this.auth.sendPasswordResetEmail(email);
      }
    } catch (error) {
      console.log("Error Sending Email", error);
    }
  }

  async saveContest(response) {
    const contestRef = this.db.collection(
      `merchants/${response.merchantID}/contests/`
    );
    return contestRef
      .add({
        merchantID: response.merchantID,
        contestName: response.contestName,
        expiry: new Date(response.expiry).getTime(),
        couponCode: response.couponCode,
        description: response.description,
        couponType: response.couponType,
        timestamp: Date.now(),
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  }

  async getContests(merchantId) {
    let data = [];
    try {
      const querySnapshot = await this.db
        .collection("merchants")
        .doc(`${merchantId}`)
        .collection("contests")
        .get();

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
    } catch (error) {
      console.log("Error getting collection:", error);
    }

    return data;
  }

  /**
   * CUSTOMERS DATA
   */
  async getNumberOfCustomers(merchantId) {
    try {
      const customersSnapshot = await this.db
        .collection(`merchants/${merchantId}/customers`)
        .get();
      return customersSnapshot.size;
    } catch (error) {
      console.error("Error fetching customers:", error);
      throw error;
    }
  }

  // async deleteCustomer(merchantID, customerEmail) {
  //   console.log("Starting delete process for customer:", customerEmail);
  //   try {
  //     const customersRef = this.db
  //       .collection("merchants")
  //       .doc(merchantID)
  //       .collection("customers");
  //     const snapshot = await customersRef
  //       .where("email", "==", customerEmail)
  //       .get();
  //     if (!snapshot.empty) {
  //       console.log("Customer found. Deleting...");
  //       const batch = this.db.batch();
  //       snapshot.forEach((doc) => {
  //         console.log("Deleting document with ID:", doc.id);
  //         batch.delete(doc.ref);
  //       });
  //       await batch.commit();
  //       console.log("Customer deleted successfully!");
  //     } else {
  //       throw new Error("Customer not found");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting customer:", error);
  //     throw error;
  //   }
  // }

  /**
   * Get Promotions Details
   *  */
  async fetchPromotionData(merchantId, promoId) {
    try {
      const promotionDoc = await this.db
        .collection(`merchants/${merchantId}/promoCards`)
        .doc(promoId)
        .get();

      if (promotionDoc.exists) {
        const merchantDoc = await this.db
          .collection("merchants")
          .doc(merchantId)
          .get();
        if (!merchantDoc.exists) {
          throw new Error("Merchant not found");
        }
        const promotionData = promotionDoc.data();
        const merchantData = merchantDoc.data();

        return {
          ...promotionData,
          businessLogoUrl: merchantData.businessLogoUrl,
          businessName: merchantData.businessName,
          businessAddress: merchantData.businessAddress,
        };
      } else {
        console.warn("Promotion not found for promotion:", promoId);
        throw new Error("Promotion not found");
      }
    } catch (error) {
      console.error(
        "Error fetching promotion data for merchant:",
        merchantId,
        "and promotion:",
        promoId,
        error
      );
      throw error;
    }
  }

  /**
   * COUPON MANAGEMENT
   */

  async createCoupon(merchantID, couponData) {
    try {
      //  console.log(`Attempting to create coupon for merchant ID: ${merchantID}`);
      const couponRef = await this.db
        .collection(`merchants/${merchantID}/coupons`)
        .doc();
      await couponRef.set({
        ...couponData,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      //  console.log("Coupon created successfully with data:", couponData);
    } catch (error) {
      console.error("Error creating coupon:", error);
      throw error;
    }
  }

  async updateCoupon(merchantID, couponID, couponData) {
    try {
      const couponRef = await this.db
        .collection(`merchants/${merchantID}/coupons`)
        .doc(couponID);
      await couponRef.update({
        ...couponData,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      console.error("Error updating coupon:", error);
      throw error;
    }
  }

  async fetchCoupons(merchantID) {
    try {
      const couponsSnapshot = await this.db
        .collection(`merchants/${merchantID}/coupons`)
        .get();
      const coupons = couponsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //console.log("Fetched coupons:", coupons);
      return coupons;
    } catch (error) {
      console.error("Error fetching coupons:", error);
      throw error;
    }
  }

  //fetch customer coupon
  async getCustomerCoupon(merchantId, customerEmail, couponId) {
    try {
      const customerMerchantRef = this.db
        .collection("customers")
        .doc(customerEmail)
        .collection("merchants")
        .doc(merchantId)
        .collection("coupons")
        .doc(couponId);
      const customerSnapshot = await customerMerchantRef.get();

      if (!customerSnapshot.exists) {
        throw new Error("Customer not found");
      }

      const customerData = customerSnapshot.data();

      if (customerData.selectedCoupon.id !== couponId) {
        throw new Error("Invalid coupon for this customer");
      }

      return customerData;
    } catch (error) {
      console.error("Error fetching customer's coupon:", error);
      throw error; // Re-throw the error for higher-level handling (in your component)
    }
  }

  //fetch customer scans documents. Each scan is considered as a visit if its saved for the customer document
  async fetchMerchantCustomerScans(merchantID, customerEmail) {
    try {
      const scansSnapshot = await this.db
        .collection(`merchants/${merchantID}/customers/${customerEmail}/scans`)
        .get();
      const scans = scansSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //console.log("Fetched scan data:", scans);
      return scans;
    } catch (error) {
      console.error("Error fetching scan data:", error);
      throw error;
    }
  }

  async deleteCoupon(merchantID, couponID) {
    try {
      await this.db
        .collection(`merchants/${merchantID}/coupons`)
        .doc(couponID)
        .delete();
    } catch (error) {
      console.error("Error deleting coupon:", error);
      throw error;
    }
  }

  async sendCouponEmail(merchantID, couponID, groupID) {
    try {
      // Implement the email sending logic here, e.g., using Firebase Cloud Functions
      // console.log(
      //   `Sending coupon ${couponID} to group ${groupID} for merchant ${merchantID}`
      // );
    } catch (error) {
      console.error("Error sending coupon email:", error);
      throw error;
    }
  }

  /**
   * BIRTHDAY COUPONS
   */

  async removeBirthdayOffer(merchantId, customerEmail) {
    const customerCollectionRef = this.db
      .collection("customers")
      .doc(customerEmail)
      .collection("merchants")
      .doc(merchantId);

    const merchantCustomerRef = this.db
      .collection("merchants")
      .doc(merchantId)
      .collection("customers")
      .doc(customerEmail);

    try {
      // Use a transaction to ensure both documents are updated atomically
      await this.db.runTransaction(async (transaction) => {
        // Remove the birthdayOfferDetails field from both documents
        transaction.update(merchantCustomerRef, {
          birthdayOfferDetails: firebase.firestore.FieldValue.delete(),
        });
        transaction.update(customerCollectionRef, {
          birthdayOfferDetails: firebase.firestore.FieldValue.delete(),
        });
      });

      //  console.log("Birthday offer removed successfully from both collections.");
    } catch (error) {
      console.error("Error removing birthday offer:", error);
      throw error; // Rethrow the error for higher-level handling
    }
  }

  // Fetch coupon details for a customer under a specific merchant
  async getBirthdayCouponDetails(merchantId, customerEmail, couponId) {
    const customerRef = doc(
      db,
      `merchants/${merchantId}/customers/${customerEmail}`
    );
    const customerSnapshot = await getDoc(customerRef);

    if (!customerSnapshot.exists()) {
      throw new Error("Customer not found");
    }

    const birthdayOfferDetails = customerSnapshot.data().birthdayOfferDetails;
    if (!birthdayOfferDetails || !birthdayOfferDetails.couponDetails) {
      throw new Error("No coupon details found");
    }

    const couponDetails = birthdayOfferDetails.couponDetails;

    if (couponDetails.id !== couponId) {
      throw new Error("Coupon ID does not match");
    }

    return couponDetails;
  }

  // Mark the coupon as redeemed
  async markBirthdayCouponRedeemed(
    merchantId,
    customerEmail,
    couponId,
    redemptionDate
  ) {
    const customerRef = doc(
      db,
      `merchants/${merchantId}/customers/${customerEmail}`
    );
    const merchantCustomerRef = doc(
      db,
      `customers/${customerEmail}/merchants/${merchantId}`
    );
    const customerSnapshot = await getDoc(customerRef);
    const merchantCustomerSnapshot = await getDoc(merchantCustomerRef);

    if (!customerSnapshot.exists() || !merchantCustomerSnapshot.exists()) {
      throw new Error("Customer not found");
    }

    const birthdayOfferDetails = customerSnapshot.data().birthdayOfferDetails;
    if (!birthdayOfferDetails || !birthdayOfferDetails.couponDetails) {
      throw new Error("No coupon details found");
    }

    const couponDetails = birthdayOfferDetails.couponDetails;

    if (couponDetails.id !== couponId) {
      throw new Error("Coupon ID does not match");
    }

    if (couponDetails.isRedeemed) {
      throw new Error("Coupon has already been redeemed");
    }

    const redeemedCoupon = {
      couponId,
      merchantId,
      customerEmail,
      reason: "Birthday Coupon",
      redemptionDate: new Date(redemptionDate),
    };

    await updateDoc(customerRef, {
      "birthdayOfferDetails.couponDetails.isRedeemed": true,
      "birthdayOfferDetails.couponDetails.redemptionDate": redemptionDate,
    });

    await updateDoc(merchantCustomerRef, {
      "birthdayOfferDetails.couponDetails.isRedeemed": true,
      "birthdayOfferDetails.couponDetails.redemptionDate": redemptionDate,
    });

    await addDoc(
      collection(db, `merchants/${merchantId}/redeemedCoupons`),
      redeemedCoupon
    );

    return { success: true, message: "Coupon redeemed successfully" };
  }
  /**
   *
   * SUBSCRIPTION STATUS
   */
  async fetchSubscriptionStatus(merchantId) {
    try {
      const docRef = doc(getFirestore(), "subscriptions", merchantId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  }

  /////////////
  ///USERS/////
  /////////////
  async addUser(email, roleType, merchantID, isRoleActive) {
    try {
      const userRef = this.db.collection("users").doc(email);
      const userDoc = await userRef.get();
      if (userDoc.exists) {
        await userRef.update({
          [`${roleType}.${merchantID}`]: isRoleActive,
        });
      } else {
        await userRef.set({
          customer: {},
          admin: {},
          employee: {},
        });
        await userRef.update({
          [`${roleType}.${merchantID}`]: isRoleActive,
        });
      }
    } catch (error) {
      console.error("Error adding/updating user:", error);
      throw error;
    }
  }

  async getUserByEmail(email) {
    try {
      const userDoc = await db.collection("users").doc(email).get();
      return userDoc.exists ? userDoc.data() : null;
    } catch (error) {
      console.error("Error fetching user:", error);
      return null;
    }
  }

  async updateUserRoles(email, roleType, merchantID, isRoleActive) {
    try {
      await this.db
        .collection("users")
        .doc(email)
        .update({
          [`${roleType}.${merchantID}`]: isRoleActive,
        });
    } catch (error) {
      throw error;
    }
  }

  /**
   *
   * EMPLOYEES APIS
   *
   */
  async getAllEmployees(merchantID) {
    const snapshot = await this.db
      .collection(`merchants/${merchantID}/employees`)
      .get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  async getSingleEmployee(merchantID, email) {
    const employeesRef = db.collection(`merchants/${merchantID}/employees`);
    const snapshot = await employeesRef.where("email", "==", email).get();
    if (!snapshot.empty) {
      return snapshot.docs[0].data();
    }
    return null;
  }

  async addEmployee(merchantID, employee) {
    const employeeRef = await this.db
      .collection(`merchants/${merchantID}/employees`)
      .add(employee);
    await this.updateUserRoles(employee.email, merchantID, "employee");
    return employeeRef;
  }

  async updateEmployee(merchantID, id, employee) {
    await this.db
      .collection(`merchants/${merchantID}/employees`)
      .doc(id)
      .update(employee);
    await this.updateUserRoles(employee.email, merchantID, "employee");
  }

  async deleteEmployee(merchantID, id) {
    const employeeDoc = await this.db
      .collection(`merchants/${merchantID}/employees`)
      .doc(id)
      .get();
    const employeeEmail = employeeDoc.data().email;
    await this.db
      .collection(`merchants/${merchantID}/employees`)
      .doc(id)
      .delete();
    await this.updateUserRoles(employeeEmail, merchantID, "employee", true);
  }

  async updateUserRoles(email, merchantID, role, remove = false) {
    const userRef = this.db.collection("users").doc(email);
    const userDoc = await userRef.get();
    let userData = {
      customer: {},
      admin: {},
      employee: {},
    };

    if (userDoc.exists) {
      userData = userDoc.data();
    }

    if (remove) {
      if (userData[role] && userData[role][merchantID]) {
        delete userData[role][merchantID];
      }
    } else {
      userData[role] = {
        ...userData[role],
        [merchantID]: true,
      };
    }

    await userRef.set(userData, { merge: true });
  }

  ////////////////////////////WINBACK APIS//////////////////////////////
  //update scans document - that contains date
  async updateScanWithWinbackOffer(
    merchantId,
    customerEmail,
    scanDate,
    selectedCoupon
  ) {
    const merchantCouponRef = this.db
      .collection("merchants")
      .doc(merchantId)
      .collection("customers")
      .doc(customerEmail)
      .collection("coupons")
      .doc(selectedCoupon.id);

    const customerCouponRef = this.db
      .collection("customers")
      .doc(customerEmail)
      .collection("merchants")
      .doc(merchantId)
      .collection("coupons")
      .doc(selectedCoupon.id);

    await this.db.runTransaction(async (transaction) => {
      const merchantCouponDoc = await transaction.get(merchantCouponRef);
      const customerCouponDoc = await transaction.get(customerCouponRef);

      const updatedCouponData = {
        emailSent: true,
        selectedCoupon,
        issuedDate: scanDate,
        isCouponRedeemed: false,
      };

      // Update coupon details in merchants collection
      if (merchantCouponDoc.exists) {
        transaction.update(merchantCouponRef, updatedCouponData);
      } else {
        transaction.set(merchantCouponRef, updatedCouponData);
      }

      // Update coupon details in customers collection
      const updatedCouponDataCustomers = {
        emailSent: true,
        selectedCoupon,
        isCouponRedeemed: false,
      };

      if (customerCouponDoc.exists) {
        transaction.update(customerCouponRef, updatedCouponDataCustomers);
      } else {
        transaction.set(customerCouponRef, updatedCouponDataCustomers);
      }
    });
  }

  //Get Coupon details for Redeem OFfer component
  async getCoupon(merchantId, customerEmail, couponId) {
    try {
      const customerRef = this.db
        .collection("customers")
        .doc(customerEmail)
        .collection("merchants")
        .doc(merchantId)
        .collection("coupons");
      const customerSnapshot = await customerRef.get();

      if (!customerSnapshot.exists) {
        throw new Error("Customer not found");
      }

      const customerData = customerSnapshot.data();

      // Check if the winbackCoupon field exists and the coupon ID matches
      if (
        customerData.winbackCoupon &&
        customerData.winbackCoupon.id === couponId
      ) {
        return customerData.winbackCoupon;
      } else {
        throw new Error("Invalid coupon for this customer");
      }
    } catch (error) {
      console.error("Error fetching customer's coupon:", error);
      throw error;
    }
  }

  //UPDATE CUSTOMER INFO
  async updateCustomerInfo(merchantId, customerEmail, updatedData) {
    const customerCollectionRef = this.db
      .collection("customers")
      .doc(customerEmail);

    const merchantCustomerRef = this.db
      .collection("merchants")
      .doc(merchantId)
      .collection("customers")
      .doc(customerEmail);

    try {
      await this.db.runTransaction(async (transaction) => {
        const customerDoc = await transaction.get(customerCollectionRef);
        const merchantCustomerDoc = await transaction.get(merchantCustomerRef);

        if (customerDoc.exists) {
          transaction.update(customerCollectionRef, updatedData);
        }
        if (merchantCustomerDoc.exists) {
          transaction.update(merchantCustomerRef, updatedData);
        }
      });
    } catch (error) {
      throw error; // Rethrow the error for higher-level handling
    }
  }
}

const firebaseInstance = new Firebase();
export default firebaseInstance;
