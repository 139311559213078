import React from "react";
import { Link } from "react-router-dom";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/",
  },
];

const FooterEight = () => {
  return (
    <div className="row justify-content-end">
      <div className="col-xl-3 col-lg-3 col-6 footer-about-widget">
        <div className="logo">
          <a href="home/#home">
            <img
              style={{ height: "75px" }}
              src="images/logo/logo.svg"
              alt="Review+"
            />{" "}
            <span
              style={{
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
              }}
            >
              Review+
            </span>
          </a>
        </div>
        {/* <ul className="font-rubik mt-10">
          <li>
            <a href="mailto:support@reviewplus.com">support@reviewplus.com</a>
          </li>
          <li>
            <a href="tel:+1234567890" className="phone">
              +1 (438)-870-2332
            </a>
          </li>
        </ul> */}
      </div>

      {/*  /.about-widget */}
      <div className="col-xl-3 col-lg-3 col-6 footer-list">
        <h5 className="footer-title">Links</h5>
        <ul>
          <li>
            <a href="home#home" className="nav-link">
              Home
            </a>
          </li>
          <li>
            <a href="home#features" className="nav-link">
              Features
            </a>
          </li>
          <li>
            {/* <Link to="/about">About Us</Link> */}
            <a href="home#businesstypes" className="nav-link">
              Business Categories
            </a>
          </li>
          <li>
            {/* <Link to="/about">About Us</Link> */}
            <a href="home#howitworks" className="nav-link">
              How it works
            </a>
          </li>
          {/* <li>
            <Link to="/blog">Blog</Link>
          </li> */}
          {/* <li>
            <Link to="/demo">Demo the Product</Link>
          </li> */}
        </ul>
      </div>
      {/* /.footer-list */}
      <div className="col-xl-3 col-lg-3 col-6 footer-list">
        <h5 className="footer-title">Legal</h5>
        <ul>
          <li>
            <a href="terms#opt1" className="nav-link">
              Terms of Use
            </a>
            {/* <Link to="/terms-conditions">Terms of Use</Link> */}
          </li>
          <li>
            <a href="terms#opt3" className="nav-link">
              Privacy Policy
            </a>
            {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
          </li>
          <li>
            <a href="terms#opt4" className="nav-link">
              Cookie Policy
            </a>
            {/* <Link to="/cookie-policy">Cookie Policy</Link> */}
          </li>
        </ul>
      </div>

      {/* /.footer-list */}

      <div className="col-xl-3 col-lg-3 col-6 footer-list">
        <h5 className="footer-title">Contact Info</h5>
        <p className="font-rubik">
          2 Robert Speck Parkway, Suite 750, Mississauga, Ontario L4Z 1H8
        </p>
        <ul className="info">
          <li>
            <a href="mailto:info.tezooo@gmail.com">info.tezooo@gmail.com</a>
          </li>
          <li>
            <a href="Tel: +14388702332" className="mobile-num">
              +1 (438)-870-2332
            </a>
          </li>
        </ul>
        {/* End ul */}
      </div>
      {/*  /.footer-list  */}
    </div>
    //   /.row
  );
};

export default FooterEight;
