import React from "react";
import { Grid, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import { useNavigate, useParams } from "react-router-dom";
import firebase from "../../config/firebase";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Box, Container, Select, MenuItem } from "@mui/material";
import BmCardContainer from "../../components/merchant/BmCardContainer";
import BmCustomerAnalysis from "../../components/merchant/BmCustomerAnalysis";
import BmExperienceCard from "../../components/merchant/BmExperinceCard";
import BmQrScansGraph from "../../components/merchant/BmQrScansGraph";
import BmRecentReviewsContainer from "../../components/merchant/BmRecentReviewsContainer";
import { createBrowserHistory } from "history";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  title: {
    color: "#000000",
    paddingBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  select: {
    borderRadius: 25,
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:focus": {
      borderRadius: 25,
      backgroundColor: "white",
    },
    maxWidth: "200px", // Set fixed width
  },
  menuItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const CloverDashboard = () => {
  const classes = useStyles();

  //Should have same name as parameter
  const { id } = useParams();

  const navigate = useNavigate();
  const [cloverMerchantId, setCloverMerchantId] = useState("");
  const [currentMerchant, setCurrentMerchant] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [merchantData, setMerchantData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [customerFeelings, setCustomerFeelings] = useState({});
  const history = createBrowserHistory();

  const getMerchantData = (merchantId) => {
    const locations = [];
    //set merchant docs data from firebase
    try {
      firebase.getMerchantDetails(merchantId).then((data) => {
        if (data !== undefined) {
          setMerchantData(data);

          locations.push({
            id: data.merchantID,
            address: data.businessName + "- " + data.businessAddress,
          });

          setLocations(locations);

          setCurrentMerchant(data);
          setSelectedLocation(data.merchantID);
          getCustomerFeelings(data.merchantID);

          sessionStorage.setItem("currentMerchant", JSON.stringify(data));
          sessionStorage.setItem("merchantData", JSON.stringify(data));
        } else {
          console.log("Merchant not found " + merchantId);
          navigate("/not-found");
        }
      });
    } catch (error) {
      console.log(error.message);
      navigate("/not-found");
    }
  };

  const getCustomerFeelings = (merchantId) => {
    try {
      firebase.getCustomerFeelings(merchantId).then((data) => {
        setCustomerFeelings(data);
      });
    } catch (error) {
      alert(error.message);
    }
  };

  async function logout() {
    try {
      await firebase.logout();
      sessionStorage.removeItem("currentMerchant");
      sessionStorage.removeItem("merchantData");
      history.push("/login");
      window.location.reload();
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    setCloverMerchantId(id);
    getMerchantData(id);
  }, []);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth="xl">
        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.marginBtm}
        >
          <Typography variant={"h1"} className={classes.title}>
            <strong>Welcome Back!</strong>
          </Typography>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            className={classes.title}
          >
            <strong>{currentMerchant.businessName} ❤️</strong>
          </Typography>
        </Box>

        {currentMerchant ? (
          <BmCardContainer
            className={classes.marginBtm}
            currentMerchant={currentMerchant}
          />
        ) : (
          <p>Loading...</p>
        )}

        <Grid mt={1} container direction="row" spacing={4}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {currentMerchant ? (
              <BmRecentReviewsContainer currentMerchant={currentMerchant} />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {customerFeelings && Object.keys(customerFeelings).length > 0 && (
              <BmExperienceCard
                percentage={[
                  parseFloat(customerFeelings.loved),
                  parseFloat(customerFeelings.happy),
                  parseFloat(customerFeelings.ok),
                  parseFloat(customerFeelings.sad),
                  parseFloat(customerFeelings.angry),
                ]}
              />
            )}
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={4} mt={1}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {currentMerchant ? (
              <BmQrScansGraph currentMerchant={currentMerchant} />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {currentMerchant ? (
              <BmCustomerAnalysis currentMerchant={currentMerchant} />
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CloverDashboard;
