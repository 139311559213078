import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Box } from "@mui/material";
import { FcGoogle } from "react-icons/fc";
import { HiUsers } from "react-icons/hi";
import { BsArrowUpCircle } from "react-icons/bs";
import BmGoogleReviewCard from "./BmGoogleReviewCard";
import BmGoogleRatingCard from "./BmGoogleRatingCard";
import BmTotalCustomersCard from "./BmTotalCustomersCard";
import BmCustomerGrowthCard from "./BmCustomerGrowthCard";
import { getGoogleReviewDetails } from "../../config/cloudApis";
import firebase from "../../config/firebase";

const BmCardContainer = ({ currentMerchant }) => {
  const [totalGoogleReviews, setTotalGoogleReviews] = useState(0);
  const [currentGoogleRating, setCurrentGoogleRating] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [customerGrowth, setCustomerGrowth] = useState(0);
  const [initialGoogleReviews, setInitialGoogleReviews] = useState(0);
  const [initialGoogleRating, setInitialGoogleRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState("");

  async function fetchMerchantData() {
    try {
      setLoading(true);
      const googlePlaceID = currentMerchant.googlePlaceID;
      const reviewDetails = await getGoogleReviewDetails(googlePlaceID);
      const customers = await firebase.getAllMerchantCustomers(
        currentMerchant.merchantID
      );
      const growth = await firebase.getWeeklyCustomerGrowth(
        currentMerchant.merchantID
      );
      const merchantDetails = await firebase.getMerchantDetails(
        currentMerchant.merchantID
      );

      setTotalGoogleReviews(reviewDetails.data.user_ratings_total);
      setCurrentGoogleRating(reviewDetails.data.rating);
      setTotalCustomers(customers.length);
      setCustomerGrowth(growth);
      setInitialGoogleReviews(merchantDetails.initGoogleReviews);
      setInitialGoogleRating(merchantDetails.initGoogleRating);
      setPlan(merchantDetails.subscriptions.plan);
    } catch (error) {
      console.error("Error fetching merchant data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (currentMerchant) {
      fetchMerchantData();
    }
  }, [currentMerchant]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xl={3} md={6} sm={6} xs={12}>
        <BmGoogleReviewCard
          icon={<FcGoogle />}
          mediumText="Total Google Reviews"
          initialReviews={initialGoogleReviews}
          currentReviews={totalGoogleReviews}
          plan={plan} // Pass the plan prop
        />
      </Grid>
      <Grid item xl={3} md={6} sm={6} xs={12}>
        <BmGoogleRatingCard
          ratingValue={currentGoogleRating}
          mediumText="Current Google Rating"
          initialRating={initialGoogleRating}
          currentRating={currentGoogleRating}
          plan={plan} // Pass the plan prop
        />
      </Grid>
      <Grid item xl={3} md={6} sm={6} xs={12}>
        <BmTotalCustomersCard
          icon={<HiUsers />}
          mediumText="Total Customers"
          largeText={totalCustomers}
        />
      </Grid>
      <Grid item xl={3} md={6} sm={6} xs={12}>
        <BmCustomerGrowthCard
          icon={<BsArrowUpCircle />}
          mediumText="Customer Growth"
          largeText={customerGrowth + "%"}
          smallText="this week"
        />
      </Grid>
    </Grid>
  );
};

export default BmCardContainer;
