import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  Box,
  Container,
  Paper,
  TableContainer,
  IconButton,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import firebase from "../../config/firebase";
import Page from "../../utils/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
    position: "relative",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  inputField: {
    width: "70%", // Reduced width
    margin: theme.spacing(1, 0),
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
      background: "rgba(255, 255, 255, 0.1)",
      borderRadius: "8px",
      color: "black",
    },
    "& .MuiInputLabel-root": {
      color: "black",
    },
  },
  descriptionText: {
    width: "70%",
    color: "#555", // Light gray for description text
  },
  button: {
    marginTop: theme.spacing(1),
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  dialog: {
    padding: theme.spacing(2),
  },
}));

const AdminMerchantComponent = () => {
  const classes = useStyles();
  const [pin, setPin] = useState("");
  const [enteredPin, setEnteredPin] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const navigate = useNavigate();

  const TIMEOUT_DURATION = 5 * 60 * 1000; // 5 minutes

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        handleTimeout();
      }, TIMEOUT_DURATION);
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    const handleTimeout = () => {
      alert("Session timed out due to inactivity.");
      setEnteredPin(false); // Logs out the user or redirects to the PIN dialog
    };

    // Listen to user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    // Set initial timeout
    resetTimeout();

    return () => {
      // Cleanup when the component is unmounted
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, [enteredPin]);

  useEffect(() => {
    if (enteredPin) {
      loadMerchants();
    }
  }, [enteredPin, page, rowsPerPage]);

  const loadMerchants = async () => {
    try {
      let query = firebase.db
        .collection("merchants")
        .orderBy("createdTimestamp")
        .limit(rowsPerPage);

      // Only apply startAfter if lastTimestamp exists
      if (lastTimestamp) {
        query = query.startAfter(lastTimestamp);
      }

      const snapshot = await query.get();

      if (snapshot.empty) {
        console.log("No merchants found.");
        return;
      }

      const merchantsList = snapshot.docs.map((doc) => doc.data());

      setMerchants(merchantsList);

      // Update lastTimestamp to the createdTimestamp of the last document in the snapshot
      setLastTimestamp(
        snapshot.docs[snapshot.docs.length - 1].data().createdTimestamp
      );
    } catch (error) {
      console.error("Error loading merchants:", error);
    }
  };

  const handlePinSubmit = () => {
    if (pin === "3061431") {
      setEnteredPin(true);
    } else {
      alert("Incorrect PIN");
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditMerchant = (merchant) => {
    // setSelectedMerchant(merchant);
    // setOpenEditModal(true);
    navigate("/admin-edit-merchant", { state: { merchant } });
  };

  const handleDeleteMerchant = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await firebase.db
        .collection("merchants")
        .doc(selectedMerchant.id)
        .delete();
      setIsDeleteDialogOpen(false);
      loadMerchants();
    } catch (error) {
      console.error("Error deleting merchant:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {!enteredPin && (
        <Dialog open={!enteredPin}>
          <DialogTitle>Enter Admin PIN 🔑</DialogTitle>
          <Box display="flex" flexDirection="column" alignItems="center">
            <TextField
              label="PIN"
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              className={classes.inputField}
            />
            <Typography variant="body2" className={classes.descriptionText}>
              To get the Admin PIN, please reach out to
              <strong> info@tezooo.com</strong>.
            </Typography>
          </Box>
          <DialogActions>
            <Button
              color="secondary"
              onClick={handlePinSubmit}
              className={classes.button}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {enteredPin && (
        <Page className={classes.root} title="Review+ Merchant Setup">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Container maxWidth="md">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h4" color="#000" gutterBottom>
                  Admin Dashboard
                </Typography>

                <TextField
                  label="Search Merchant"
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearch}
                  className={classes.inputField}
                />

                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table>
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell>Business Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Owner Name</TableCell>
                        <TableCell>Created Timestamp</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {merchants
                        .filter((merchant) =>
                          merchant.businessName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((merchant) => (
                          <TableRow key={merchant.id}>
                            <TableCell>{merchant.businessName}</TableCell>
                            <TableCell>{merchant.email}</TableCell>
                            <TableCell>{merchant.ownerName}</TableCell>
                            <TableCell>
                              {new Date(
                                merchant.createdTimestamp
                              ).toLocaleString()}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleEditMerchant(merchant)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteMerchant()}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  component="div"
                  count={merchants.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

                {/* Edit Merchant Dialog */}
                <Dialog
                  open={openEditModal}
                  onClose={() => setOpenEditModal(false)}
                >
                  <DialogTitle>Edit Merchant</DialogTitle>
                  <DialogContent>
                    {selectedMerchant && (
                      <>
                        <TextField
                          label="Business Name"
                          fullWidth
                          value={selectedMerchant.businessName}
                          onChange={(e) =>
                            setSelectedMerchant({
                              ...selectedMerchant,
                              businessName: e.target.value,
                            })
                          }
                        />
                        <TextField
                          label="Owner Email"
                          fullWidth
                          value={selectedMerchant.email}
                          onChange={(e) =>
                            setSelectedMerchant({
                              ...selectedMerchant,
                              email: e.target.value,
                            })
                          }
                        />
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenEditModal(false)}>
                      Cancel
                    </Button>
                    <Button onClick={() => setOpenEditModal(false)}>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={() => setIsDeleteDialogOpen(false)}
                >
                  <DialogTitle>Confirm Deletion</DialogTitle>
                  <DialogContent>
                    Are you sure you want to delete this merchant?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      onClick={handleDeleteConfirmation}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Container>
          </Box>
        </Page>
      )}
    </>
  );
};

export default AdminMerchantComponent;
