import React, { useState, useEffect } from 'react'
import CustomerTable from './CustomerTable';

const CustomerDataView = () => {

    return(
        <div>
          <CustomerTable/>
        </div>
      
    );
}

export default CustomerDataView;