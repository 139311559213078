import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, Edit, Delete, Search } from "@mui/icons-material";
import firebase from "../../../config/firebase";
import Page from "../../../utils/Page";
import { sendEmployeeInvitation } from "../../../config/cloudApis";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  card: {
    margin: theme.spacing(2),
    boxShadow: theme.shadows[5],
    borderRadius: "15px",
  },
  addButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
  searchField: {
    marginBottom: theme.spacing(2),
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      color: "#fff",
    },
    "& .MuiInputAdornment-root": {
      color: "#fff",
    },
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  tableHeaderText: {
    color: "#fff",
    fontWeight: "bold",
  },
  title: {
    userSelect: "none",
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: theme.palette.text.primary, // Usually black or dark grey
    marginBottom: theme.spacing(1), // Add some spacing below the title
  },
  titleDescription: {
    userSelect: "none",
    color: theme.palette.text.secondary, // Usually a lighter grey
    fontSize: "1rem",
  },
}));

const EmployeesView = () => {
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [search, setSearch] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(""); // 'add' or 'edit'
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    email: "",
    phone: "",
    access_permissions: [],
  });
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const currentMerchant = JSON.parse(sessionStorage.getItem("currentMerchant"));
  const merchantID = currentMerchant.merchantID;

  useEffect(() => {
    fetchEmployees();
  }, [merchantID]);

  const fetchEmployees = async () => {
    const data = await firebase.getAllEmployees(merchantID);
    setEmployees(data);
  };

  const handleDialogOpen = (type, employee = null) => {
    setDialogType(type);
    if (employee) {
      setSelectedEmployee(employee);
      setEmployeeData({
        name: employee.name,
        email: employee.email,
        phone: employee.phone,
        access_permissions: employee.access_permissions || [],
      });
    } else {
      setEmployeeData({
        name: "",
        email: "",
        phone: "",
        access_permissions: [],
      });
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedEmployee(null);
  };

  const handleDeleteDialogOpen = (employee) => {
    setEmployeeToDelete(employee);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setEmployeeToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePermissionsChange = (e) => {
    const { name, checked } = e.target;
    setEmployeeData((prevData) => {
      const access_permissions = checked
        ? [...prevData.access_permissions, name]
        : prevData.access_permissions.filter((perm) => perm !== name);
      return { ...prevData, access_permissions };
    });
  };

  const handleFormSubmit = async () => {
    if (dialogType === "add") {
      await firebase.addEmployee(merchantID, employeeData);
      await sendEmployeeInvitation(
        employeeData.email,
        merchantID,
        currentMerchant.businessName,
        currentMerchant.businessLogoUrl // Include the logo URL
      );
      setInviteDialogOpen(true);
    } else {
      await firebase.updateEmployee(
        merchantID,
        selectedEmployee.id,
        employeeData
      );
    }
    fetchEmployees();
    handleDialogClose();
  };

  const handleDelete = async () => {
    await firebase.deleteEmployee(merchantID, employeeToDelete.id);
    fetchEmployees();
    handleDeleteDialogClose();
  };

  const handleInviteDialogClose = () => {
    setInviteDialogOpen(false);
  };

  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Page className={classes.root} title="Manage Employees">
      <Container maxWidth="lg">
        <Box className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            Manage Employees
          </Typography>
          <Typography variant="body1" className={classes.titleDescription}>
            Create and delegate access to your employees/managers who can keep
            track of promotions, analytics, customer feedback and respond
            accordingly.
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<Add />}
          className={classes.addButton}
          onClick={() => handleDialogOpen("add")}
        >
          Add Employee
        </Button>
        <TextField
          variant="outlined"
          placeholder="Search Employees"
          value={search}
          onChange={handleSearchChange}
          className={classes.searchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableHeaderText}>Name</TableCell>
                <TableCell className={classes.tableHeaderText}>Email</TableCell>
                <TableCell className={classes.tableHeaderText}>Phone</TableCell>
                <TableCell className={classes.tableHeaderText}>
                  Permissions
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEmployees.map((employee) => (
                <TableRow key={employee.id}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                  <TableCell>{employee.phone}</TableCell>
                  <TableCell>
                    {employee.access_permissions
                      ? employee.access_permissions.join(", ")
                      : ""}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleDialogOpen("edit", employee)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteDialogOpen(employee)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>
            {dialogType === "add" ? "Add Employee" : "Edit Employee"}
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Name"
              name="name"
              value={employeeData.name}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              value={employeeData.email}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Phone"
              name="phone"
              value={employeeData.phone}
              onChange={handleInputChange}
            />
            <Typography variant="subtitle1" style={{ marginTop: "16px" }}>
              Permissions:
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={employeeData.access_permissions.includes(
                    "Customers"
                  )}
                  onChange={handlePermissionsChange}
                  name="Customers"
                  color="primary"
                />
              }
              label="Customers"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={employeeData.access_permissions.includes(
                    "Marketing"
                  )}
                  onChange={handlePermissionsChange}
                  name="Marketing"
                  color="primary"
                />
              }
              label="Marketing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={employeeData.access_permissions.includes("Coupons")}
                  onChange={handlePermissionsChange}
                  name="Coupons"
                  color="primary"
                />
              }
              label="Coupons"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={employeeData.access_permissions.includes(
                    "Birthday offers"
                  )}
                  onChange={handlePermissionsChange}
                  name="Birthday offers"
                  color="primary"
                />
              }
              label="Birthday offers"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={employeeData.access_permissions.includes(
                    "Promotions"
                  )}
                  onChange={handlePermissionsChange}
                  name="Promotions"
                  color="primary"
                />
              }
              label="Promotions"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={employeeData.access_permissions.includes(
                    "Subscription"
                  )}
                  onChange={handlePermissionsChange}
                  name="Subscription"
                  color="primary"
                />
              }
              label="Subscription"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              {dialogType === "add" ? "Add" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this employee?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={inviteDialogOpen} onClose={handleInviteDialogClose}>
          <DialogTitle>Employee Invitation Sent</DialogTitle>
          <DialogContent>
            <Typography>
              An invitation email has been sent to the employee. They can use
              the link in the email to complete their registration.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleInviteDialogClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default EmployeesView;
