import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import classNames from "classnames";
import ArrowForward from "@mui/icons-material/ArrowForward";
import GoogleIcon from "@mui/icons-material/Google";
import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import firebase from "../../config/firebase";
import logo from "../../icons/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
    animation: "$fadeIn 2s",
    userSelect: "none",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  title: {
    color: "#ffffff",
    fontSize: 18,
    userSelect: "none",
  },
  subtitle: {
    fontSize: 16,
    userSelect: "none",
    textAlign: "center",
  },
  errorText: {
    color: theme.palette.error.main,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  marginTp: {
    marginTop: theme.spacing(2),
  },
  iconSmall: {
    fontSize: 20,
  },
  typography: {
    padding: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    color: "#000", // Set footer text color to black
    fontSize: "0.9rem",
    opacity: 0.8,
    width: "100%",
    userSelect: "none",
  },
  seperator: {
    marginTop: theme.spacing(2),
    fontSize: 15,
    textAlign: "center",
  },
  glowingIcon: {
    margin: theme.spacing(1, 0),
    animation: "$glow 2.5s infinite",
    borderRadius: "50%",
    width: "110px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.7)",
    position: "relative",
    userSelect: "none",
    background: "#fff",
  },
  "@keyframes glow": {
    "0%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
    "50%": { boxShadow: "0 0 20px rgba(255, 255, 255, 1)" },
    "100%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
  },
  btnArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& button": {
      margin: theme.spacing(1, 0),
    },
  },
  alreadyRegisteredButton: {
    background: "none",
    border: "none",
    color: "#F2B50F", // Highlight color for "Click Here"
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "inherit", // Inherit font style from parent
    fontSize: "1rem", // Customize font size
  },
}));

const MerchantCompleteRegistration = () => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [merchantDetails, setMerchantDetails] = useState(null);
  const [fieldValues, setFieldValues] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [isDataFilled, setIsDataFilled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchMerchantDetails = async () => {
      try {
        const data = await firebase.getMerchantDetails(id);
        if (data) {
          setMerchantDetails(data);
          setFieldValues((prevState) => ({
            ...prevState,
            email: data.ownerEmail || "",
          }));
        } else {
          alert("Merchant details not found. Please try again.");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching merchant details: ", error);
        alert(
          "An error occurred while fetching merchant details. Please try again."
        );
      }
    };

    fetchMerchantDetails();
  }, [id, navigate]);

  useEffect(() => {
    if (
      fieldValues.email &&
      fieldValues.password.length >= 6 &&
      fieldValues.confirmpassword.length >= 6 &&
      fieldValues.password === fieldValues.confirmpassword
    ) {
      setIsDataFilled(true);
    } else {
      setIsDataFilled(false);
    }
  }, [fieldValues]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmition = async (e) => {
    e.preventDefault();
    if (fieldValues.password !== fieldValues.confirmpassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      const merchantInfo = await firebase.checkIfRegistered(fieldValues.email);
      if (merchantInfo) {
        await firebase.register(fieldValues.email, fieldValues.password);
        await firebase.updateMerchantEmailVerification(
          merchantDetails.merchantID
        );
        await firebase.addUser(
          fieldValues.email,
          "admin",
          merchantDetails.merchantID,
          true
        );
        navigateToThankyou();
      } else {
        alert(
          "Merchant not found. Please check your email or contact support."
        );
      }
    } catch (error) {
      console.error("Error during registration: ", error);
      setErrorMessage(error.message);
      setIsError(true);
    }
  };

  const handleAlreadyRegisteredClick = async () => {
    try {
      await firebase.updateMerchantEmailVerification(
        merchantDetails.merchantID
      );
      await firebase.addUser(
        fieldValues.email,
        "admin",
        merchantDetails.merchantID,
        true
      );

      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 200);
    } catch (error) {
      console.error("Error during registration: ", error);
      alert(error.message);
    }
  };

  const registerWithGoogle = async () => {
    try {
      const merchantInfo = await firebase.checkIfRegistered(fieldValues.email);
      if (merchantInfo.length > 0 && merchantDetails.isEmailVerified) {
        setIsError(true);
      } else {
        await firebase.registerWithGoogle();
        await firebase
          .updateMerchantEmailVerification(merchantDetails.merchantID)
          .then(() => {
            firebase.addUser(
              fieldValues.email,
              "admin",
              merchantDetails.merchantID,
              true
            );
            navigateToThankyou();
          });
      }
    } catch (error) {
      console.error("Error during Google registration: ", error);
      setErrorMessage(error.message);
      setIsError(true);
    }
  };

  const navigateToThankyou = () => {
    navigate("/setup-complete-thankyou", {
      state: {
        businessName: merchantDetails.businessName,
        logo: merchantDetails.businessLogoUrl,
      },
    });
  };

  return (
    <Page className={classes.root} title="Review+ Connect">
      <Box display="flex" flexDirection="column" height="100%">
        <Container maxWidth="sm">
          <div className={classes.glowingIcon}>
            <img
              src={merchantDetails?.businessLogoUrl || logo}
              alt="Business Logo"
              className={classes.logo}
            />
          </div>
          <Typography color="secondary" variant="h3">
            <b>REVIEW+</b>
          </Typography>
          <Typography variant="h4" color="black" gutterBottom>
            <strong>Complete Merchant Setup</strong>
          </Typography>
          <Typography
            variant="caption"
            className={classes.subtitle}
            gutterBottom
          >
            Finish the setup to get access to Review+ Portal. As a Merchant, you
            can configure app settings, language, view recent reviews, promote
            social media accounts, collect customer data, and offer discounts
            based on visits.
          </Typography>

          <Dialog
            open={isError}
            onClose={() => setIsError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Email Already in use"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsError(false)}
                variant="contained"
                color="secondary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <section>
            <form onSubmit={handleSubmition}>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                type="email"
                label="Email"
                name="email"
                margin="normal"
                onChange={handleChange}
                value={fieldValues.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                label="Password"
                type="password"
                name="password"
                margin="normal"
                onChange={handleChange}
                value={fieldValues.password}
                helperText={
                  fieldValues.password.length < 6 &&
                  fieldValues.password.length > 0
                    ? "Password must be at least 6 characters long"
                    : ""
                }
                FormHelperTextProps={{ classes: { root: classes.errorText } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                label="Confirm Password"
                type="password"
                name="confirmpassword"
                margin="normal"
                onChange={handleChange}
                value={fieldValues.confirmpassword}
                helperText={
                  fieldValues.password !== fieldValues.confirmpassword &&
                  fieldValues.confirmpassword.length > 0
                    ? "Password does not match. Please try again"
                    : ""
                }
                FormHelperTextProps={{ classes: { root: classes.errorText } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.marginTp}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={!isDataFilled}
                >
                  Complete Setup
                  <ArrowForward
                    className={classNames(classes.rightIcon, classes.iconSmall)}
                  />
                </Button>
              </div>
              <Typography className={classes.seperator}>
                <strong>or</strong>
              </Typography>
              <div className={classes.btnArea}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={registerWithGoogle}
                  fullWidth
                >
                  Complete Setup with Google
                  <GoogleIcon
                    sx={{
                      color: "#F2B50F",
                      display: "inline-flex",
                      alignItems: "center",
                      ml: 1,
                    }}
                    className={classNames(classes.rightIcon, classes.iconSmall)}
                  />
                </Button>
              </div>
            </form>
          </section>
        </Container>

        <Typography
          className={classes.footer}
          align="center"
          onClick={handleAlreadyRegisteredClick}
        >
          Already registered? <strong>Click here</strong>
        </Typography>
      </Box>
    </Page>
  );
};

export default MerchantCompleteRegistration;
