import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Typography from "@mui/material/Typography";
import firebase from "../../config/firebase";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BmQrScanChart = ({ currentMerchant }) => {
  const [chartData, setChartData] = useState({});
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    if (currentMerchant !== undefined && currentMerchant !== "") {
      getScansData(currentMerchant.merchantID);
    }
  }, [currentMerchant]);

  async function getScansData(merchantId) {
    try {
      // console.log(
      //   `Fetching QR scans and customer data for merchant ID: ${merchantId}`
      // );

      const result = await firebase.getQrScansVsCustomers(merchantId);
      // console.log("Fetched data:", result);

      if (!result || Object.keys(result).length === 0) {
        // console.log("No data found.");
        setHasData(false);
        return;
      }

      const today = new Date();
      const labels = [];

      for (let i = 6; i >= 0; i--) {
        const day = new Date(today);
        day.setDate(today.getDate() - i);
        labels.push(day.toLocaleDateString());
      }

      //  console.log("Generated labels for the last 7 days:", labels);

      const qrScansData = [];
      const customersData = [];

      labels.forEach((date) => {
        const dateData = result[date] || [0, 0];
        qrScansData.push(dateData[0]);
        customersData.push(dateData[1]);
      });

      // console.log("QR Scans Data:", qrScansData);
      // console.log("Customers Data:", customersData);

      const hasValidData =
        qrScansData.some((data) => data > 0) ||
        customersData.some((data) => data > 0);

      if (!hasValidData) {
        //console.log("No valid data found.");
        setHasData(false);
        return;
      }

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: "QR Scans",
            data: qrScansData,
            backgroundColor: "#8758FF",
            borderColor: "#8758FF",
            borderWidth: 1,
            borderRadius: 12,
          },
          {
            label: "Customers Added",
            data: customersData,
            backgroundColor: "#74D0D0",
            borderColor: "#74D0D0",
            borderWidth: 1,
            borderRadius: 12,
          },
        ],
      };

      //  console.log("Chart data prepared:", chartData);

      setChartData(chartData);
      setHasData(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert(error.message);
    }
  }

  return (
    <div style={{ height: "400px", marginTop: "20px" }}>
      {hasData ? (
        <Bar
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  borderDash: [1],
                  drawBorder: false,
                  color: "rgba(0, 0, 0, 0.1)",
                  zeroLineColor: "rgba(0, 0, 0, 0.1)",
                },
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  boxWidth: 10,
                },
              },
            },
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Full height of the container
          }}
        >
          <Typography variant="body2">
            No data is available to display the chart yet. Please check back
            later.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default BmQrScanChart;
